import { createGarageProducts, getGarageProducts } from '../actionTypes';

const ProductReducer = (state = [], action) => {
  switch (action.type) {
    case getGarageProducts:
      return action.payload;
    case createGarageProducts:
      return [...state, action.payload]
    default:
      return state;
  }
};

export default ProductReducer;
