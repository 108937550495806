import axios from "axios";
import http from "../../utils/Api";
import { useNavigate } from "react-router";
import { getAllProductCategory } from "../actionTypes";



const getProductCategories =(data)=>async(dispatch)=>{
    try{
        const token = localStorage.getItem("accessToken")
        const res = await http.get(`/api/product-categories`,http.generateConfig(token));
        console.log(res,"TEHLA")
        if(res){
            dispatch({
                type:getAllProductCategory,
                payload:res
            })
        }
    }
    catch(err){
        console.log(err)
    }
}


export {
    getProductCategories
}