import { addGarage, addNewGarrage, deletGarage, editUserGarage, getAllUserGarages } from "../actionTypes";

const initialState = [];

const garageReducer = (state = initialState, action) => {
  switch (action.type) {
    case getAllUserGarages:
      return action.payload;

    case editUserGarage:
      return state.map((garage) =>
        garage.id === action.payload.id ? action.payload.garage : garage
      );

    case deletGarage:
      return state.filter((garage) => garage.id !== action.payload.id);

    case addNewGarrage:
      return [...state, action.payload]; 
    default:
      return state;
  }
};

export default garageReducer;
