import React, { useState, useRef } from "react";
import { GoogleMap, Marker, LoadScript, StandaloneSearchBox } from "@react-google-maps/api";

const CustomModal = ({ show, handleClose, children }) => {
  const modalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: show ? 'flex' : 'none',
    justifyContent: 'center',
    alignItems: 'center'
  };

  const modalMainStyle = {
    position: 'relative',
    background: 'white',
    padding: '20px',
    borderRadius: '8px',
    width: '50%',
    maxWidth: '600px',
  };

  return (
    <div style={modalStyle}>
      <div style={modalMainStyle}>
        {children}
        <button onClick={handleClose} style={{padding: '10px 20px', margin: '10px', border: 'none', backgroundColor: '#007bff', color: 'white', cursor: 'pointer'}}>
          Close
        </button>
      </div>
    </div>
  );
};

const MapModel = ({ show, handleClose, handleLocationSelect, googleMapsApiKey }) => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const mapRef = useRef(null);
  const searchBoxRef = useRef(null);

  const handleMapClick = (e) => {
    setSelectedLocation({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  const handleConfirmLocation = () => {
    if (selectedLocation) {
      handleLocationSelect(selectedLocation);
      handleClose();
    }
  };

  const onLoadMap = (map) => {
    mapRef.current = map;
  };

  const onLoadSearchBox = (ref) => {
    searchBoxRef.current = ref;
  };

  const onPlacesChanged = () => {
    if (searchBoxRef.current) {
      const places = searchBoxRef.current.getPlaces();
      if (places.length > 0) {
        const { geometry } = places[0];
        const newLocation = {
          lat: geometry.location.lat(),
          lng: geometry.location.lng(),
        };
        setSelectedLocation(newLocation);
        mapRef.current.panTo(newLocation);
      }
    }
  };

  return (
    <CustomModal show={show} handleClose={handleClose}>
      <h2>Select Location</h2>
      <StandaloneSearchBox
        onLoad={onLoadSearchBox}
        onPlacesChanged={onPlacesChanged}
      >
        <input
          type="text"
          placeholder="Search places"
          style={{
            boxSizing: "border-box",
            border: "1px solid transparent",
            width: "100%",
            height: "32px",
            padding: "0 12px",
            borderRadius: "3px",
            boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
            fontSize: "14px",
            outline: "none",
            backgroundColor: "white"
          }}
        />
      </StandaloneSearchBox>
      <div>
        <GoogleMap
          mapContainerStyle={{ height: "400px", width: "100%" }}
          center={selectedLocation || { lat: 37.7749, lng: -122.4194 }}
          zoom={13}
          onClick={handleMapClick}
          onLoad={onLoadMap}
        >
          {selectedLocation && <Marker position={selectedLocation} />}
        </GoogleMap>
      </div>
      <button onClick={handleConfirmLocation} style={{padding: '10px 20px', border: 'none', backgroundColor: '#007bff', color: 'white', cursor: 'pointer'}}>
        Confirm Location
      </button>
    </CustomModal>
  );
};

export default MapModel;
