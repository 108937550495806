import "./App.css";
import { Col, Container, Row } from "react-bootstrap";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Sidebar from "./components/sidebar";
import {
  Customer,
  Dashboard,
  ForgetPassword,
  Garages,
  HelpDesk,
  Inventory,
  Login,
  OTP,
  Products,
  Reports,
  Settings,
  SetupPassword,
  Signup,
  Transactions,
  Users,
  Order,
  Information,
  ServiceSchedule
} from "./pages";
import "./App.css"
import Services from "./pages/Services";
import OwnerInformation from "./components/OwnerInformation";
import Booking from "./pages/Bookings";
import MultipleProduct from "./components/multipleProduct";
import SingleProduct from "./components/singleProduct";
import BookingDetails from "./pages/BookingDetails";
import PrivacyPolicy from "./pages/privacyPolicy";
import RequestAccountDeletion from './pages/accountDelete';
import './i18n/i18n.js';

function App() {

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route>
            <Route path="/" element={<Login />} />
            <Route path="signup" element={<Signup />} />
            <Route path="forgetPassword" element={<ForgetPassword />} />
            <Route path="otp" element={<OTP />} />
            <Route path="setupPassword" element={<SetupPassword />} />
            <Route
        path="/dashboard"
        element={
          <OwnerInformation>
            <Dashboard />
          </OwnerInformation>
        }
      />
            <Route path="inventory" element={<Inventory />} />
            <Route path="customer" element={<Customer />} />
            <Route path="booking" element={<Booking />} />
            <Route path="bookingDetails/:id" element={<BookingDetails />} />
            <Route path="garages" element={<Garages />} />
            <Route path="products" element={<Products />} />
            <Route path="reports" element={<Reports />} />
            <Route path="settings" element={<Settings />} />
            <Route path="transactions" element={<Transactions />} />
            <Route path="helpDesk" element={<HelpDesk />} />
            <Route path="services" element={<Services />} />
            <Route path="serviceSchedule" element={<ServiceSchedule />} />
            <Route path="users" element={<Users />} />
            <Route path="order" element={<Order />} />
            <Route path="information"  element={<Information />} />
            <Route path="singleProduct"  element={<SingleProduct />} />
            <Route path="multipleProduct"  element={<MultipleProduct />} />
            <Route path="privacyPolicy"  element={<PrivacyPolicy />} />
            <Route path="deleteAccount"  element={<RequestAccountDeletion />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
// <Container fluid>
{
  /* <Row>
    <Col md={3}>
    </Col>

    <Col md={9}>
      <main>
        <h1>Main Content</h1>
      </main>
    </Col>
  </Row> */
}

// </Container>
