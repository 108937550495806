import axios from "axios";
import http from "../../utils/Api";
import { useNavigate } from "react-router";

import {createGarageService, filterServices, getGarageServices, getGarageServicesSchedule} from '../actionTypes'




const getAllServices = (data)=>async(dispatch)=>{

    try{
        const token = localStorage.getItem("accessToken");
        const {garageId} = data
        const res = await http.get(`/api/myServices/${garageId}`, http.generateConfig(token));

        console.log(res,'kjaskjas<ALSKDNAS')
        if(res){
            dispatch({
                type:getGarageServices,
                payload:res.result
            })
        }
    }
    catch(e){
        console.log(e)
    }
}

const getServicesByCategory =(data)=>async(dispatch)=>{
    try{

        const token = localStorage.getItem("accessToken");
        const {id,garageId, brands }  = data 
        const res = await http.get(`/api/servicebyCat/${id}/${garageId}`, http.generateConfig(token))
        console.log(res,'ASLKDKAJSD')
        if(res && res.result){ 
            dispatch({
            type:getGarageServices,
            payload:res.result
        })

    }
    }
    catch(err){
        console.log(err)
    }
}

const getServiceSchedule=(data)=> async(dispatch)=>{
    try{


        const token = localStorage.getItem("accessToken");
        const {id,garageId, brands }  = data 
        const res = await http.post(`/api/serviceSchedulabyCat/${id}/${garageId}`,{brands:brands}, http.generateConfig(token))
        if(res && res.result){ 
   
            dispatch({
                type:getGarageServicesSchedule,
                payload: res.result.mobileServiceSchedules
            })
        }
    }
    catch(err){
        console.log(err)

    }
}


const getServiceScheduleBYMODEL=(data)=> async(dispatch)=>{
    try{


        const token = localStorage.getItem("accessToken");
        const {brandId, vehicleModel }  = data 
        const res = await http.post(`/api/serviceSchedulabyBrandPOS`,data, http.generateConfig(token))
        console.log(res,'ALKSDKAS')
        if(res && res.result){ 
   
            dispatch({
                type:getGarageServicesSchedule,
                payload: res.result[0].items
            })
        }
    }
    catch(err){
        console.log(err)

    }
}


const createService =(data)=> async (dispatch)=>{
    try{
        const token = localStorage.getItem("accessToken");
        const config = http.generateConfig(token);
        // config.headers['Content-Type'] = 'multipart/form-data';

        const res = await http.post(`/api/garageService`, data, config);
        console.log(res,"THE RESPONSE")
        dispatch({
            type:createGarageService,
            payload :res.result
        })

    }
    catch(err){
        console.log(err)
    }
}

export {
    getAllServices,
    getServicesByCategory,
    createService,
    getServiceSchedule,
    getServiceScheduleBYMODEL
}