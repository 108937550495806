import { Button, Container, Row, Col, Table, Modal } from "react-bootstrap";
import { Layout, OrderStats } from "../components";
import noProduct from "../assets/noProduct.png";
import { useEffect, useState } from "react";
import dots from "../assets/dots.png";
import userOrderImage from "../assets/userOrderImage.png";
import "../App.css";
import AddProduct from "../components/addProduct";
import { useDispatch, useSelector } from "react-redux";
import { getProductByCategory } from "../service/action/productAction";
import http from "../utils/Api";
const Products = () => {
  const dispatch = useDispatch();
  const allProducts = useSelector((state) => state.products);

  const ProductStatistics = [
    { title: "Total Orders", count: "1000", ratio: "10%" },
    { title: "Total Sales", count: "$1000", ratio: "10%" },
    { title: "Active Orders", count: "1000", ratio: "10%" },
    { title: "Average orders size", count: "$1000", ratio: "10%" },
  ];
  const [showEditModal, setShowEditModal] = useState(false);
  const [editedProduct, setEditedProduct] = useState(null);
  const [editedFields, setEditedFields] = useState({
    unitPrice: "",
    price: "",
    unit: "",
    sold: "",
  });

  const [isAddingProduct, setAddingProduct] = useState(false);

  const handleAddProductClick = () => {
    setAddingProduct(true);
  };

  const selectedGarage = useSelector((state) => state.selectedGarage);
  console.log(selectedGarage,"THE SAE")
  useEffect(() => {
    dispatch(getProductByCategory({ id: 0, garageId: selectedGarage.id }));
  }, [showEditModal]);

  const handleBackToListClick = () => {
    setAddingProduct(false);
  };

  const handleEditClick = (product) => {
    console.log(product, "THE product");
    setEditedProduct(product);
    setEditedFields({
      unitPrice: product.unitPrice,
      price: product.price,
      unit: product.unit,
      sold: product.sold,
    });
    setShowEditModal(true);
  };

  const handleSaveClick = async () => {
    // Call your save function here with the edited fields and product ID
    const token = localStorage.getItem("accessToken");

    const res = await http.put(
      `/api/products/${editedProduct.id}`,
      editedFields,
      http.generateConfig(token)
    );
    if(res.success){
    }
    setShowEditModal(false);
  };
  const handleCancelClick = () => {
    setShowEditModal(false);
  };
  console.log(allProducts,"THE A L ALKS")
  return (
    <Layout>
      {allProducts.length === 0 && !isAddingProduct ? (
        <Container
          fluid
          className="d-flex flex-column align-items-center justify-content-center text-center p-5"
        >
          <>
            <img src={noProduct} alt="no Product" height={250} width={250} />
            <h3>NO PRODUCTS FOUND</h3>
            <span
              className="mb-4"
              style={{
                color: "#757575",
                textAlign: "center",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
              }}
            >
              Ready to kick off your inventory? Add products and <br />
              open the door to a realm of automotive management <br />
              right at your fingertips.
            </span>
            <Button
              style={{
                display: "flex",
                width: "271px",
                height: "40px",
                padding: "20px 43px",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                flexShrink: 0,
                borderRadius: "999px",
                borderColor: "orange",
                background: "linear-gradient(97deg, #F15822 0%, #F7941D 100%)",
              }}
              onClick={handleAddProductClick}
            >
              Add New Product
            </Button>
          </>
        </Container>
      ) : (
        <>
          {isAddingProduct ? (
            <div>
              <AddProduct handleBackToListClick={handleBackToListClick} />
            </div>
          ) : (
            // Render the product list
            <>
              <OrderStats
                title={"Product Statistics"}
                statistics={ProductStatistics}
              />
              <Row className="align-items-center mt-2">
                <Col md={6}>
                  <p
                    style={{
                      fontSize: "24px",
                      fontWeight: 600,
                    }}
                  >
                    Products
                  </p>
                </Col>
                <Col md={6} className="d-flex justify-content-end">
                  <Button
                    onClick={handleAddProductClick}
                    style={{
                      display: "flex",
                      width: "210px",
                      height: "40px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                      flexShrink: 0,
                      borderRadius: "999px",
                      borderColor: "orange",
                      background:
                        "linear-gradient(97deg, #F15822 0%, #F7941D 100%)",
                    }}
                  >
                    Add New Product
                  </Button>
                </Col>
              </Row>{" "}
              <Container
                fluid
                className="p-2"
                style={{ backgroundColor: "#fff", borderRadius: "15px" }}
              >
                <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                  <Table
                    hover
                    responsive
                    className="text-center"
                    style={{ margin: "0 auto" }}
                  >
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Product ID</th>
                        <th>Buy Price</th>
                        <th>Sell Price</th>
                        <th>Stock</th>
                        <th>Sold</th>
                        <th>Rating</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allProducts.map((product, index) => (
                        <tr key={index}>
                          <td>
                            {product && product.images && product.images.length>0 ?
                            (
                              <img
                              src={`https://varposbuckets.s3.amazonaws.com/productImage/${product?.images[0]}`}
                              // src={product?.images[0]}
                              // src={userOrderImage}
                              alt="Order"
                              style={{ borderRadius: "32px" }}
                              height={35}
                              width={35}
                            />
                            ):
                          (<img
                            // src={product?.images[0]}
                            src={userOrderImage}
                            alt="Order"
                            style={{ borderRadius: "32px" }}
                            height={35}
                            width={35}
                          />)}
                            
                            <br />
                            <span className="productName">{product.title}</span>
                          </td>
                          <td>{product.productUID}</td>
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={() => handleEditClick(product)}
                          >
                            {product?.unitPrice}
                          </td>
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={() => handleEditClick(product)}
                          >
                            {product?.price}
                          </td>
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={() => handleEditClick(product)}
                          >
                            {product?.unit}
                          </td>
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={() => handleEditClick(product)}
                          >
                            {product?.sold}
                          </td>
                          <td>{product?.rating || 0}⭐</td>
                          <td>
                            <span
                              // product.status === "In stock"
                              className={product.unit > 0 ? "in-stock" : "not-available"}
                            >
                              {/* {product.status} */}
                              {product.unit > 0 ? "In Stock" : "Not Available"}
                            </span>
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            <img src={dots} alt={"..."} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Container>
            </>
          )}
        </>
      )}
      <Modal show={showEditModal} onHide={handleCancelClick}>
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#f8f9fa", borderBottom: "none" }}
        >
          <Modal.Title style={{ color: "#333", fontWeight: "bold" }}>
            Edit Product
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label>Purchase Unit Price</label>
              <input
                type="text"
                className="form-control"
                style={{ width: "100%" }}
                value={editedFields.unitPrice}
                onChange={(e) =>
                  setEditedFields({
                    ...editedFields,
                    unitPrice: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group">
              <label>Price</label>
              <input
                type="text"
                className="form-control"
                style={{ width: "100%" }}
                value={editedFields.price}
                onChange={(e) =>
                  setEditedFields({ ...editedFields, price: e.target.value })
                }
              />
            </div>
            <div className="form-group">
              <label>Unit</label>
              <input
                type="text"
                className="form-control"
                style={{ width: "100%" }}
                value={editedFields.unit}
                onChange={(e) =>
                  setEditedFields({ ...editedFields, unit: e.target.value })
                }
              />
            </div>
            <div className="form-group">
              <label>Sold</label>
              <input
                type="text"
                className="form-control"
                style={{ width: "100%" }}
                value={editedFields.sold}
                onChange={(e) => {
                  const sold = parseInt(e.target.value);
                  const newUnit = editedProduct.unit - sold; // Calculate new unit value
                  setEditedFields({ ...editedFields, sold, unit: newUnit }); // Update editedFields state
                }}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer style={{ borderTop: "none" }}>
          <Button variant="secondary" onClick={handleCancelClick}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveClick}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default Products;
