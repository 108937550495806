import React, { useContext, useEffect, useState } from "react";
import { Button, Container, Modal, Form, Table, Row, Col } from "react-bootstrap";
import { Layout } from "../components";
import { useDispatch, useSelector } from "react-redux";
import { adduser, getAllUsers } from "../service/action/userAction";
import userImage from "../assets/userImage.png";
import dots from "../assets/dots.png";
import location from "../assets/location.svg";
import GarageRating from "../components/rattingStars";
import { useTranslation } from "react-i18next";
import { UserContext } from "../Context/UserContext";


const Users = () => {

  const user = JSON.parse(localStorage.getItem('user'))
  const userRole = user?.roles[0]?.name
  const [isAddingUser, setAddingUser] = useState(false);
  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    password: "",
    role: "", 
  });
  const [isViewingGarages, setViewingGarages] = useState(false); 
  const [selectedUser, setSelectedUser] = useState(null); 
  const { language } = useContext(UserContext);
  const rowReverse = language === 'ar' ? "flex-row-reverse" : "";
  const { t } = useTranslation();


  const handleAddUserClick = () => {
    setAddingUser(true);
  };

  const handleAddUserClose = () => {
    setAddingUser(false);
    setNewUser({ name: "", email: "", password: "", role: "" });
  };

  const handleAddUserSubmit = () => {
    dispatch(adduser(newUser));
    handleAddUserClose();
  };

  const [roles, setRoles] = useState([
    {
      id: 2,
      name: "manager",
    },
    {
      id:3,
      name:"cashier"
    }
  
  ]);

  const companyUsers = useSelector((state) => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  const handleViewGaragesClick = (user) => {
    setSelectedUser(user);
    setViewingGarages(true);
  };

  const handleModalClose = () => {
    setAddingUser(false);
    setViewingGarages(false);
    setSelectedUser(null);
    setNewUser({ name: "", email: "", password: "", role: "" });
  };


  return (
    <Layout>
      <Container
        fluid
        className="d-flex flex-column align-items-center justify-content-center text-center p-5"
        >
        {companyUsers.length === 0 ? (
          <>
            <h3 className={``}>{t("No User Found")}</h3>
            <span
              className="mb-4"
              style={{
                color: "#757575",
                textAlign: "center",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
              }}
            >
              {t("Ready to kick off your user management? Add user and")} <br />
              {t("provide them with a seamless experience.")}
            </span>
            <Button
              style={{
                display: "flex",
                width: "271px",
                height: "40px",
                padding: "20px 43px",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                flexShrink: 0,
                borderRadius: "999px",
                borderColor: "orange",
                background: "linear-gradient(97deg, #F15822 0%, #F7941D 100%)",
              }}
              onClick={handleAddUserClick}
            >
              {t("Add New User")}
            </Button>
          </>
        ) : (
          <>
            <div  style={{ width:"100%"}} className={`d-flex justify-content-between ${rowReverse}`}> 
              {/* <Col md={6}> */}
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: 600,
                    textAlign:"left"
                  }}
                  className={`d-flex ${rowReverse}`}
                >
                  {t("Users")}
                </p>
              {/* </Col> */}
              {/* <Col md={6} className="d-flex justify-content-end"> */}
              <div className={``}>
                <Button
                  onClick={handleAddUserClick}
                  style={{
                    display: "flex",
                    width: "210px",
                    height: "40px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    flexShrink: 0,
                    borderRadius: "999px",
                    borderColor: "orange",
                    background:
                      "linear-gradient(97deg, #F15822 0%, #F7941D 100%)",
                  }}
                >
                  {t("Add New User")}
                </Button>
              </div>
              {/* </Col> */}
            </div>{" "}
            <Table striped bordered hover dir={language === 'ar' ? "rtl" : "ltr"}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{t("Name")}</th>
                  <th>{t("Email")}</th>
                  <th>{t("Role")}</th>
                  <th>{t("Garages")}</th>
                </tr>
              </thead>
              <tbody>
                {companyUsers?.map((user, index) => (
                    <tr key={index}>

                    <td>{index + 1}</td>
                    <td>{user?.name}</td>
                    <td>{user?.email}</td>
                    <td>{user?.roles?.length > 0 && user?.roles[0]?.name}</td>
                    <td>
                    <Button
                  onClick={() => handleViewGaragesClick(user)}
                  style={{
                    width: "210px",
                    height: "40px",
                    borderRadius: "999px",
                    borderColor: "orange",
                    background:
                      "linear-gradient(97deg, #F15822 0%, #F7941D 100%)",
                  }}
                >
                  {t("View Garages")}
                </Button>
                        
                        </td>

                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}

        <Modal show={isAddingUser} onHide={handleAddUserClose}>
          <Modal.Header closeButton style={{ flexDirection: language === 'ar' ? 'row-reverse': '', justifyContent: 'space-between' }}>
            <Modal.Title className={`d-flex ${rowReverse}`}>{t("Add New User")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formName">
                <Form.Label className={`d-flex ${rowReverse}`}>{t("Name")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("Enter name")}
                  value={newUser.name}
                  onChange={(e) =>
                    setNewUser({ ...newUser, name: e.target.value })
                  }
                />
              </Form.Group>

              <Form.Group controlId="formEmail">
                <Form.Label className={`d-flex ${rowReverse}`}>{t("Email address")}</Form.Label>
                <Form.Control
                  type="email"
                  placeholder={t("Enter email")}
                  value={newUser.email}
                  onChange={(e) =>
                    setNewUser({ ...newUser, email: e.target.value })
                  }
                />
              </Form.Group>

              <Form.Group controlId="formPassword">
                <Form.Label className={`d-flex ${rowReverse}`}>{t("Password")}</Form.Label>
                <Form.Control
                  type="password"
                  placeholder={t("Enter password")}
                  value={newUser.password}
                  onChange={(e) =>
                    setNewUser({ ...newUser, password: e.target.value })
                  }
                />
              </Form.Group>

              {/* Add a Form.Select for the role */}
              <Form.Group controlId="formRole">
                <Form.Label className={`d-flex ${rowReverse}`}>{t("Role")}</Form.Label>
                <Form.Select
                  value={newUser.role}
                  onChange={(e) =>
                    setNewUser({ ...newUser, role: e.target.value })
                  }
                >
                  <option value="">{t("Select a role")}</option>
                  {roles?.map((role) => (
                    <option key={role?.id} value={role?.name}>
                      {role?.name?.toUpperCase()}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <div className={`mt-3 d-flex ${rowReverse}`}>
                <Button variant="primary" onClick={handleAddUserSubmit}>
                  {t("Add User")}
                </Button>

              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </Container>

      {/* View Garage Modal */}
      <Modal
        show={isViewingGarages}
        onHide={handleModalClose}
        style={{ flexDirection: language === 'ar' ? 'row-reverse': '', justifyContent: 'space-between' }}
        centered  // Center the modal both vertically and horizontally
      >
        <Modal.Header closeButton className={`d-flex ${rowReverse}`}>
          <Modal.Title className={`d-flex ${rowReverse}`}>{`${t("Garages for")} ${selectedUser?.name}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{height:"40vh", overflow:'scroll'}} >
          {/* Render the list of garages for the selected user */}
          <ul>
          {selectedUser?.assignedGarages && selectedUser?.assignedGarages?.length >0 ?
          (<>
           {selectedUser?.assignedGarages?.map((garage, index) => (
                  <div
                    key={garage.name}
                    style={{
                      width: "348.768px",
                      padding: "15px",
                      backgroundColor: "#fff",
                      borderRadius: "13px",
                      border:"1px solid grey",
                      margin: "5px",
                    }}
                  >
                    {/* image title */}
                    <div className={`d-flex flex-wrap justify-content-between align-items-center md-4 ${rowReverse}`}>
                      <div>
                        <img
                          src={userImage}
                          alt="User"
                          height={40}
                          width={40}
                          style={{ borderRadius: "44px", marginRight: "10px" }}
                        />
                        <p className={`d-flex ${rowReverse} mb-0`}>{t(garage.name)}</p>
                      </div>
                      <div>
                        <img
                          src={dots}
                          alt="Options"
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                    {/* Description */}
                    <div>
                      <span
                        style={{
                          color: "#585858",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineheight: "normal",
                        }}
                        className={`d-flex ${rowReverse}`}
                      >
                        {t(garage.description) ||
                          t("Vehicles as canvases, where artistry meets engineering brilliance")}
                      </span>
                    </div>

                    {/* location  */}
                    <div className={`d-flex flex-wrap justify-content-between align-items-center md-4 ${rowReverse}`}>
                      <div>
                        <img
                          src={location}
                          alt="loc"
                          style={{ marginRight: "10px" }}
                        />
                        <span>{t(garage.city)}</span>
                      </div>
                      <div>
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "#F08B1D",
                            color: "#fff",
                            padding: "5.078px 23.89px",
                            borderRadius: "4.424px",
                          }}
                        >
                          {t(garage.city)}
                        </button>
                      </div>
                    </div>

                    {/* rating and team */}
                    <div className="d-flex flex-wrap justify-content-between align-items-center mt-4">
                      {/* {garage.rating}  */}
                      <div>
                        <GarageRating rating={garage.rating} />
                      </div>
                      <div>
                        <span
                          style={{
                            color: "#141414",
                            fontSize: "14px",
                            fontWeight: 400,
                          }}
                        >
                          {garage?.team ? <>{t("Team")}</> : <> </>}
                        </span>
                        <span style={{ marginLeft: "8px" }}>
                          {garage?.team ? (
                            <>
                              {garage?.team?.map((tem, index) => {
                                return (
                                  <img
                                    src={userImage}
                                    height={19}
                                    width={19}
                                    style={{
                                      borderRadius: "19px",
                                      marginLeft: index > 0 ? "-4px" : 0,
                                    }}
                                  />
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
          </>)
        :
        (<>No Garage Assigned</>)
        }
             
          </ul>
        </Modal.Body>
      </Modal>

    </Layout>
  );
};

export default Users;
