import axios from "axios";
import http from "../../utils/Api";
import { loginType, registerType } from "../actionTypes";
import { useNavigate } from "react-router";

const signin = (data) => async (dispatch) => {
  try {
    const res = await http.post("/api/auth/signin", data);
    console.log(res,"THE RESPONSE abc here")
    if (res && res.status !== 400) {
      dispatch({
        type: loginType,
        payload: res,
      });
    }
    return res;
  } catch (err) {
    console.log(err);
  }
};

const signup = (data) => async (dispatch) => {
  try {
    const dat = await http.post("/api/auth/signup", data);
    return dat;
  } catch (err) {
    alert('Enter all fields correctly');
  }
};
// const signinGSWithGoogle = (data) => async (dispatch) => {
//   try {
//     const res = await http.post("/api/auth/gameStudio/loginWithGoogle", data);
//     if (res) {
//       dispatch({
//         type: "loginWithGoogle",
//         payload: res,
//       });
//     }
//   } catch (err) {
//     console.log(err);
//   }
// };

// const forgetPasswordApi = (data) => async (dispatch) => {
//   try {
//     const res = await http.post("/api/auth//gameStudio/forgotPassword", data);
//     if (res) {
//       dispatch({
//         type: "forgetPassword",
//         payload: res,
//       });
//       toast(res.message, res.success);
//     }
//   } catch (err) {
//     console.log(err);
//   }
// };

// const confirmedPassword = (data, token) => async (dispatch) => {
//   try {
//     const res = await http.put(
//       `/api/auth/gameStudio/resetPassword/${token}`,
//       data
//     );
//     if (res) {
//       dispatch({
//         type: "forgetPassword",
//         payload: res,
//       });
//       toast(res.message, res.success);
//     }
//   } catch (err) {
//     console.log(err);
//   }
// };

export {
  signin,
  signup,
};
