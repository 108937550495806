import React, { createContext, useState } from 'react';

// Create a Context
export const UserContext = createContext();

// Create a Provider Component
export const UserProvider = ({ children }) => {
  const [language, setLanguage] = useState("en");

  return (
    <UserContext.Provider value={{ language, setLanguage }}>
      {children}
    </UserContext.Provider>
  );
};
