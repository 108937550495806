import { useEffect, useState } from "react";
import OwnerRequest from "../components/ownerRequest";
import http from "../utils/Api";
import { Container } from "react-bootstrap";
import logo from "../assets/Logo.png";
import { useNavigate } from "react-router-dom";
import verifyImage from '../assets/Verify.png'
const Information = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const userRole = user?.roles[0] || null;

  const [oldRequest, setOldRequest] = useState([]);
  useEffect(() => {
    exRequest();
  }, []);


  const exRequest = async () => {
    const id = localStorage.getItem("userId");
    const token = localStorage.getItem("accessToken");

    const config = http.generateConfig(token);
    const res = await http.get(`ownerRequest/${id}`,config);
    if (res) {
      setOldRequest(res.result);
    }
  };

  const navigate = useNavigate();

  const handleLogoutClick = () => {
    // dispatch(logo)
    localStorage.removeItem("accessToken");
    localStorage.removeItem("sessionToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("user");
    navigate("/");
  };
  
  return (
    <>
      {userRole?.name === "owner" ? (
        <>
          {oldRequest.length > 0 ? (
              <>
              <Container
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
                className="mt-5"
              >
                <div
                  style={{
                    alignSelf: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img src={logo} alt="VAR" height={80} />
                </div>
              </Container>
              <Container
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh", // Ensure the Container covers the entire viewport height
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "25px",
                    fontWeight: "700",
                  }}
                >
                  <div>
                  <img  src={verifyImage} />
                  </div>
                  <br />
                  Your request has been successfully submitted for <br /> approval by the administrator.
                </div>
                <button
                  style={{
                    width: "271px",
                    height: "40px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    flexShrink: 0,
                    borderRadius: "999px",
                    borderColor: "orange",
                    background: "linear-gradient(97deg, #F15822 0%, #F7941D 100%)",
                    marginTop: "5px",
                  }}
                  onClick={handleLogoutClick}
                >
                  Log out
                </button>
              </Container>
            </>
          ) : (
            <>
              <OwnerRequest />
            </>
          )}
        </>
      ) : userRole?.name === "manager" ? (
        // Render something when userRole.name is 'manager'
        <>
          <Container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
            className="mt-5 mb-5"
          >
            <div
              style={{
                alignSelf: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src={logo} alt="VAR" height={80} />
            </div>
          </Container>
          <Container
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh", // Ensure the Container covers the entire viewport height
            }}
          >
            <div
              style={{
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "600",
              }}
            >
              Your account is not approved. Contact Owner to continue.
            </div>
            <button
              style={{
                width: "271px",
                height: "40px",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                flexShrink: 0,
                borderRadius: "999px",
                borderColor: "orange",
                background: "linear-gradient(97deg, #F15822 0%, #F7941D 100%)",
                marginTop: "5px",
              }}
              onClick={handleLogoutClick}
            >
              Log out
            </button>
          </Container>
        </>
      ) : (
        // Render something for other cases
        // <DefaultComponent />
        <></>
      )}
    </>
  );
};

export default Information;
