import { addCompanyUser, getCompanyUsers } from "../actionTypes";







const userReducer= (state=[], action)=>{
    switch(action.type){
        case getCompanyUsers:
            return action.payload;
        case addCompanyUser:
            return [...state, action.payload];
        default:
            return state;
    }
}

export default userReducer