import React, { useContext, useState } from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../Context/UserContext";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 20,
  },
  section: {
    marginBottom: 10,
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 10,
  },
  product: {
    flexDirection: "row",
    marginBottom: 5,
  },
  productName: {
    flexGrow: 1,
  },
  productPrice: {
    textAlign: "right",
  },
  container:{
    // backgroundColor:"red"
  }
  
});

const Receipt = ({ show, handleClose, selectedProducts, subTotal, discount, salesTax, total, selectedCustomer }) => {
  const [printDialogOpen, setPrintDialogOpen] = useState(false);
  const { language } = useContext(UserContext);
  const rowReverse = language === 'ar' ? "flex-row-reverse" : "";
  const { t } = useTranslation();

  const handlePrint = () => {
    setPrintDialogOpen(true);
    setTimeout(() => {
      window.print();
      setPrintDialogOpen(false);
    }, 500);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton style={{ flexDirection: language === 'ar' ? 'row-reverse': '', justifyContent: 'space-between' }}>
        <Modal.Title className={`d-flex ${rowReverse}`}>{t("Receipt")}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={styles.container} className={`d-flex ${rowReverse}`}>
        <Document  >
          <Page size="A4" style={styles.page}>
            <View style={styles.section}>
              <Text style={styles.title}>{t("Items:")}</Text>
              <br />
              {selectedProducts.map((product, index) => {
                console.log(product,'ASLKDKJAS')
                return(
                <View style={styles.product} key={product.id}>
                  {product.title ?(
                                      <Text style={styles.productName}>
                    {index + 1}.{t(product.title)}
                  </Text>):(                
                      <Text style={styles.productName}>
                    {index + 1}.{product.serviceItem}
                  </Text>)}

                

                  {product.price ?(         
                         <Text style={styles.productPrice}>
                         ${parseInt(product.price).toFixed(2)}
                       </Text>
                       ):(                
                      <Text style={styles.productName}>
                    {index + 1}.{product.model}
                  </Text>)}
                  
                  <br />
                  {product.mileageInterval ? ( 
                                <Text style={styles.productName}>
                    {t("Milage :")} {product.mileageInterval}
                  </Text>):(<></>)}
                  <br />
                  {product.action ? ( 
                                <Text style={styles.productName}>
                      {product.action}
                  </Text>):(<></>)}
                  
                  <br />
                  <br />
                </View>
              )})}
            </View>
            <br />
            <View style={styles.section}>
              <Text style={styles.title}>{t("Summary")}</Text> <br />
              <View style={styles.product}>
                <Text style={styles.productName}>{t("Sub-total:")}</Text>
                <Text style={styles.productPrice}> {subTotal.toFixed(2)}</Text>
              </View>
              <br />
              <View style={styles.product}>
                <Text style={styles.productName}>{t("Discount:")}</Text>
                <Text style={styles.productPrice}> {discount.toFixed(2)}</Text>
              </View>
              <br />
              <View style={styles.product}>
                <Text style={styles.productName}>{t("Sales Tax:")}</Text>
                <Text style={styles.productPrice}> {salesTax.toFixed(2)}</Text>
              </View>
              <View style={styles.product}>
                <Text style={styles.productName}>{t("Total:")}</Text>
                <Text style={styles.productPrice}> {total.toFixed(2)}</Text>
              </View>
              <br />
              <br />
              <View style={styles.product}>
                <Text style={styles.productName}>
                  <strong>{t("Customer")}</strong>
                </Text>{" "}
                <br />
                <Text style={styles.productPrice}>
                  {selectedCustomer?.name || ""}
                </Text>
              </View>
            </View>
          </Page>
        </Document>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t("Close")}
        </Button>
        <Button variant="primary" onClick={handlePrint}>
          {t("Print")}
        </Button>
      </Modal.Footer>
      {printDialogOpen && (
        <div style={{ position: "absolute", top: "-1000px" }}>
          <Document>
            <Page size="A4" style={styles.page}>
              <View style={styles.section}>
                <Text style={styles.title}>{t("Items:")}</Text>
                <br />
                {selectedProducts?.map((product, index) => (
                  <View style={styles.product} key={product.id}>
                    <Text style={styles.productName}>
                      {index + 1}.{t(product.title)}
                    </Text>
                    <Text style={styles.productPrice}>
                      ${parseInt(product.price).toFixed(2)}
                    </Text>
                    <br />
                  </View>
                ))}
              </View>
              <br />
              <View style={styles.section}>
                <Text style={styles.title}>{t("Summary")}</Text> <br />
                <View style={styles.product}>
                  <Text style={styles.productName}>{t("Sub-total:")}</Text>
                  <Text style={styles.productPrice}>
                    {" "}
                    {subTotal.toFixed(2)}
                  </Text>
                </View>
                <br />
                <View style={styles.product}>
                  <Text style={styles.productName}>{t("Discount:")}</Text>
                  <Text style={styles.productPrice}>
                    {" "}
                    {discount.toFixed(2)}
                  </Text>
                </View>
                <br />
                <View style={styles.product}>
                  <Text style={styles.productName}>{t("Sales Tax:")}</Text>
                  <Text style={styles.productPrice}>
                    {" "}
                    {salesTax.toFixed(2)}
                  </Text>
                </View>
                <View style={styles.product}>
                  <Text style={styles.productName}>{t("Total:")}</Text>
                  <Text style={styles.productPrice}>
                    {" "}
                    {total.toFixed(2)}
                  </Text>
                </View>
                <br />
                <br />
                <View style={styles.product}>
                  <Text style={styles.productName}>
                    <strong>{t("Customer")}</strong>
                  </Text>{" "}
                  <br />
                  <Text style={styles.productPrice}>
                    {selectedCustomer?.name || ""}
                  </Text>
                </View>
              </View>
            </Page>
          </Document>
        </div>
      )}
    </Modal>
  );
};

export default Receipt;
