import { loginType } from "../actionTypes";

const authReducer = (state = {}, action) => {
    switch (action.type) {
      case loginType:
        const accessToken = action.payload.accessToken;
        const sessionToken = action.payload.sessionToken;
        const id =action.payload.id;
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("sessionToken", sessionToken);
        localStorage.setItem("userId", id);
        ;
        return { ...state, accessToken };
      case "loginWithGoogle":
        const accessToke = action.payload.accessToken;
        localStorage.setItem("accessToken", accessToke);
        return { ...state, accessToken };
      case "logout":
        localStorage.removeItem("accessToken");
        localStorage.removeItem("sessionToken");
        return {};
      default:
        return state;
    }
  };
  
  export default authReducer;
  