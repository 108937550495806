import { getAllServiceCategory } from "../actionTypes"



const serviceCategoryReducer =(state=[], action)=>{
    switch(action.type){
        case getAllServiceCategory:
            return action.payload
        default:
            return state
    }
}


export default serviceCategoryReducer