import React from 'react';
import { Layout } from '../components';

const Inventory = () => {
  return (
    <Layout>
      <div>
        <h1>Inventory SCREEN</h1>
      </div>
    </Layout>
  );
};

export default Inventory;
