

//login action Types
export const loginType = "login";
export const registerType = "register";


// garage aciton types
export const addNewGarrage = 'addGarage'
export const getAllUserGarages = "getAllUserGarage"
export const editUserGarage = "editUserGarage"
export const getSIngleGarage = "getsingleGarage"
export const deletGarage = 'deleteGarage'


// service Category
export const getAllServiceCategory ='getAllServiceCategories'


// services

export const getGarageServices = 'getGarageServices'
export const getGarageServicesSchedule = 'getGarageServicesSchedule'

export const filterServices = 'filterbyCategory'
export const createGarageService = 'addGarageService'


// company

export const getCompanyUsers = 'getAllCompanyUsers'
export const addCompanyUser = 'addCompanyUser'
export const assignGarage = 'assignGarage'


export const selectGarage = 'selectGarage'

export const getAllProductCategory = 'getAllProductCategory'


export const getGarageProducts = 'getGarageProducts'
export const createGarageProducts = 'createGarageProducts'


export const getProductsByUIDs ='getProductsByUIDs';
export const addProduct="addGarageProduct";
