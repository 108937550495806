import { Layout } from "../components"

const Transactions =()=>{
    return(
        <Layout>
            <h1>TransactionsS</h1>
        </Layout>
        
    )
}


export default Transactions