// AssignUserModal.js
import React, { useContext, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../Context/UserContext";

const AssignUserModal = ({ show, handleClose, users, handleAssignUserSubmit }) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { language } = useContext(UserContext);
  const rowReverse = language === 'ar' ? "flex-row-reverse" : "";
  const { t } = useTranslation();

  // Sample options for react-select
  const options = users.map((user) => ({
    value: user.id,
    label: user.name,
  }));

  const handleUserSelectionChange = (selectedOptions) => {
    setSelectedUsers(selectedOptions);
  };

  const handleAssignUserClick = () => {
    // Pass the selected users to the parent component
    handleAssignUserSubmit(selectedUsers);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton style={{ flexDirection: language === 'ar' ? 'row-reverse': '', justifyContent: 'space-between' }}>
        <Modal.Title>{t("Assign User")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formAssignUser">
            <Form.Label className={`d-flex ${rowReverse}`}>{t("Select User")}</Form.Label>
            {/* Dropdown to select users */}
            <Select
              options={options}
              isMulti
              placeholder={t("Select User")}
              onChange={handleUserSelectionChange}
            />
          </Form.Group>
          <div className={`d-flex ${rowReverse} mt-3`}>
            <Button variant="primary" onClick={handleAssignUserClick}>
              {t("Assign User")}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AssignUserModal;
