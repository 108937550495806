import { getAllProductCategory } from "../actionTypes"



const ProductCategoryReducer =(state=[], action)=>{
    switch(action.type){
        case getAllProductCategory:
            return action.payload
        default:
            return state
    }
}


export default ProductCategoryReducer