// OTP.js

import React, { useState } from "react";
import { Col, Container, Row, Form, Button, Image } from "react-bootstrap";
import "./otp.css"; // Add a CSS file for custom styling
import logo from "../../assets/Logo.png";
import { Link, useNavigate } from "react-router-dom";

const OTP = () => {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const email = "azharnaeem76@gmail.com";

  const handleOtpChange = (index, event) => {
    const inputOtp = event.target.value.replace(/\D/g, ""); // Remove non-digit characters
    const newOtp = [...otp];
    newOtp[index] = inputOtp.slice(-1); // Only take the last character
    setOtp(newOtp);

    // Move focus to the next input box
    if (event.target.value && index < otp.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const navigate = useNavigate()
  const handleSubmit = (event) => {
    event.preventDefault();
    const enteredOtp = otp.join(""); // Combine individual digits into OTP
    console.log("Entered OTP:", enteredOtp);
      navigate("/setupPassword");
    // Perform further action with the entered OTP
  };

  return (
    <Container fluid style={{ height: "100vh" }}>
      <Row style={{ height: "100%" }}>
        <Col
          md={4}
          className="login-form d-flex"
          style={{
            flexDirection: "column",
            justifyContent: "center",
            padding: "10px 30px 0 30px",
          }}
        >
          <div className="logo-container">
            <Image
              src={logo}
              alt="Logo"
              fluid
              className="logo"
              height={90}
              width={90}
            />
          </div>

          <div
            className="d-flex"
            style={{
              flexGrow: 1,
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div className="text-header">
              <p>Enter Verification Code</p>
              <p>
                Code sent to {email} This code will expire in 01:30
              </p>
            </div>

            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicOTP" className="mb-3 d-flex">
                {otp.map((digit, index) => (
                  <Form.Control
                    key={index}
                    id={`otp-input-${index}`}
                    type="text"
                    placeholder="0"
                    value={digit}
                    onChange={(e) => handleOtpChange(index, e)}
                    className={`formControl otp-input ${digit ? "filled" : ""}`}
                    maxLength={1}
                  />
                ))}
              </Form.Group>

              <Button variant="primary" type="submit" className="signin-btn" disabled={otp.some(digit => digit === "")}>
                Submit
              </Button>

              <p className="signup-text">
                <Link to="/forgetPassword" style={{ color: "#F7941D" }}>
                  Back
                </Link>
              </p>
            </Form>
          </div>
        </Col>

        <Col md={8} className="background-image" style={{ height: "100%" }}>
          {/* Add image to the background */}
          <div className="text">
            <h4>Features of VAR Mobile Application</h4>
            <p>
              Will enable users to get all required services with only one click
              away. This convenience will eliminate the hustle and emergency
              situations which any car owner faces when there is an urgent need
              and as well as the routine care of their vehicle.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default OTP;
