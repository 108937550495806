import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout } from "../components";
import { Row, Col, Container, Button } from "react-bootstrap";
import {
  getServiceSchedule,
  getServiceScheduleBYMODEL,
} from "../service/action/serviceAction";
import http from "../utils/Api";
import Receipt from "../components/Receipt";
import CustomerSelectionModal from "../components/customerSelectionModal";
import { useTranslation } from "react-i18next";
import { UserContext } from "../Context/UserContext";

const ServiceSchedule = () => {
  const selectedGarage = useSelector((state) => state.selectedGarage);
  const garageList = useSelector((state) => state.garages);
  const serviceSchedules = useSelector((state) => state.serviceSchedule);
  const [loading, setLoading] = useState(true);
  const [selectedServices, setSelectedServices] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);

  const [showReceipt, setShowReceipt] = useState(false);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);

  const [selectedVehicle, setSelectedVehicle] = useState([]);
  const { language } = useContext(UserContext);
  const rowReverse = language === 'ar' ? "flex-row-reverse" : "";
  const { t } = useTranslation();

  useEffect(() => {
    getGarageCustomers();
  }, [selectedGarage]);

  const getGarageCustomers = async () => {
    const token = localStorage.getItem("accessToken");
    const config = http.generateConfig(token);
    try {
      setLoading(true);
      const allCustomers = await http.get(
        `/api/customerGarage/${selectedGarage.id}`,
        config
      );
      console.log(allCustomers, "HTE ALL CUSTOMER ");
      setCustomerList(allCustomers);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false regardless of success or failure
    }
  };

  const handleCreateCustomer = async (newCustomerData) => {
    const token = localStorage.getItem("accessToken");
    const res = await http.post(
      `/api/customerPos`,
      { ...newCustomerData, garageId: selectedGarage.id },
      http.generateConfig(token)
    );
    setSelectedCustomer(res);
    setCustomerList([...customerList, newCustomerData]);
  };
  const handleCreateVehicle = async (newVehicleData) => {
    const token = localStorage.getItem("accessToken");
    const res = await http.post(
      `/api/vehiclePos`,
      { ...newVehicleData, customerId: selectedCustomer.id },
      http.generateConfig(token)
    );
    console.log(res, "THE RESPIONSE");
    setSelectedVehicle(res);
    setVehicleList([...vehicleList, newVehicleData]);
  };

  const handleCloseReceipt = () => {
    setShowReceipt(false);
    setSelectedCustomer(null);
    setSelectedProducts([]);
  };

  const handlePrintReceipt = () => {
    if (!!selectedCustomer) {
      setShowReceipt(true);
    } else {
      setShowCustomerModal(true);
    }
  };
  const dispatch = useDispatch();

  useEffect(() => {
    fetchBrands();
  }, []);

  const fetchBrands = async () => {
    try {
      const token = localStorage.getItem("accessToken");

      const response = await http.get("api/brand", http.generateConfig(token));
      console.log(response, "THE RESPONSE HERE");
      if (response) {
        setBrands(response);
      }
    } catch (error) {
      console.error("Error fetching brands:", error);
    }
  };

  // useEffect(() => {
  //   dispatch(
  //     getServiceSchedule({
  //       id: 1,
  //       garageId: selectedGarage.id,
  //       brands: selectedGarage.brands,
  //     })
  //   );
  // }, [selectedGarage]);

  // const handleServiceItemClick = (service) => {
  //   console.log(service,'THE SERVICE HERE');
  //   // // Create a unique key for the selected service
  //   // const key = `${service.serviceItem._id}-${item.mileageInterval}-${item.action}-${index}`;

  //   // // Add the selected service to the list of selected services
  //   // setSelectedServices((prevSelected) => [
  //   //   ...prevSelected,
  //   //   {
  //   //     key: key,
  //   //     serviceItem: service.serviceItem.title,
  //   //     model: model.model,
  //   //     mileageInterval: item.mileageInterval,
  //   //     action: item.action,
  //   //   },
  //   // ]);
  // };

  const handleServiceItemClick = (service) => {
    console.log(service, "THE SERVICE HERE");
    // Create a unique key for the selected service
    const key = `${service._id}-${service.mileageInterval}-${service.action}`;

    // Add the selected service to the list of selected services
    setSelectedServices((prevSelected) => [
      ...prevSelected,
      {
        key: key,
        serviceItem: service.serviceItem.title,
        model: selectedVehicleModel.title,
        mileageInterval: service.mileageInterval,
        action: service.action,
      },
    ]);
  };

  const [vehicleModels, setVehicleModels] = useState([]);
  const [vehModel, setvehModel] = useState(null);
  const [selectedVehicleModel, setSelectedVehicleModel] = useState(null);
  // const [selectedEngineType, setSelectedEngineType] = useState("");

  const handeVehicleModelChange = (event) => {
    // setSelectedEngineType("");
    setvehModel(event.target.value);
    const selectedModel = vehicleModels.find(
      (model) => model.id === Number(event.target.value)
    );
    setSelectedVehicleModel(selectedModel);

    const payload = {
      brandId: selectedBrand,
      vehicleModel: selectedModel,
    };

    dispatch(getServiceScheduleBYMODEL(payload));
  };
  // const handleEngineTypeChange = (event) => {
  //   setSelectedEngineType(event.target.value);
  //   const payload ={
  //     brandId: selectedBrand,
  //     vehicleModel: selectedVehicleModel,
  //   }

  // };

  const handleBrandChange = (event) => {
    setSelectedBrand(event.target.value);
    getBrandVehicles(event.target.value);
  };
  const getBrandVehicles = async (brandId) => {
    try {
      const token = localStorage.getItem("accessToken");
      const config = http.generateConfig(token);
      const br = await http.get(`api/vehicleModelID/${brandId}`, config);
      console.log(br.result, "AKUSBJHDAS");
      if (br && br.success) {
        setVehicleModels(br.result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const filteredServiceSchedules = selectedBrand
  //   ? serviceSchedules.filter((serviceSchedule) =>
  //       serviceSchedule.models.some((model) =>
  //         model.services.some((service) =>
  //           service.some((serv) =>
  //             serv.items.some((item) => item.brandId === selectedBrand)
  //           )
  //         )
  //       )
  //     )
  //   : serviceSchedules;
  // console.log(filteredServiceSchedules, "THE FILTERED SERVICE SCHEDUELS");

  return (
    <Layout>
      {garageList.length <= 0  ?(<p className={`d-flex ${rowReverse}`}>{t("No Garage Assigned")}</p>):(
        <>
         <div className={`d-flex ${rowReverse}`}>
            <Col xs={8} style={{ overflowY: "auto" }}>
              <div
                style={{
                  padding: "14px 35px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  backgroundColor: "#F15822",
                  color: "#fff",
                  border: "1px solid #BFD4E0",
                  margin: "10px",
                  cursor: "pointer",
                }}
              >
                {t("Quick Maintenance")}
              </div>
              <select
                value={selectedBrand}
                onChange={handleBrandChange}
                style={{ marginLeft: "10px" }}
              >
                <option value="">{t("Select Brand")}</option>
                {brands.map((brand) => (
                  <option key={brand.id} value={brand.id}>
                    {t(brand.title)}
                  </option>
                ))}
              </select>
              {/* vehicleModels */}
              <select
                value={vehModel}
                onChange={handeVehicleModelChange}
                style={{ marginLeft: "10px" }}
              >
                <option value="">{t("Select Vehicle")}</option>
                {vehicleModels.map((vehicleModel) => (
                  <option key={vehicleModel.id} value={vehicleModel.id}>
                    {t(vehicleModel.title)}
                  </option>
                ))}
              </select>
              {/* <select
                value={selectedEngineType}
                onChange={handleEngineTypeChange}
                style={{ marginLeft: "10px" }} 
              >
                <option value="">Select Engine Type</option>
                {selectedVehicleModel &&
                  selectedVehicleModel?.engineTypes &&
                  selectedVehicleModel?.engineTypes.map((engineType, index) => (
                    <option key={index} value={engineType}>
                      {engineType}
                    </option>
                  ))}
              </select> */}

              <Container
                fluid
                className={`d-flex flex-wrap mt-3 ${rowReverse}`}
                style={{
                  height: "60vh",
                  overflowY: "auto",
                }}
              >
                {serviceSchedules &&
                  serviceSchedules?.map((serviceSch) => {
                    const isSelected = selectedServices.some(
                      (selectedService) =>
                        selectedService.key ===
                        `${serviceSch._id}-${serviceSch.mileageInterval}-${serviceSch.action}`
                    );

                    return (
                      <Container
                        key={serviceSch._id} // Add key prop for React rendering optimization
                        fluid
                        className={`d-flex flex-wrap mt-3 ${rowReverse}`}
                        style={{
                          borderRadius: "15px",
                          gap: "5px",
                          width: "320.768px",
                          backgroundColor: isSelected ? "#ccc" : "#fff", // Change background color to gray if service is selected
                          borderRadius: "13px",
                          padding: "10px",
                          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                          cursor: isSelected ? "not-allowed" : "pointer", // Change cursor to not-allowed if service is selected
                        }}
                        onClick={() =>
                          !isSelected && handleServiceItemClick(serviceSch)
                        } // Only handle click if service is not selected
                      >
                        <div style={{ marginBottom: "10px" }}>
                          <strong>{serviceSch?.serviceItem?.title}</strong>
                        </div>
                        <div>
                          <h6 style={{ margin: "0" }} className={`d-flex ${rowReverse}`}>
                            {t("Mileage:")} {serviceSch.mileageInterval} - {t("Action:")}{" "}
                            {serviceSch.action}
                          </h6>
                        </div>
                      </Container>
                    );
                  })}
              </Container>
            </Col>
            <Col xs={4} style={{ border: "1px solid #C7D5E1" }}  >
              <div
                style={{
                  height: "80vh",
                  overflowY: "auto",
                  padding: "20px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ flex: "1 1 auto", overflowY: "auto" }}>
                  <p style={{ fontWeight: "bold" }} className={`d-flex ${rowReverse}`}>{t("Selected Services:")}</p>
                  {selectedServices?.map((service, index) => (
                    <div
                      key={service.key} // Use the generated key here
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "10px 10px 0 10px",
                        backgroundColor: "#fff",
                        borderRadius: "13px",
                        marginBottom: "10px",
                      }}
                    >
                      <div>
                        <p className={`d-flex ${rowReverse}`} style={{ fontWeight: "bold" }}>{t(service.serviceItem)}</p>
                        <p className={`d-flex ${rowReverse}`}>{t("Model:")} {t(service.model)}</p>
                        <p className={`d-flex ${rowReverse}`}>{t("Mileage:")} {service.mileageInterval}</p>
                        <p className={`d-flex ${rowReverse}`}>{t("Action:")} {t(service.action)}</p>
                      </div>
                      <Button
                        variant="danger"
                        onClick={() => {
                          // Remove the selected service from the list of selected services
                          const updatedServices = [...selectedServices];
                          updatedServices.splice(index, 1);
                          setSelectedServices(updatedServices);
                        }}
                      >
                        x
                      </Button>
                    </div>
                  ))}
                </div>
                <Button
                  style={{
                    width: "100%",
                    height: "40px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    borderRadius: "999px",
                    borderColor: "orange",
                    background: "linear-gradient(97deg, #F15822 0%, #F7941D 100%)",
                    marginTop: "10px",
                  }}
                  onClick={handlePrintReceipt}
                >
                  {t("Print Receipt")}
                </Button>
              </div>
            </Col>
        </div>
      <Receipt
        show={showReceipt}
        handleClose={handleCloseReceipt}
        selectedProducts={selectedServices}
        subTotal={0}
        discount={0}
        salesTax={0}
        total={0}
        selectedCustomer={selectedCustomer}
      />

      <CustomerSelectionModal
        customerList={customerList}
        onCreateCustomer={handleCreateCustomer}
        onCreateVehicle={handleCreateVehicle}
        showModal={showCustomerModal}
        setSelectedVehicle={setSelectedVehicle}
        setShowModal={setShowCustomerModal}
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
        brands={brands}
        selectedService={selectedServices}
        flow="Schedule"
      />
        </>
      )}
     
    </Layout>
  );
};

export default ServiceSchedule;
