import { Button, Container, Row, Col, Modal, Form } from "react-bootstrap";
import { Layout, OrderStats } from "../components";
import { useContext, useEffect, useState } from "react";
import noService from "../assets/noService.png";
import userImage from "../assets/userImage.png";
import dots from "../assets/dots.png";
import location from "../assets/location.svg";
import "../App.css";
import serviceRating from "../components/rattingStars";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getServiceCategories } from "../service/action/serviceCategory";
import { getUserAssignedGarages } from "../service/action/garageAction";
import {
  createService,
  getAllServices,
  getServicesByCategory,
} from "../service/action/serviceAction";
import CreatableSelect from "react-select/creatable";
import http from "../utils/Api";
import Receipt from "../components/Receipt";
import CustomerSelectionModal from "../components/customerSelectionModal";
import { useTranslation } from "react-i18next";
import { UserContext } from "../Context/UserContext";
const Services = () => {
  const initialFormData = {
    serviceName: "",
    serviceCategory: null,
    subcategoryId: "",
    duration: "",
    garageList: [],
    specifications: {},
    price:0
  };
  const selectedGarage = useSelector((state) => state.selectedGarage);
  const [isSpecificationAdded, setIsSpecificationAdded] = useState(false);


  const [loading, setLoading] = useState(true);

  const [showReceipt, setShowReceipt] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState(0);
  const [title, setTitle] = useState("");
  const { language } = useContext(UserContext);
  const rowReverse = language === 'ar' ? "flex-row-reverse" : "";
  const { t } = useTranslation();


  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const dispatch = useDispatch();

  const [isAddingService, setAddingService] = useState(false);

  const handleAddServiceClick = () => {
    setIsSpecificationAdded(false);

    setAddingService(true);
  };

  const handleBackToListClick = () => {
    setAddingService(false);
  };

  // Modal state
  const [showModal, setShowModal] = useState(false);

  const handleServiceCreate = (data) => {
        dispatch(createService(formData));
    setShowModal(false);
    setAddingService(false);
    resetFormData();
  };
  // const handleServiceCreate = (data) => {
  //   console.log(data, "THE asdasdas");
  //   const formData = new FormData();

  //   // Append the fields to FormData
  //   formData.append("serviceName", data.serviceName);
  //   formData.append("serviceCategory_id", data.serviceCategory.id);
  //   formData.append("serviceSubcategoryId", data.subcategoryId);
  //   formData.append("price", data.price);
  //   formData.append("duration", data.duration);
  //   formData.append("description", data.description);
  //   formData.append("specifications", data.specifications);
  //   console.log(data.specifications, "asfs");
  //   // Append the garage list as an array of garage IDs
  //   const garageIds = data.garageList.map((garage) => garage.id);
  //   formData.append("garageList", JSON.stringify(garageIds));
  //   console.log(data, "THE ASODOASD");
  //   // Append each image file
  //   data.uploadPhotos.forEach((photo, index) => {
  //     console.log(photo, "the upload images");
  //     if (photo) {
  //       formData.append(`images`, photo);
  //     }
  //   });
  //   dispatch(createService(formData));
  //   setShowModal(false);
  //   setAddingService(false);
  //   resetFormData();
  // };

  const handleShowModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    dispatch(getServiceCategories());
    dispatch(getUserAssignedGarages());
  }, []);

  useEffect(() => {
    dispatch(getAllServices({ garageId: selectedGarage.id }));
    console.log(allServices,'THE AKL ASKJD')
  }, [selectedGarage]);

  const [formData, setFormData] = useState({
    serviceName: "",
    serviceCategory: null,
    subcategoryId: "",
    duration: "",
    garageList: [],
    specifications: {},
  });

  const [brands, setBrands] = useState([]);

  useEffect(() => {
    fetchBrands();
  }, []);

  const fetchBrands = async () => {
    try {
      const token = localStorage.getItem("accessToken");

      const response = await http.get("api/brand", http.generateConfig(token));
      console.log(response, "THE RESPONSE HERE");
      if (response) {
        setBrands(response);
      }
    } catch (error) {
      console.error("Error fetching brands:", error);
    }
  };

  const serviceCategories = useSelector((state) => state.serviceCategories);
  const garageList = useSelector((state) => state.garages);
  const allServices = useSelector((state) => state.services);
  // const serviceSchedules = useSelector((state) => state.serviceSchedule);
  const handleCategoryClick = (categoryId) => {
    // Update the selected category state
    setSelectedCategory(categoryId);
    dispatch(
      getServicesByCategory({
        id: categoryId,
        garageId: selectedGarage.id,
        brands: selectedGarage.brands,
      })
    );
  };

  const formattedServiceCategories = [
    { value: "All Services", label: "All Services", id: 0 }, // New option
    ...serviceCategories.map((category) => ({
      value: category.title,
      label: category.title,
      id: category.id,
    })),
  ];

  const formattedGarageList = garageList.map((garage) => ({
    value: garage.name,
    label: garage.name,
    id: garage.id,
  }));

  const [subCategory, setSubCategory] = useState([]);

  // let formattedServiceSubCategories = [

  //   ...subCategory.map((category) => ({
  //     value: category.title,
  //     label: category.title,
  //     id: category.id,
  //   })),
  // ];
  const handleServiceCategoryChange = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      serviceCategory: selectedOption,
    }));
    // get the sub categories for the selected Service
    getSubCategory(selectedOption.id);
  };

  const handleSubCategoryChange = (selectedOption) => {
    setFormData({
      ...formData,
      subcategoryId: selectedOption,
    });
  };

  const getSubCategory = async (category) => {
    const token = localStorage.getItem("accessToken");

    const res = await http.get(
      `/api/getSubCategoryByCatId/${category}`,
      http.generateConfig(token)
    );
    if (res) {
      setSubCategory(res.result);
    }
  };

  const handleInputChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  const handleGarageListChange = (selectedOptions) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      garageList: selectedOptions,
    }));
  };

  const handleSpecificationChange = (selectedOptions) => {
    if (!Array.isArray(selectedOptions)) {
      // If selectedOptions is not an array, handle it appropriately
      return;
    }

    // Map selectedOptions to the desired structure
    const updatedSpecifications = {};
    selectedOptions.forEach((option) => {
      updatedSpecifications[option.label] = [];
    });

    // Update the formData with the new specifications
    setFormData((prevFormData) => ({
      ...prevFormData,
      specifications: updatedSpecifications,
    }));
    setIsSpecificationAdded(true);

  };

  const handleRemoveImage = (index) => {
    setFormData((prevFormData) => {
      const updatedPhotos = [...prevFormData.uploadPhotos];
      updatedPhotos.splice(index, 1);
      return {
        ...prevFormData,
        uploadPhotos: updatedPhotos,
      };
    });
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    // Convert the FileList to an array and update the state
    const photoArray = Array.from(files);
    setFormData((prevFormData) => ({
      ...prevFormData,
      uploadPhotos: [...prevFormData.uploadPhotos, ...photoArray],
    }));
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    // Convert the FileList to an array and update the state
    const photoArray = Array.from(files);
    setFormData((prevFormData) => ({
      ...prevFormData,
      uploadPhotos: [...prevFormData.uploadPhotos, ...photoArray],
    }));
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const resetFormData = () => {
    setFormData(initialFormData);
  };

  const [subcategories, setSubcategories] = useState([]);
  const [newSubcategoryName, setNewSubcategoryName] = useState("");
  const handleNewSubcategoryChange = (newValue) => {
    setNewSubcategoryName(newValue);
    setFormData({
      ...formData,
      subcategoryId: newValue.id,
    });
  };
  const [formattedServiceSubCategories, setFormattedServiceSubCategories] =
    useState([]);

  useEffect(() => {
    // Update formattedServiceSubCategories when subCategory changes
    const formattedSubCategories = subCategory.map((subcategory) => ({
      value: subcategory.title,
      label: subcategory.title,
      id: subcategory.id,
    }));
    setFormattedServiceSubCategories(formattedSubCategories);

    // Log formattedServiceSubCategories after it has been updated
    console.log(formattedServiceSubCategories, "THE FORMATTED SUBCATEGORIES");
  }, [subCategory]);

  const handleAddNewSubcategory = async (inputValue) => {
    if (!inputValue.trim()) {
      alert("Please enter a valid subcategory name");
      return;
    }

    const token = localStorage.getItem("accessToken");
    try {
      const res = await http.post(
        "/api/subCategory",
        {
          serviceCategoryId: formData?.serviceCategory.id,
          title: inputValue,
        },
        http.generateConfig(token)
      );
      console.log(res, "TEH RESPONSE");
      if (res.status === 200) {
        // Subcategory created successfully
        const newSubcategory = { value: res.data.id, label: res.data.name };

        // Update the subCategory state with the newly added subcategory
        setSubCategory((prevSubCategory) => [...prevSubCategory, res.data]);

        // Update formattedServiceSubCategories with the new subcategory
        setFormattedServiceSubCategories((prevFormattedSubCategories) => [
          ...prevFormattedSubCategories,
          { value: res.data.title, label: res.data.title, id: res.data.id },
        ]);
        console.log(formattedServiceSubCategories, "THE FORMATTED SIB");
        alert("New subcategory added successfully");
      }
    } catch (error) {
      console.error("Error creating subcategory:", error);
      alert("Error creating subcategory. Please try again later.");
    }
  };

  const [selectedProducts, setSelectedProducts] = useState([]);

  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [selectedVehicle, setSelectedVehicle] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);

  const handleCreateCustomer = async (newCustomerData) => {
    const token = localStorage.getItem("accessToken");
    const res = await http.post(
      `/api/customerPos`,
      { ...newCustomerData, garageId: selectedGarage.id },
      http.generateConfig(token)
    );
    setSelectedCustomer(res);
    setCustomerList([...customerList, newCustomerData]);
  };

  const handleCreateVehicle = async (newVehicleData) => {
    const token = localStorage.getItem("accessToken");
    const res = await http.post(
      `/api/vehiclePos`,
      { ...newVehicleData, customerId: selectedCustomer.id },
      http.generateConfig(token)
    );
    console.log(res, "THE RESPIONSE");
    setSelectedVehicle(res);
    setVehicleList([...vehicleList, newVehicleData]);
  };
  const handleAddToCart = (product) => {
    setSelectedProducts((prevSelectedProducts) => {
      // Check if the product already exists in the selected products array
      const existingProductIndex = prevSelectedProducts.findIndex(
        (p) => p.id === product.id
      );

      if (existingProductIndex !== -1) {
        // If the product already exists, update its count
        const updatedProducts = [...prevSelectedProducts];
        updatedProducts[existingProductIndex] = {
          ...updatedProducts[existingProductIndex],
          count: updatedProducts[existingProductIndex].count + 1,
        };
        return updatedProducts;
      } else {
        // If the product does not exist, add it with count 1
        return [...prevSelectedProducts, { ...product, count: 1 }];
      }
    });
  };

  const handleRemoveFromCart = (product) => {
    setSelectedProducts((prevSelectedProducts) => {
      const existingProductIndex = prevSelectedProducts.findIndex(
        (p) => p.id === product.id
      );

      if (existingProductIndex !== -1) {
        const updatedProducts = [...prevSelectedProducts];
        // If the count of the product is greater than 1, decrement the count
        if (updatedProducts[existingProductIndex].count > 1) {
          updatedProducts[existingProductIndex] = {
            ...updatedProducts[existingProductIndex],
            count: updatedProducts[existingProductIndex].count - 1,
          };
          return updatedProducts;
        } else {
          // If the count is 1, remove the product from the selected products array
          return prevSelectedProducts.filter((p) => p.id !== product.id);
        }
      } else {
        return prevSelectedProducts;
      }
    });
  };

  const discount = 0;
  const salesTax = 0;

  // Function to calculate subtotal
  const calculateSubTotal = () => {
    return selectedProducts.reduce((total, product) => {
      // Multiply each product's price by its quantity and add it to the total
      return total + product.price * product.count;
    }, 0);
  };

  const subTotal = calculateSubTotal();
  const total = subTotal - discount + salesTax;

  const getProductCount = (product) => {
    const selectedProduct = selectedProducts.find((p) => p.id === product.id);
    return selectedProduct ? selectedProduct.count : 0;
  };
  const handlePrintReceipt = () => {
    if (!!selectedCustomer) {
      setShowReceipt(true);
    } else {
      setShowCustomerModal(true);
    }
  };

  const handleCloseReceipt = () => {
    setShowReceipt(false);
    setSelectedCustomer(null);
    setSelectedProducts([]);
  };

  const [customerList, setCustomerList] = useState([]);

  useEffect(() => {
    getGarageCustomers();
  }, [selectedGarage]);
  const getGarageCustomers = async () => {
    const token = localStorage.getItem("accessToken");
    const config = http.generateConfig(token);
    try {
      setLoading(true);
      const allCustomers = await http.get(
        `/api/customerGarage/${selectedGarage.id}`,
        config
      );
      console.log(allCustomers, "HTE ALL CUSTOMER ");
      setCustomerList(allCustomers);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false regardless of success or failure
    }
  };


  // Function to handle changes in the specification name
  const handleSpecificationNameChange = (index, key, value) => {
    setFormData((prevFormData) => {
      const updatedSpecifications = { ...prevFormData.specifications };
      updatedSpecifications[key][index] = {
        ...updatedSpecifications[key][index],
        name: value,
      };
      return {
        ...prevFormData,
        specifications: updatedSpecifications,
      };
    });

  };

  // Function to handle changes in the specification value
  const handleSpecificationValueChange = (index, key, value) => {
    setFormData((prevFormData) => {
      const updatedSpecifications = { ...prevFormData.specifications };
      updatedSpecifications[key][index] = {
        ...updatedSpecifications[key][index],
        value: value,
      };
      return {
        ...prevFormData,
        specifications: updatedSpecifications,
      };
    });

  };

  // Function to handle adding a new specification to the existing group
  const handleAddSpecificationGroup = () => {
    setFormData((prevFormData) => {
      const updatedSpecifications = { ...prevFormData.specifications };
      const groupKeys = Object.keys(updatedSpecifications);
      const lastGroupKey = groupKeys[groupKeys.length - 1]; // Select the key of the last group in specifications
      updatedSpecifications[lastGroupKey].push({ name: "", value: "" }); // Add a new specification to the last group
      return {
        ...prevFormData,
        specifications: updatedSpecifications,
      };
    });
  };

  // Function to add a new specification
  const handleAddSpecification = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      specifications: [...prevFormData.specifications, { name: "", value: "" }],
    }));
  };

  const [showServiceSchedule, setShowServiceSchedule] = useState(false);

  const handleToggleServiceSchedule = () => {
    setShowServiceSchedule((prevState) => !prevState);
  };

  const flex_rev_class = `d-flex ${rowReverse}`;

  return (
    <Layout>
      {garageList.length<=0 ? (<p className={flex_rev_class}>{t("No Garage Assigned")}</p>):(<>
        <div className={flex_rev_class}>
        <Col xs={8} style={{ overflowY: "auto" }}>
          <Container
            style={{
              display: "flex",
              overflowX: "auto",
            }}
            className={flex_rev_class}
          >
            {formattedServiceCategories?.map((category, index) => (
              <div
                key={index}
                onClick={() => handleCategoryClick(category?.id)}
                style={{
                  flex: "0 0 auto",
                  padding: "14px 35px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  backgroundColor:
                    selectedCategory === category?.id ? "#F15822" : "#FFFFFF",
                  color:
                    selectedCategory === category?.id ? "#FFFFFF" : "#545B68",
                  border:
                    selectedCategory === category?.id
                      ? "none"
                      : "1px solid #BFD4E0",
                  margin: "10px",
                  cursor: "pointer",
                }}
              >
                {t(category?.value)}
              </div>
            ))}
          </Container>

          {allServices && allServices?.length <= 0 ? (
            // No services found
            <Container
              fluid
              className="d-flex flex-column align-items-center justify-content-center text-center p-5"
            >
              <img src={noService} alt="no Service" height={250} width={250} />
              <h3 style={{ fontWeight: 600 }}>{t("No Services Found")}</h3>
              <span
                className="mb-4"
                style={{
                  color: "#757575",
                  textAlign: "center",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                }}
              >
                {t(`Welcome to the VAR! This screen is your starting point`)} <br /> 
                {t(`to tailor and showcase the unique services your service`)} <br />{" "}
                {t("offers.")}
              </span>
              <Button
                style={{
                  display: "flex",
                  width: "271px",
                  height: "40px",
                  padding: "20px 43px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  flexShrink: 0,
                  borderRadius: "999px",
                  borderColor: "orange",
                  background:
                    "linear-gradient(97deg, #F15822 0%, #F7941D 100%)",
                }}
                onClick={handleAddServiceClick}
              >
                {t("Add New Service")}
              </Button>
          
            </Container>
          ) : (
            // Render the product list
            <>
              <div className={`align-items-center mt-2 ${flex_rev_class}`}>
                <Col md={6}>
                  <p
                    style={{
                      fontSize: "24px",
                      fontWeight: 600,
                    }}
                    className={flex_rev_class}
                  >
                    {t("Service")}
                  </p>
                </Col>

                <Col md={6} className={`d-flex justify-content-end ${rowReverse}`}>
                  <Button
                    onClick={handleAddServiceClick}
                    style={{
                      display: "flex",
                      width: "210px",
                      height: "40px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                      flexShrink: 0,
                      borderRadius: "999px",
                      borderColor: "orange",
                      background:
                        "linear-gradient(97deg, #F15822 0%, #F7941D 100%)",
                    }}
                  >
                    {t("Add New Service")}
                  </Button>
                  
                </Col>
              </div>

              <Container
                fluid
                className={`d-flex flex-wrap  mt-3 ${rowReverse}`}
                style={{ borderRadius: "15px", gap: "5px" }}
              >
                {allServices &&
                  allServices?.map((service, index) => (
                    <div
                      key={service.id}
                      style={{
                        width: "348.768px",
                        padding: "15px",
                        backgroundColor: "#fff",
                        borderRadius: "13px",
                        marginBottom:
                          index < allServices.length - 1 ? "15px" : 0,
                        // textAlign:'center',
                        // justifyContent:"center",
                      }}
                      onClick={() => handleAddToCart(service)}
                    >
                      <div className={`d-flex justify-content-between align-items-center md-4 ${rowReverse}`}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {service.image && service.image.length > 0 ? (
                            <></>
                          ) : (
                           <></>
                          )}

                          <div>
                            <div style={{ fontSize: "16px", fontWeight: 600 }}>
                              {/* {service.title.toUpperCase()} */}
                            </div>
                           
                            <div style={{ fontSize: "18px", fontWeight: 600 }}>
                              {service?.serviceCategory ? (
                                <>{service?.serviceCategory?.title}</>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div>
                              {service.specification ? (
                              <>
                              {Object.keys(service.specification).map((specification)=>{
                                console.log(service.specification[specification],'JAS')
                                return(
                                  <>
                                  <div style={{ fontSize: "16px", fontWeight: 600 }}>
                                  {specification}
                                  </div>
                                  {service.specification && service.specification[specification]?.map((items)=>{
                                    return(
                                      <>
                                      <div style={{ fontSize: "14px", fontWeight: 600 }}>
                                      {items.name} - {items.value}
                                      </div>
                                      </>
                                    )
                                  })}
                                  </>
                                )
                              })}
                              </>
                              ):(<></>)}
                            </div>
                            {service?.subCategory ? (
                              <>
                                <div
                                  style={{ fontSize: "14px", color: "#757575" }}
                                >
                                  {service?.serviceCategory?.title} /{" "}
                                  {service?.serviceSubCategory?.title}
                                </div>
                              </>
                            ) : (
                              <></>
                            )}

                            <div style={{ fontSize: "14px", color: "#757575" }}>
                              {service?.price}/ SAR
                            </div>
                            <div
                              style={{ fontSize: "14px", color: "#757575" }}
                            ></div>
                          </div>
                        </div>
                        <div>
                          <img src={dots} alt="dots" />
                        </div>
                      </div>

                      <div>
                        <span
                          style={{
                            color: "#585858",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineheight: "normal",
                          }}
                        >
                          {service?.description}
                        </span>
                      </div>
                    </div>
                  ))}
              </Container>
            </>
          )}

 
          {/* Add New Service Modal */}
          <Modal show={isAddingService} onHide={handleBackToListClick}>
            <Modal.Header closeButton style={{ flexDirection: language === 'ar' ? 'row-reverse': '', justifyContent: 'space-between' }}>
              <Modal.Title className={`serviceFormheader `}>
                {t("Create New Service")} <br />{" "}
                <span className="serviceFormheader2">
                  {t("Describe your service details")}
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className={`d-flex ${rowReverse}`}>
              <Form>
                <Form.Group controlId="serviceCategory">
                  <Form.Label className="serviceformtewsxt">
                    {t("Service Category")}
                  </Form.Label>
                  <Select
                    options={formattedServiceCategories}
                    value={formData.serviceCategory}
                    onChange={handleServiceCategoryChange}
                  />
                </Form.Group>

                <Form.Group controlId="subcategory">
                  <Form.Label className={`serviceformtewsxt ${flex_rev_class}`}>
                    {t("Subcategory")}
                  </Form.Label>
                  <Row>
                    <Col>
                      <CreatableSelect
                        options={formattedServiceSubCategories}
                        onChange={(newValue) =>
                          handleNewSubcategoryChange(newValue)
                        }
                        value={newSubcategoryName}
                        placeholder={t("Select or Add New Subcategory")}
                        onCreateOption={handleAddNewSubcategory}
                      />
                    </Col>
                  </Row>
                  {/* Display the input field only when "Create New Subcategory" is selected */}
                  {formData?.subcategory &&
                    formData?.subcategory?.value === "new" && (
                      <Form.Control
                        type="text"
                        placeholder={t("Enter new subcategory")}
                        value={formData.newSubcategory}
                        onChange={handleInputChange}
                      />
                    )}
                </Form.Group>

                <div className={`mt-2 ${flex_rev_class}`}>
                  <Col>
                    <Form.Group controlId="garageList">
                      <CreatableSelect
                        isClearable
                        isMulti
                        onChange={(e) => handleSpecificationChange(e)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Button onClick={() => handleAddSpecificationGroup()} disabled={!isSpecificationAdded}>
                      +
                    </Button>
                  </Col>
                </div>

                <Form.Group controlId="specifications">
                  {Object.entries(formData.specifications).map(
                    ([key, specs]) => (
                      <div key={key}>
                        {specs.map((specification, index) => (
                          <div key={index} className={`mt-2 ${flex_rev_class}`}>
                            <Col>
                              <Form.Control
                                type="text"
                                placeholder="---"
                                value={specification.name}
                                onChange={(e) =>
                                  handleSpecificationNameChange(
                                    index,
                                    key,
                                    e.target.value
                                  )
                                }
                              />
                            </Col>
                            <Col>
                              <Form.Control
                                type="text"
                                placeholder=" ---"
                                value={specification.value}
                                onChange={(e) =>
                                  handleSpecificationValueChange(
                                    index,
                                    key,
                                    e.target.value
                                  )
                                }
                              />
                            </Col>
                          </div>
                        ))}
                      </div>
                    )
                  )}
                </Form.Group>

                <Form.Group controlId="garageList">
                  <Form.Label className={`serviceformtewsxt ${flex_rev_class}`}>
                    {t("Garage List")}
                  </Form.Label>
                  <Select
                    isMulti
                    options={formattedGarageList}
                    value={formData.garageList}
                    onChange={handleGarageListChange}
                  />
                </Form.Group>

                <Form.Group controlId="duration">
                  <Form.Label className={`serviceformtewsxt ${flex_rev_class}`}>
                    {t("Duration (hh/mm)")}
                  </Form.Label>
                  <Form.Control
                    className="serviceFormControl"
                    type="text"
                    placeholder={t("Enter duration")}
                    value={formData.duration}
                    onChange={(e) =>
                      handleInputChange("duration", e.target.value)
                    }
                  />
                </Form.Group>
                <Form.Group controlId="price">
                  <Form.Label className={`serviceformtewsxt ${flex_rev_class}`}>
                    {t("Price")} 
                  </Form.Label>
                  <Form.Control
                    className="serviceFormControl"
                    type="number"
                    placeholder={t("Enter Price")}
                    value={formData.price}
                    onChange={(e) =>
                      handleInputChange("price", e.target.value)
                    }
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleBackToListClick}>
                {t("Close")}
              </Button>
              <Button
                variant="primary"
                onClick={() => handleServiceCreate(formData)}
                style={{
                  display: "flex",
                  width: "271px",
                  height: "40px",
                  padding: "20px 43px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  flexShrink: 0,
                  borderRadius: "999px",
                  borderColor: "orange",
                  background:
                    "linear-gradient(97deg, #F15822 0%, #F7941D 100%)",
                }}
              >
                {t("Save Changes")}
              </Button>
            </Modal.Footer>
          </Modal>
        </Col>

        <Col xs={4} style={{ border: "1px solid #C7D5E1" }}>
          <div
            style={{
              height: "60vh", // Adjust the height based on your needs
              overflowY: "auto",
              padding: "20px",
            }}
          >
            <p
              style={{
                fontWeight: 600,
                fontSize: "18px",
                marginBottom: "10px",
              }}
              className={flex_rev_class}
            >
              {t("Current Orders")}
            </p>
            {selectedProducts?.map((selectedProduct) => (
              <div
                key={selectedProduct.id}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                  padding: "5px",
                  borderRadius: "10px",
                  border: "1px solid #C7D5E1",
                  background: "#FFF",
                }}
                className={`${rowReverse}`}
              >
                <div style={{ width: "60%" }}>
                  <p
                    style={{
                      color: "#000",
                      fontFamily: "Source Sans Pro",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      marginBottom: "5px",
                    }}
                    className={flex_rev_class}
                  >
                    {t(selectedProduct.title)}
                  </p>
                  <div style={{ display: "flex", alignItems: "center" }} className={flex_rev_class}>
                    <Button
                      variant="outline-primary"
                      size="sm"
                      style={{ marginRight: "10px" }}
                      onClick={() => handleRemoveFromCart(selectedProduct)}
                    >
                      -
                    </Button>
                    <p
                      style={{
                        color: "#000",
                        fontFamily: "Source Sans Pro",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                        margin: "0",
                      }}
                    >
                      {getProductCount(selectedProduct)}
                    </p>
                    <Button
                      variant="outline-primary"
                      size="sm"
                      style={{ marginLeft: "10px" }}
                      onClick={() => handleAddToCart(selectedProduct)}
                    >
                      +
                    </Button>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                    className={flex_rev_class}
                  >
                    <p
                      style={{
                        color: "#000",
                        fontFamily: "Source Sans Pro",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                        margin: "0",
                      }}
                      className={flex_rev_class}
                    >
                      {t("Price:")}
                    </p>
                    <p
                      style={{
                        color: "#F15822",
                        fontFamily: "Source Sans Pro",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "normal",
                        margin: "0",
                      }}
                    >
                      ${selectedProduct.price}
                    </p>
                  </div>
                </div>
                {/* <img
    src={engineImage}
    alt="Product Image"
    style={{ width: "40px", height: "40px", borderRadius: "50%" }}
  /> */}
              </div>
            ))}
          </div>
          {/* Sub-total, discount, sales tax, total */}
          <div style={{ padding: "20px" }}>
            <p style={{ fontSize: "16px", marginBottom: "10px" }} className={flex_rev_class}>
              {t("Sub-total:")} ${subTotal.toFixed(2)}
            </p>
            <p style={{ fontSize: "16px", marginBottom: "10px" }} className={flex_rev_class}>
              {t("Discount:")} ${discount.toFixed(2)}
            </p>
            <p style={{ fontSize: "16px", marginBottom: "10px" }} className={flex_rev_class}>
              {t("Sales Tax:")} ${salesTax.toFixed(2)}
            </p>
            <hr style={{ borderTop: "1px solid #C7D5E1", margin: "10px 0" }} />
            <p
              style={{
                fontSize: "18px",
                fontWeight: 600,
                marginBottom: "10px",
              }}
              className={flex_rev_class}
            >
              {t("Total:")} ${total.toFixed(2)}
            </p>
            <Button
              style={{
                width: "100%",
                height: "40px",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                borderRadius: "999px",
                borderColor: "orange",
                background: "linear-gradient(97deg, #F15822 0%, #F7941D 100%)",
              }}
              onClick={handlePrintReceipt}
            >
              {t("Print Receipt")}
            </Button>
          </div>
        </Col>
      </div>

      <Receipt
        show={showReceipt}
        handleClose={handleCloseReceipt}
        selectedProducts={selectedProducts}
        subTotal={subTotal}
        discount={discount}
        salesTax={salesTax}
        total={total}
        selectedCustomer={selectedCustomer}
      />

      <CustomerSelectionModal
        customerList={customerList}
        onCreateCustomer={handleCreateCustomer}
        onCreateVehicle={handleCreateVehicle}
        showModal={showCustomerModal}
        setSelectedVehicle={setSelectedVehicle}
        setShowModal={setShowCustomerModal}
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
        brands={brands}
        selectedService={selectedProducts}
        flow="serv"
      />
      </>)}
      {/* Service Categories */}
    
    </Layout>
  );
};

export default Services;
