import { Button, Col, Row } from "react-bootstrap";
import { Layout } from "../components";
import { useContext, useState } from "react";
import searchIcon from '../assets/searchIcon.png'
import { useTranslation } from "react-i18next";
import { UserContext } from "../Context/UserContext";

const HelpDesk = () => {
  const helpDeskItems = [
    "Authentication",
    "Garages",
    "Products",
    "Customers",
    "Payments",
  ];

  // State to keep track of the selected help desk item
  const [selectedItem, setSelectedItem] = useState("Authentication");
  const { language } = useContext(UserContext);
  const rowReverse = language === 'ar' ? "flex-row-reverse" : "";
  const { t } = useTranslation();

  return (
    <Layout>
      <Row
        className="justify-content-center align-items-center"
        style={{
          background: "linear-gradient(93deg, #F15822 0%, #F7941D 100%)",
          height: "240px",
        }}
      >
        <Col md={6}>
          <div
            className="d-flex flex-column align-items-center"
            style={{ position: "relative", color: "#fff" }}
          >
            <p>{t("How Can We  Help You ?")}</p>
            <div
              style={{
                display: "flex",
                position: "relative",
                alignItems: "center",
              }}
            >
              <input
                type="text"
              //   placeholder="Ask Question..."
                style={{
                  width: "558px",
                  height: "66px",
                  padding: "17px 20px",
                  flexShrink: 0,
                  borderRadius: "999px",
                  background: "#FFF",
                  position: "relative",
                  paddingRight: "50px", 
                }}
              />
              {/* Left Search Icon */}
              <img
                src={searchIcon}
                alt="Search Image"
                style={{
                  position: "absolute",
                  left: "10px", 
                  height: "30px",
                  width: "30px",
                }}
              />
              {/* Right Search Icon */}
              <Button
                variant="primary"
                style={{
                  position: "absolute",
                  right: "10px", 
                  padding: "13px 53px",
                  backgroundColor: "#F15822",
                  borderRadius: "999px",

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                {t("Search")}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <h4 className={`d-flex ${rowReverse}`}>{t("Frequently Asked Questions (FAQs)")}</h4>
      <div className={`m-2 d-flex ${rowReverse}`}  style={{border:"1px solid #D3DAE7", borderRadius:"15px 0px 0px 15px"}} >
        {/* Help Desk Items List */}
        <Col md={3}   >
          {helpDeskItems?.map((helpDesk, index) => (
            <h5
              key={index}
              style={{
                cursor: "pointer",
                padding: "10px",
                color: selectedItem === helpDesk ? "#F15822" : "#545B68",
                margin: "5px",
                borderRadius: "5px",
              }}
              className={`d-flex ${rowReverse}`}
              onClick={() => setSelectedItem(helpDesk)}
            >
              {t(helpDesk)}
            </h5>
          ))}
        </Col>

        {/* Render content based on the selected help desk item */}
        <Col  style={{backgroundColor:"#fff"}}>
          <div>
            {/* You can render specific content based on the selected item */}
            {selectedItem === "Authentication" && (
              <p className={`d-flex ${rowReverse}`}>{t("Content related to Authentication")}</p>
            )}
            {selectedItem === "Garages" && <p className={`d-flex ${rowReverse}`}>{t("Content related to Garages")}</p>}
            {selectedItem === "Products" && <p className={`d-flex ${rowReverse}`}>{t("Content related to Products")}</p>}
            {selectedItem === "Customers" && (
              <p className={`d-flex ${rowReverse}`}>{t("Content related to Customers")}</p>
            )}
            {selectedItem === "Payments" && <p className={`d-flex ${rowReverse}`}>{t("Content related to Payments")}</p>}
          </div>
        </Col>
      </div>
    </Layout>
  );
};

export default HelpDesk;
