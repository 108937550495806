import { filterServices, getGarageServicesSchedule } from '../actionTypes';

const ServiceScheduleReducer = (state = [], action) => {
  switch (action.type) {
    case getGarageServicesSchedule:
      console.log(action.payload,'THE ACTION PAYLLOASD')
      return action.payload;
    default:
      return state;
  }
};

export default ServiceScheduleReducer;
