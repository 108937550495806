import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../Context/UserContext";

const GarageOptionsBox = ({
  handleAssignUserClick,
  handleEditClick,
  handleDeleteClick,
}) => {
  const { language } = useContext(UserContext);
  const rowReverse = language === 'ar' ? "flex-row-reverse" : "";
  const { t } = useTranslation();

  return (
    <div
      style={{
        position: "absolute",
        // top: "50%",
        // right: "10px",
        // transform: "translateY(-50%)",
        backgroundColor: "#fff",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        padding: "10px",
        // zIndex: 999,
      }}
    >
      <Button
        variant="link"
        onClick={handleAssignUserClick}
        style={{ marginBottom: "8px", display: "block" }}
      >
        {t("Assign User")}
      </Button>
      <Button
        variant="link"
        onClick={handleEditClick}
        style={{ marginBottom: "8px", display: "block" }}
      >
        {t("Edit")}
      </Button>
      <Button variant="link" onClick={handleDeleteClick} style={{ display: "block" }}>
        {t("Delete")}
      </Button>
    </div>
  );
};

export default GarageOptionsBox;
