// Login.js

import React, { useContext } from "react";
import { Col, Container, Row, Form, Button, Image } from "react-bootstrap";
import "./login.css"; // Add a CSS file for custom styling
import logo from "../../assets/Logo.png";
import { Link, useNavigate  } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../Context/UserContext";

const ForgetPassword = () => {
  const navigate = useNavigate(); 
  const { language, setLanguage } = useContext(UserContext);
  const rowReverse = language === 'ar' ? "flex-row-reverse" : "";
  const { t } = useTranslation();

  const handleSubmit =(event)=>{
    event.preventDefault();
    navigate("/otp");
    }
  return (
    <Container fluid style={{ height: "100vh" }}>
      <div className={`d-flex ${rowReverse}`} style={{ height: "100%" }}>
        <Col
          md={4}
          className="login-form d-flex"
          style={{
            flexDirection: "column",
            justifyContent: "center",
            padding:'10px 30px 0 30px '
          }}
        >
          <div className={`logo-container d-flex ${rowReverse}`}>
            <Image
              src={logo}
              alt="Logo"
              fluid
              className="logo"
              height={90}
              width={90}
            />
          </div>

          <div
          className="d-flex"
            style={{
              flexGrow: 1,
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div className="text-header" >

            <p className={`d-flex ${rowReverse}`}>{t("Forget Password?")}</p>
            <p className={`d-flex ${rowReverse}`}>{t("Please enter your email to reset your password")}</p>
            </div>

            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicEmail" className="mb-3">
                <Form.Label className={`text-left formLabel d-flex ${rowReverse}`} >{t("Email")}</Form.Label>
                <Form.Control
                  type="email"
                  placeholder={t("Please enter your email address")}
                  className="formControl"
                />
              </Form.Group>

              <Button variant="primary" type="submit" className="signin-btn">
                {t("Submit")}
              </Button>

              <p className="signup-text">
                <Link to="/" style={{color:"#F7941D"}}>{t("Back")}</Link>
              </p>
            </Form>
          </div>
        </Col>

        <Col md={8} className="background-image" style={{ height: "100%" }}>
          {/* Add image to the background */}
          <div className="text">
            <h4 className={`d-flex ${rowReverse}`}>{t("Features of VAR Mobile Application")}</h4>
            <p className={`d-flex ${rowReverse}`}>
              {t(`Will enable users to get all required services with only one click
              away. This convenience will eliminate the hustle and emergency
              situations which any car owner faces when there is an urgent need
              and as well as the routine care of their vehicle.`)}
            </p>
          </div>
        </Col>
      </div>
    </Container>
  );
};

export default ForgetPassword;
