import React, { useContext, useEffect, useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import "./Sidebar.css"; // Custom styles
import logo from "../../assets/Logo.png";
import { ReactComponent as DashboardIcon } from "../../assets/dashicon.svg";
import { ReactComponent as DocumentIcon } from "../../assets/documentIcon.svg";
import { ReactComponent as ProductIcon } from "../../assets/cartIcon.svg";
import { ReactComponent as GarageIcon } from "../../assets/garageIcon.svg";
import { ReactComponent as CustomerIcon } from "../../assets/customerIcon.svg";
import { ReactComponent as ReportIcon } from "../../assets/reportIcon.svg";
import { ReactComponent as TransactionIcon } from "../../assets/creditCardIcon.svg";
import { ReactComponent as SettingsIcon } from "../../assets/settingsIcon.svg";
import { ReactComponent as HelpDeskIcon } from "../../assets/helpicon.svg";
import { ReactComponent as LogoutIcon } from "../../assets/logoutIcon.svg";
import {ReactComponent as OrderIcon} from '../../assets/order.svg'
import { useDispatch } from "react-redux";
import { UserContext } from "../../Context/UserContext";
import { useTranslation } from "react-i18next";

const Sidebar = () => {
  const [expanded, setExpanded] = useState(true);
  const { language, } = useContext(UserContext);
  const rowReverse = language === 'ar' ? "flex-row-reverse": "";
  const { t } = useTranslation();

  const user = JSON.parse(localStorage.getItem('user'));
  

  const toggleSidebar = () => {
    setExpanded(expanded);
  };


  let links

  if(user.roles[0].name === 'owner'){
    links = [
      { path: "dashboard", label: "Dashboard", icon: <DashboardIcon /> },
      // { path: 'inventory', label: 'Inventory', icon: <DocumentIcon/> },
      // { path: "products", label: "Products", icon: <ProductIcon /> },
      // { path: "services", label: "Services", icon: <ProductIcon /> },
      { path: "garages", label: "Garages", icon: <GarageIcon /> },
      // { path: "customer", label: "Customer", icon: <CustomerIcon /> },
      // { path: 'helpdesk', label: 'Help Desk', icon: <HelpDeskIcon /> },
      // { path: 'reports', label: 'Reports', icon: <ReportIcon/> },
      // { path: 'transactions', label: 'Transactions', icon: <TransactionIcon/> },
      { path: 'users', label: 'Users',  icon: <CustomerIcon /> },
    ];
  }
  else if(user.roles[0].name === 'cashier'){
    links=[
      // { path: "order", label: "Order", icon: <OrderIcon /> },
      // { path: "customer", label: "Customer", icon: <CustomerIcon /> },
      { path: "services", label: "Services", icon: <ProductIcon /> },

      { path: "serviceSchedule", label: "Service Schedule", icon: <ProductIcon /> },

      { path: "booking", label: "Booking", icon: <OrderIcon /> },
    ]
  }
  else{
    links = [
      { path: "dashboard", label: "Dashboard", icon: <DashboardIcon /> },
      // { path: 'inventory', label: 'Inventory', icon: <DocumentIcon/> },
      // { path: "products", label: "Products", icon: <ProductIcon /> },
      { path: "services", label: "Services", icon: <ProductIcon /> },
      { path: "serviceSchedule", label: "Service Schedule", icon: <ProductIcon /> },
      // { path: "order", label: "Order", icon: <OrderIcon /> },
      { path: "garages", label: "Garages", icon: <GarageIcon /> },
      // { path: "customer", label: "Customer", icon: <CustomerIcon /> },
      { path: "booking", label: "Booking", icon: <OrderIcon /> },
      // { path: 'helpdesk', label: 'Help Desk', icon: <HelpDeskIcon /> },
      // { path: 'reports', label: 'Reports', icon: <ReportIcon/> },
      // { path: 'transactions', label: 'Transactions', icon: <TransactionIcon/> },
    ];
  }
 
  const isNavLinkActive = (path) => {
    return window.location.pathname === `/${path}`;
  };

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const handleButtonClick=()=>{
    // dispatch(logo)
    localStorage.removeItem('accessToken')
    localStorage.removeItem('sessionToken')
    localStorage.removeItem('userId')
    localStorage.removeItem('user')
    navigate('/')
  }

  return (
    <Navbar className={`sidebar ${expanded ? 'open' : 'closed'}`} expand="lg">
      <Navbar.Brand>
        <img src={logo} height={50} width={100} alt="Logo" />
      </Navbar.Brand>
      <Nav className="flex-column nav-main">
        {links.map((link, index) => (
          <NavLink
            key={index}
            to={`/${link.path}`}
            className={`links-main d-flex ${language === 'ar' ? "gap-3 flex-row-reverse": ""}`}
            activeClassName="active"
            onClick={toggleSidebar}
          >
            <div className="icon">
              {React.cloneElement(link.icon, {
                stroke: isNavLinkActive(link.path) ? '#F15822' : '#323232',
              })}
            </div>
            <p className="d-flex align-items-center mb-0">
              {t(link.label.charAt(0).toUpperCase() + link.label.slice(1))}
            </p>
          </NavLink>
        ))}

        <div className="line"></div>

        <NavLink
          to="/helpdesk"
          activeClassName="active"
          className={`links-main d-flex ${language === 'ar' ? "gap-3 flex-row-reverse": ""}`}
          onClick={toggleSidebar}
        >
          <div className="icon">
            {React.cloneElement(<HelpDeskIcon />, {
              stroke: isNavLinkActive('/helpdesk') ? '#F15822' : '#323232',
            })}
          </div>
          {t('Help Desk')}
        </NavLink>

        <div className={`links-main lgout d-flex ${language === 'ar' ? "gap-3 flex-row-reverse": ""}`} onClick={handleButtonClick}>
          <div className="icon">
            {React.cloneElement(<LogoutIcon />, {
              stroke: isNavLinkActive('/settings') ? '#F15822' : '#323232',
            })}
          </div>
          {t('Logout')}
        </div>
      </Nav>
    </Navbar>
  );
};

export default Sidebar;
