import { useState } from "react";
import { Button, Container } from "react-bootstrap";
import { ReactComponent as SingleProductSVG } from "../../assets/singleProduct.svg";
import { ReactComponent as MultipleProductSVG } from "../../assets/multipleProduct.svg";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation

const AddProduct = ({ handleBackToListClick }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [showSelectedComponent, setShowSelectedComponent] = useState(false);
    const navigate = useNavigate(); // Initialize useNavigate

    const handleOptionSelect = (option) => {
      setSelectedOption(option);
    };
  
    const handleContinue = () => {
      setShowSelectedComponent(true);
      // Navigate to the appropriate route based on selectedOption
      if (selectedOption === "single") {
        navigate("/singleProduct");
      } else if (selectedOption === "multiple") {
        navigate("/multipleProduct");
      }
    };
  
    return (
      <div style={{ textAlign: "center" }}>
        <div
          style={{
            display: "flex",
            width: "210px",
            height: "40px",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            flexShrink: 0,
            borderRadius: "999px",
            borderColor: "orange",
            // background: "linear-gradient(97deg, #F15822 0%, #F7941D 100%)",
            marginBottom: "20px",
          }}
        >
          {/* Back to List */}
        </div>
  
        <Container
          style={{
            border: "1px solid grey",
            backgroundColor: "#fff",
            padding: "20px",
            textAlign: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            padding:"90px",

            borderRadius:"15px"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              border: "1px solid grey",
              padding: "20px",
              margin: "20px",
              cursor: "pointer",
              position: "relative",
            }}
            onClick={() => handleOptionSelect("single")}
          >
            <SingleProductSVG style={{ width: "100px", height: "100px" }} />
            <span>Single Product</span>
            {selectedOption === "single" && (
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  backgroundColor: "#F15822",
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                }}
              />
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              border: "1px solid grey",
              padding: "20px",
              margin: "20px",
              cursor: "pointer",
              position: "relative",
            }}
            onClick={() => handleOptionSelect("multiple")}
          >
            <MultipleProductSVG style={{ width: "80px", height: "100px" }} />
            <span>Multiple Product</span>
            {selectedOption === "multiple" && (
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  backgroundColor: "#F15822",
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                }}
              />
            )}
          </div>
        </Container>
  
        {/* Render selected component conditionally */}
        {showSelectedComponent && (
          <>
            {/* No need to render components here */}
          </>
        )}
        
        {/* Cancel and Continue Buttons */}
        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
          <Button variant="secondary" onClick={handleBackToListClick}>Cancel</Button>
          {/* Continue button */}
          <Button variant="primary" onClick={handleContinue}
          style={{
            display: "flex",
            width: "110px",
            height: "40px",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            flexShrink: 0,
            borderRadius: "999px",
            borderColor: "orange",
            background: "linear-gradient(97deg, #F15822 0%, #F7941D 100%)",
          }}
          >
            Continue
          </Button>
        </div>
      </div>
    );
  };
  
  export default AddProduct;
