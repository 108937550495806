import axios from "axios";
import http from "../../utils/Api";
import { useNavigate } from "react-router";
import { getAllServiceCategory } from "../actionTypes";



const getServiceCategories =(data)=>async(dispatch)=>{
    try{


        const res = await http.get(`/api/serviceCategories`,http.generateConfig());

        if(res){
            dispatch({
                type:getAllServiceCategory,
                payload:res.result
            })
        }
    }
    catch(err){
        console.log(err)
    }
}


export {
    getServiceCategories
}