import { Layout } from "../components"

const Reports =()=>{
    return(
        <Layout>
            <h1>ReportsS</h1>
        </Layout>
    )
}


export default Reports