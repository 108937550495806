import React, { useContext, useEffect, useState } from "react";
import { Modal, Button, Nav, Tab, Table, Container } from "react-bootstrap";
import {
  getAllServices,
  getServiceSchedule,
  getServiceScheduleBYMODEL,
} from "../../service/action/serviceAction";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../Context/UserContext";

const ServiceSelectionModal = ({
  showModal,
  handleCloseModal,
  selectedCustomer,
  selectedServices,
  setSelectedServices,
  handleNewServiceAdd,
}) => {
  const [selectedTab, setSelectedTab] = useState("garageService");
  const { language } = useContext(UserContext);
  const rowReverse = language === 'ar' ? "flex-row-reverse" : "";
  const { t } = useTranslation();

  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
  };

  // const handleServiceSelect = (service) => {
  //   console.log(service, "THE SERVUCE");
  //   if (selectedTab === "garageService") {
  //     const newItem ={...service, status:"pending"}
  //     setSelectedServices([...selectedServices, newItem]);
  //   } else if (selectedTab === "serviceSchedule") {
  //     const newItem = { ...service, garageId: selectedGarage.id , status:"pending" };

  //     setSelectedServices([...selectedServices, newItem]);
  //     console.log(selectedServices, "THE SELECTED SERVICE");
  //   }
  // };

  const handleServiceSelect = (service) => {
    if (selectedTab === "garageService") {
      const isServiceSelected = selectedServices.some(
        (selectedService) => selectedService.id === service.id
      );
      if (isServiceSelected) {
        // Deselect the service by removing it from selectedServices
        const updatedServices = selectedServices.filter(
          (selectedService) => selectedService.id !== service.id
        );
        setSelectedServices(updatedServices);
      } else {
        // Select the service
        const newItem = { ...service, status: "pending" };
        setSelectedServices([...selectedServices, newItem]);
      }
    } else if (selectedTab === "serviceSchedule") {
      const isServiceSelected = selectedServices.some(
        (selectedService) => selectedService._id === service._id
      );
      if (isServiceSelected) {
        // Deselect the service by removing it from selectedServices
        const updatedServices = selectedServices.filter(
          (selectedService) => selectedService._id !== service._id
        );
        setSelectedServices(updatedServices);
      } else {
        // Select the service
        const newItem = { ...service, garageId: selectedGarage.id, status: "pending" };
        setSelectedServices([...selectedServices, newItem]);
      }
    }
  };
  
  
  const handleAddServices = () => {
    console.log(selectedServices,'THE SELECTED SERVICES')
    handleNewServiceAdd(selectedCustomer, selectedServices);
    setSelectedServices([]);
    // Close modal
    handleCloseModal();
  };
  const selectedGarage = useSelector((state) => state.selectedGarage);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllServices({ garageId: selectedGarage.id }));
    // getScheduleServuces()
  }, [selectedGarage]);

  useEffect(() => {
    if (selectedCustomer && selectedCustomer !== null) {
      setSelectedServices(selectedCustomer.services)

      const payload = {
        brandId: selectedCustomer.vehicle?.brand.id,
        vehicleModel: selectedCustomer?.vehicle?.vehicleModel,
      };

      dispatch(getServiceScheduleBYMODEL(payload));
    }
  }, [selectedCustomer]);

  const allServices = useSelector((state) => state.services);
  const serviceSchedules = useSelector((state) => state.serviceSchedule);
  console.log(allServices, "THE ALL SERVICS");
  return (
    <Modal show={showModal} onHide={handleCloseModal} size="lg">
      <Modal.Header closeButton>
      <Modal.Title className={`d-flex ${rowReverse}`}>{t("Select Services")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tab.Container
          defaultActiveKey="garageService"
          onSelect={handleTabSelect}
        >
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="garageService">{t("Garage Service")}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="serviceSchedule">{t("Service Schedule")}</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="garageService">
              {/* Table for Garage Service */}
              {allServices &&
                allServices.map((service, index) => {
                  const isServiceSelected = selectedServices.some(
                    (selectedService) => selectedService.id === service.id
                  );
              
                  return(
                  <div
                    key={service.id}
                    style={{
                      width: "348.768px",
                      padding: "15px",
                      backgroundColor: "#fff",
                      borderRadius: "13px",
                      border: "1px solid grey",
                      marginTop: "2px",
                      cursor:  "pointer",
                      marginBottom: index < allServices.length - 1 ? "15px" : 0,
                      opacity: isServiceSelected ? 0.5 : 1,
                      // textAlign:'center',
                      // justifyContent:"center",
                    }}
                    onClick={() => handleServiceSelect(service)}
                  >
                    <div className="d-flex justify-content-between align-items-center md-4">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {service.image && service.image.length > 0 ? (
                          <></>
                        ) : (
                          <></>
                        )}

                        <div>
                          <div style={{ fontSize: "16px", fontWeight: 600 }}>
                            {/* {service.title.toUpperCase()} */}
                          </div>

                          <div style={{ fontSize: "18px", fontWeight: 600 }}>
                            {service?.serviceCategory ? (
                              <>{service?.serviceCategory?.title}</>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div>
                            {service.specification ? (
                              <>
                                {Object.keys(service.specification).map(
                                  (specification) => {
                                    console.log(
                                      service.specification[specification],
                                      "JAS"
                                    );
                                    return (
                                      <>
                                        <div
                                          style={{
                                            fontSize: "16px",
                                            fontWeight: 600,
                                          }}
                                        >
                                          {specification}
                                        </div>
                                        {service.specification[
                                          specification
                                        ].map((items) => {
                                          return (
                                            <>
                                              <div
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: 600,
                                                }}
                                              >
                                                 {t(items.name)} - {t(items.value)}
                                              </div>
                                            </>
                                          );
                                        })}
                                      </>
                                    );
                                  }
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          {service?.subCategory ? (
                            <>
                              <div
                                style={{ fontSize: "14px", color: "#757575" }}
                              >
                                {service?.serviceCategory.title} /{" "}
                                {service?.serviceSubCategory.title}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          <div style={{ fontSize: "14px", color: "#757575" }}>
                            {service?.price}/ SAR
                          </div>
                          <div
                            style={{ fontSize: "14px", color: "#757575" }}
                          ></div>
                        </div>
                      </div>
                      <div>{/* <img src={dots} alt="dots" /> */}</div>
                    </div>

                    <div>
                      <span
                        style={{
                          color: "#585858",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineheight: "normal",
                        }}
                      >
                        {service?.description}
                      </span>
                    </div>
                  </div>
                )})}
            </Tab.Pane>
            <Tab.Pane eventKey="serviceSchedule">
              {/* Table for Service Schedule */}
              <div style={{ maxHeight: "600px", overflowY: "auto" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div>
                    {selectedCustomer ? (
                      <strong>{selectedCustomer?.vehicle?.brand.title}</strong>
                    ) : (
                      ""
                    )}
                    -
                    {selectedCustomer ? (
                      <strong>
                        {selectedCustomer?.vehicle?.vehicleModel.title}
                      </strong>
                    ) : (
                      ""
                    )}
                  </div>
                  {serviceSchedules && serviceSchedules.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                      }}
                    >
                      {serviceSchedules && serviceSchedules.length > 0 && (
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                          }}
                        >
                          {serviceSchedules.map((serviceSch, index) => {
                            const isServiceSelected = selectedServices.some(
                              (selectedService) =>
                                selectedService._id === serviceSch._id
                            );

                            return (
                              <Container
                                key={serviceSch._id}
                                fluid
                                style={{
                                  borderRadius: "15px",
                                  width: "320px",
                                  marginTop: "15px",
                                  padding: "10px",
                                  border: "1px solid grey",
                                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                  cursor: "pointer",
                                  opacity: isServiceSelected ? 0.5 : 1,
                                  marginBottom:
                                    index % 2 === 0 ? "20px" : "0px",
                                }}
                                onClick={() => {
                                    handleServiceSelect(serviceSch);
                                }}
                              >
                                <div style={{ marginBottom: "10px" }}>
                                  <strong>
                                  {serviceSch?.serviceItem?.title}
                                                                    </strong>
                                </div>
                                <div>
                                  <h6 style={{ margin: "0" }}>
                                  {t("Mileage:")} {serviceSch.mileageInterval} -
                                    {t("Action:")} {serviceSch.action}
                                  </h6>
                                </div>
                              </Container>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
        {t("Close")}
                </Button>
        <Button variant="primary" onClick={handleAddServices}>
          {t("Add Services")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ServiceSelectionModal;