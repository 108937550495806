import { selectGarage } from "../actionTypes";



const selectedGarageReducer =(state={}, action) =>{
    switch (action.type){
        case selectGarage:
            return action.payload
        default:
            return state

    }
};


export default selectedGarageReducer