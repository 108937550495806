import { useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { Layout } from "../components";
import engineImage from "../assets/engine.jpg";
import { useDispatch, useSelector } from "react-redux";
import {getProductCategories} from '../service/action/productCategory'
import {getProductByCategory,getProductsByUID} from '../service/action/productAction'
import Receipt from '../components/Receipt'
import http from "../utils/Api";
import CustomerSelectionModal from "../components/customerSelectionModal";
const Order = () => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [productUID, setProductUID] = useState("");
  const [debouncedProductUID, setDebouncedProductUID] = useState("");
  const [showReceipt, setShowReceipt] = useState(false);

  const selectedGarage = useSelector((state) => state.selectedGarage);
  const allProducts = useSelector((state)=> state.products)

  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const handleCreateCustomer = async (newCustomerData) => {
    const token = localStorage.getItem("accessToken");
    const res = await http.post(`/api/customer`, {...newCustomerData, garageId:selectedGarage.id}, http.generateConfig(token))
    setSelectedCustomer(res)
    setCustomerList([...customerList, newCustomerData]);
  };
  const handleAddToCart = (product) => {
    setSelectedProducts((prevSelectedProducts) => {
      // Check if the product already exists in the selected products array
      const existingProductIndex = prevSelectedProducts.findIndex(
        (p) => p.id === product.id
      );
  
      if (existingProductIndex !== -1) {
        // If the product already exists, update its count
        const updatedProducts = [...prevSelectedProducts];
        updatedProducts[existingProductIndex] = {
          ...updatedProducts[existingProductIndex],
          count: updatedProducts[existingProductIndex].count + 1,
        };
        return updatedProducts;
      } else {
        // If the product does not exist, add it with count 1
        return [...prevSelectedProducts, { ...product, count: 1 }];
      }
    });
  };
  


  const [customerList, setCustomerList] = useState([]);

  useEffect(() => {
    getGarageCustomers();
  }, [selectedGarage]);

  const getGarageCustomers = async () => {
    const token = localStorage.getItem("accessToken");
    const config = http.generateConfig(token);
    try {
      setLoading(true)
      const allCustomers = await http.get(`/api/customerGarage/${selectedGarage.id}`, config);
      setCustomerList(allCustomers);
      setLoading(false)
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false)
    } finally {
      setLoading(false); // Set loading to false regardless of success or failure
    }
  };

  // Placeholder values for sub-total, discount, sales tax, and total
 // Placeholder values for discount and sales tax
const discount = 0;
const salesTax = 0;

// Function to calculate subtotal
const calculateSubTotal = () => {
  return selectedProducts.reduce((total, product) => {
    // Multiply each product's price by its quantity and add it to the total
    return total + (product.price * product.count);
  }, 0);
};

const [selectedVehicle, setSelectedVehicle] = useState([]);
const [vehicleList, setVehicleList] = useState([]);
const [brands, setBrands] = useState([]);

useEffect(() => {
  fetchBrands();
}, []);

const fetchBrands = async () => {
  try {
    const token = localStorage.getItem("accessToken");

    const response = await http.get("api/brand", http.generateConfig(token));
    console.log(response, "THE RESPONSE HERE");
    if (response) {
      setBrands(response);
    }
  } catch (error) {
    console.error("Error fetching brands:", error);
  }
};


const handleCreateVehicle = async (newVehicleData) => {
  const token = localStorage.getItem("accessToken");
  const res = await http.post(
    `/api/vehiclePos`,
    { ...newVehicleData, customerId: selectedCustomer.id },
    http.generateConfig(token)
  );
  console.log(res, "THE RESPIONSE");
  setSelectedVehicle(res);
  setVehicleList([...vehicleList, newVehicleData]);
};
const subTotal = calculateSubTotal();
const total = subTotal - discount + salesTax;
  const [selectedCategory, setSelectedCategory] = useState(0);

  const serviceCategories = useSelector((state) => state.productCategory);


  const formattedServiceCategories = [
    { value: "All Products", label: "All Products", id: 0 }, // New option
    ...serviceCategories.map((category) => ({
      value: category.title,
      label: category.title,
      id: category.id,
    })),
  ];
  

  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(getProductCategories())
    dispatch(getProductByCategory({ id: 0, garageId: selectedGarage.id }));

  },[])
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedProductUID(productUID);
    }, 500); // Adjust the debounce time as needed (e.g., 500ms)

    return () => {
      clearTimeout(timer);
    };
  }, [productUID]);

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
    dispatch(getProductByCategory({ id: categoryId, garageId: selectedGarage.id }));
  };

  const handleSearch = () => {
    dispatch(getProductsByUID(debouncedProductUID));
  };


  const handleRemoveFromCart = (product) => {
    setSelectedProducts((prevSelectedProducts) => {
      const existingProductIndex = prevSelectedProducts.findIndex(
        (p) => p.id === product.id
      );
  
      if (existingProductIndex !== -1) {
        const updatedProducts = [...prevSelectedProducts];
        // If the count of the product is greater than 1, decrement the count
        if (updatedProducts[existingProductIndex].count > 1) {
          updatedProducts[existingProductIndex] = {
            ...updatedProducts[existingProductIndex],
            count: updatedProducts[existingProductIndex].count - 1,
          };
          return updatedProducts;
        } else {
          // If the count is 1, remove the product from the selected products array
          return prevSelectedProducts.filter((p) => p.id !== product.id);
        }
      } else {
        return prevSelectedProducts;
      }
    });
  };
  
  const getProductCount = (product) => {
    const selectedProduct = selectedProducts.find((p) => p.id === product.id);
    return selectedProduct ? selectedProduct.count : 0;
  };
  const handlePrintReceipt = () => {
    if(!!selectedCustomer){
      setShowReceipt(true);
    }
    else{
      setShowCustomerModal(true)
    }
  };

  const handleCloseReceipt = () => {
    setShowReceipt(false);
    setSelectedCustomer(null);
    setSelectedProducts([])
  };

  console.log(allProducts,"THE AL ASFAS")

  return (
    <Layout>
      <Row >
        <Col xs={8} style={{  overflowY: "auto" }}>
          <Row className="align-items-center mt-2">
            <Col md={6}>
              <p
                style={{
                  fontSize: "24px",
                  fontWeight: 600,
                }}
              >
                VAR Products
              </p>
            </Col>
            <Col md={6} className="d-flex justify-content-end">
              <input
                type="text"
                placeholder="Enter Product UID"
                value={productUID}
                onChange={(e) => setProductUID(e.target.value)}
              />
              <Button onClick={handleSearch}>Search</Button>
            </Col>
          </Row>
          <Container
        style={{
          display: "flex",
          overflowX: "auto",
        }}
      >
        {formattedServiceCategories.map((category, index) => (
          <div
            key={index}
            onClick={() => handleCategoryClick(category?.id)}
            style={{
              flex: "0 0 auto",
              padding: "14px 35px",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              backgroundColor:
                selectedCategory === category?.id ? "#F15822" : "#FFFFFF",
              color: selectedCategory === category?.id ? "#FFFFFF" : "#545B68",
              border:
                selectedCategory === category?.id
                  ? "none"
                  : "1px solid #BFD4E0",
              margin: "10px",
              cursor: "pointer",
            }}
          >
            {category?.value}
          </div>
        ))}
      </Container>

          {/* Scrollable container for selected products */}
          <Container
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >

          {allProducts.length > 0 ? <>
            {allProducts.map((product) => (
              <div
                key={product.id}
                style={{
                  borderRadius: "10px",
                  border: "1px solid #C7D5E1",
                  background: "#FFF",
                  width: "209px",
                  height: "242px",
                  flexShrink: 0,
                  margin: "10px",
                  padding: "10px",
                
                }}
                onClick={() => handleAddToCart(product)}
              >
                  {product.images && product.images.length > 0 ? (
                <img src={`https://varposbuckets.s3.amazonaws.com/productImage/${product.images[0]}`} alt="Product Image" width={"120px"}  style={{ marginBottom: "10px" }} />


                  ) :(
                <img src={engineImage} alt="Product Image" style={{ width: "100%", marginBottom: "10px" }} />


                  )}
                {/* `${BASE_URL}/download/${fileName}` */}
                <p
                  style={{
                    width: "195px",
                    color: "#000",
                    fontFamily: "Source Sans Pro",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                >
                  {product.title}
                </p>
                <p
                  style={{
                    color: "#F15822",
                    fontFamily: "Source Sans Pro",
                    fontSize: "24px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                  }}
                >
                  {product.price} / SAR
                </p>



              </div>
            ))}
          </> : <>
          <div >
            <h3>No Product Found</h3>
          {/* 
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner> */}
          </div>
          </>}
           
          </Container>
        </Col>
        <Col xs={4} style={{ border: "1px solid #C7D5E1" }}>
          <div
            style={{
              height: "60vh", // Adjust the height based on your needs
              overflowY: "auto",
              padding: "20px",
            }}
          >
            <p style={{ fontWeight: 600, fontSize: "18px", marginBottom: "10px" }}>Current Orders</p>
            {selectedProducts.map((selectedProduct) => (
              <div
              key={selectedProduct.id}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
                padding: "10px",
                borderRadius: "10px",
                border: "1px solid #C7D5E1",
                background: "#FFF",
              }}
            >
              <div style={{ width: "60%" }}>
                <p
                  style={{
                    color: "#000",
                    fontFamily: "Source Sans Pro",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    marginBottom: "5px",
                  }}
                >
                  {selectedProduct.title}
                </p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    variant="outline-primary"
                    size="sm"
                    style={{ marginRight: "10px" }}
                    onClick={() => handleRemoveFromCart(selectedProduct)}
                  >
                    -
                  </Button>
                  <p
                    style={{
                      color: "#000",
                      fontFamily: "Source Sans Pro",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      margin: "0",
                    }}
                  >
                  {getProductCount(selectedProduct)}
                  </p>
                  <Button
                    variant="outline-primary"
                    size="sm"
                    style={{ marginLeft: "10px" }}
                    onClick={() => handleAddToCart(selectedProduct)}
                  >
                    +
                  </Button>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <p
                    style={{
                      color: "#000",
                      fontFamily: "Source Sans Pro",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      margin: "0",
                    }}
                  >
                    Price:
                  </p>
                  <p
                    style={{
                      color: "#F15822",
                      fontFamily: "Source Sans Pro",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "normal",
                      margin: "0",
                    }}
                  >
                    ${selectedProduct.price}
                  </p>
                </div>
              </div>
              <img
                src={engineImage}
                alt="Product Image"
                style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              />
            </div>
            
            ))}
          </div>
          {/* Sub-total, discount, sales tax, total */}
          <div style={{ padding: "20px" }}>
            <p style={{ fontSize: "16px", marginBottom: "10px" }}>Sub-total: ${subTotal.toFixed(2)}</p>
            <p style={{ fontSize: "16px", marginBottom: "10px" }}>Discount: ${discount.toFixed(2)}</p>
            <p style={{ fontSize: "16px", marginBottom: "10px" }}>Sales Tax: ${salesTax.toFixed(2)}</p>
            <hr style={{ borderTop: "1px solid #C7D5E1", margin: "10px 0" }} />
            <p style={{ fontSize: "18px", fontWeight: 600, marginBottom: "10px" }}>Total: ${total.toFixed(2)}</p>
            <Button
              style={{
                width: "100%",
                height: "40px",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                borderRadius: "999px",
                borderColor: "orange",
                background: "linear-gradient(97deg, #F15822 0%, #F7941D 100%)",
              }}
              onClick={handlePrintReceipt}
            >
              Print Receipt
            </Button>
          </div>
        </Col>
      </Row>
      
      <Receipt
        show={showReceipt}
        handleClose={handleCloseReceipt}
        selectedProducts={selectedProducts}
        subTotal={subTotal}
        discount={discount}
        salesTax={salesTax}
        total={total}
        selectedCustomer={selectedCustomer}
      />  
      
      <CustomerSelectionModal
 customerList={customerList}
 onCreateCustomer={handleCreateCustomer}
 onCreateVehicle={handleCreateVehicle}
 showModal={showCustomerModal}
 setSelectedVehicle={setSelectedVehicle}
 setShowModal={setShowCustomerModal}
 selectedCustomer={selectedCustomer}
 setSelectedCustomer={setSelectedCustomer}
 brands={brands}
 selectedService={selectedProducts}
 flow="serv"
      />
      
        </Layout>
  );
};

export default Order;
