// import React, { useEffect, useState } from 'react';
// import { Navigate } from 'react-router-dom';

// // Higher-Order Component (HOC) to check user activity
// const OwnerInformation = (WrappedComponent) => {
//   const [isApproved, setIsApproved] = useState(null);

//   useEffect(() => {
//     const delayFetchUser = setTimeout(() => {
//       try {
//         const userString = localStorage.getItem("user");
//         console.log(userString, 'AKJSD');

//         // Check if user information is present
//         if (userString) {
//           const user = JSON.parse(userString);
//           const approvalStatus = user && user.isApproved;
//           console.log(approvalStatus, "ASKJ");
//           setIsApproved(approvalStatus);
//         } else {
//           setIsApproved(null); // User information not present
//         }
//       } catch (error) {
//         console.error('Error fetching user approval status:', error);
//         setIsApproved(false); // Set to false in case of an error
//       }
//     }, 1000); // 1000 milliseconds (1 second) delay

//     return () => clearTimeout(delayFetchUser); // Clear the timeout if the component unmounts
//   }, []); // Run once when the component mounts

//   return (props) => {
//     // Check isApproved state and render accordingly
//     if (isApproved === null) {
//       // Loading state (you may show a loading spinner)
//       return <div>Loading...</div>;
//     } else if (isApproved) {
//       // User is approved, render the wrapped component
//       return <WrappedComponent {...props} />;
//     } else {
//       // User is not approved, you can redirect or show a different component
//       return <Navigate to="/information" />;
//     }
//   };
// };

// export default OwnerInformation;


import React from 'react';
import { Navigate } from 'react-router-dom';

const OwnerInformation = ({ children }) => {

    const user = JSON.parse(localStorage.getItem('user'));
 
        if (!user.isApproved) { 
            return <Navigate to='/information' />
        }
    return children
};

export default OwnerInformation;