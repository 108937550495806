import React, { useState } from "react";
import Layout from "../layout";
import { Button, Container, ProgressBar } from "react-bootstrap";

const MultipleProduct = () => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});

  const handleFileUpload = (event) => {
    const files = event.target.files;
    // Update state with uploaded files
    setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
    // Upload each file
    Array.from(files).forEach((file) => uploadFile(file));
  };

  const uploadFile = (file) => {
    const formData = new FormData();
    formData.append("file", file);

    // Simulate upload progress (you would replace this with actual upload code)
    const totalSize = file.size;
    let loaded = 0;

    const progressInterval = setInterval(() => {
      loaded += 1000; // Simulate progress increment (1MB per second)
      const progress = Math.round((loaded / totalSize) * 100);
      setUploadProgress((prevProgress) => ({
        ...prevProgress,
        [file.name]: progress,
      }));

      // Finish upload simulation when progress reaches 100%
      if (progress === 100) {
        clearInterval(progressInterval);
      }
    }, 1000);
  };

  const handleRemoveFile = (index) => {
    // Remove file from uploadedFiles array based on index
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  return (
    <div>
      <Layout>
        <Container style={{ backgroundColor: "#fff", padding: "100px" }}>
          <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
            <div style={{ flex: 1, maxWidth: "500px", border: "2px dashed #ccc", padding: "60px" , textAlign:"center"}}>
              <h6 >Select CSV to import </h6>
              <h6 style={{ marginBottom: "10px" }}>or drag & drop it here</h6>
              <input 
  id="file-upload" 
  type="file" 
  multiple 
  onChange={handleFileUpload} 
  style={{ display: "none" }} 
/>
<Button
  onClick={() => document.getElementById('file-upload').click()} 
  style={{
    display: "flex",
    width: "110px",
    height: "40px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    flexShrink: 0,
    borderRadius: "999px",
    borderColor: "orange",
    background: "linear-gradient(97deg, #F15822 0%, #F7941D 100%)",
  }}
>
  Browse files
</Button>
            </div>
            <div style={{ marginLeft: "20px", width:"100%" }}>
              {uploadedFiles.map((file, index) => (
                <div key={index} style={{ marginBottom: "10px", display: "flex", alignItems: "center" }}>
                  <div style={{ flex: "1" }}>
                    {file.name}
                    <ProgressBar
                      now={uploadProgress[file.name] || 0}
                      label={`${uploadProgress[file.name] || 0}%`}
                      style={{ marginTop: "5px"}}
                      bg="success" 
                    />
                  </div>
                  <Button variant="danger" onClick={() => handleRemoveFile(index)}>Cut</Button>
                </div>
              ))}
            </div>
          </div>
        </Container>
      </Layout>
    </div>
  );
};

export default MultipleProduct;
