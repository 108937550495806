import { selectGarage } from "../actionTypes"





const selectedGaragestate =(data)=> async(dispatch)=>{
    try{

        dispatch({
            type: selectGarage,
            payload:data
        })
    }
    catch(err){
        console.log(err)
    }
}

export {
    selectedGaragestate
}