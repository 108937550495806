import React, { useContext } from 'react';
import Chart from 'react-apexcharts';
import { UserContext } from '../../Context/UserContext';
import { useTranslation } from 'react-i18next';

const OrderChart = () => {
  const { language } = useContext(UserContext);
  const rowReverse = language === 'ar' ? "flex-row-reverse" : "";
  const { t } = useTranslation();
  // Generate dummy data for the chart
  const generateDummyData = () => {
    const data = [];
    for (let month = 1; month <= 12; month++) {
      data.push({
        x: new Date(2023, month - 1, 1).getTime(),
        yOrder: Math.floor(Math.random() * 100) + 50, // Random order data
        ySales: Math.floor(Math.random() * 1000) + 500, // Random sales data
      });
    }
    return data;
  };

  // Chart options
  const chartOptions = {
    chart: {
      id: 'order-chart',
    },
    xaxis: {
      type: 'datetime',
    },

  };

  // Chart series data
  const chartSeries = [
    {
      name: t('Order'),
      data: generateDummyData().map((entry) => ({ x: entry.x, y: entry.yOrder })),
      color: '#F7941D', 
    },
    {
      name: t('Sales'),
      data: generateDummyData().map((entry) => ({ x: entry.x, y: entry.ySales })),
      color: '#FF5714', 
    },
  ];

  return (
    <div className='p-3'>
      <span style={{ fontSize: "20px", fontWeight:600 }} className={`d-flex ${rowReverse}`}>{t("Order and Sales Analytics")}</span>
      <Chart options={chartOptions} series={chartSeries} type="line" height={330} />
    </div>
  );
};

export default OrderChart;



// AIzaSyACX9R5S-OC-97qHCWSNAzzHzO6lEK4QK8

// "react-leaflet": "^4.2.1",
