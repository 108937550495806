import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import MapModel from "../mapModal";
import { useDispatch } from "react-redux";
import { addGarage, editGarages } from "../../service/action/garageAction";
import Select from "react-select";
import http from "../../utils/Api";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../Context/UserContext";

const AddGarage = ({ isEditing, selectedGarage, onSubmit, onClose }) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    contactNumber: "",
    emailAddress: "",
    selectedPaymentMethod: [],
    location: {
      latitude:0,
      longitude:0
    },
    brands:[]
  });

  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const { language } = useContext(UserContext);
  const rowReverse = language === 'ar' ? "flex-row-reverse" : "";
  const { t } = useTranslation();

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  useEffect(() => {

    // Populate formData with selectedGarage data when in edit mode
    if (isEditing && selectedGarage) {
      setFormData({
        name: selectedGarage.name || "",
        address: selectedGarage.address || "",
        city: selectedGarage.city || "",
        state: selectedGarage.state || "",
        zipCode: selectedGarage.zipCode || "",
        contactNumber: selectedGarage.contactNumber || "",
        emailAddress: selectedGarage.emailAddress || "",
        selectedPaymentMethod: selectedGarage.selectedPaymentMethod || [],
        location: selectedGarage.location || null,
        image: selectedGarage.image || null,
        brands:[]
      });
    }
  }, [isEditing, selectedGarage]);


  const validateForm = () => {
    const newErrors = {};
    let isValid = true;
    // Check if any field is empty
    for (const key in formData) {
      if (!formData[key]) {
        newErrors[key] = "Field is required";
        isValid = false;
      }
    }
    setErrors(newErrors);
    return isValid;
  };
  const handleNext = () => {
    console.log(errors,"KJAS")
    if(validateForm()){

      setStep(step + 1);
    }
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleInputChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  const handleLocationSelect = (location) => {
    console.log(location,"THE LOCATION")
    let loc={
      type:'Point',
      coordinates:[
        location.lng.toString(),
        location.lat.toString()
      ]

    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      location:loc,
    }));
  };

  const handlePaymentMethodChange = (selectedOptions) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      selectedPaymentMethod: selectedOptions.map((option) => option.value),
    }));
  };

  const handleBrandsChange = (selectedOptions)=>{
    setFormData((prevFormData)=>({
      ...prevFormData,
      brands: selectedOptions.map((option)=> option.value)
    }))
  }

  const options = [
    { value: "Card", label: "Card" },
    { value: "Cash", label: "Cash" },
    { value: "GPay", label: "G Pay" },
    { value: "PayPal", label: "PayPal" },
  ];
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }

    // Create a new FormData object
    const formDataToSend = new FormData();

    // Append fields from formData
    for (const key in formData) {

      if(key ==='location'){
        formDataToSend.append(key, JSON.stringify(formData[key]))
      }else{
        formDataToSend.append(key, formData[key]);
      }
    }

    // Append the image file
    formDataToSend.append("image", selectedImage);

    try {
      // Dispatch the appropriate action based on the mode (Add or Edit)
      if (isEditing) {
        // Edit mode
        const updatedGarage = {
          ...selectedGarage,
          ...formData,
        };
        dispatch(editGarages(updatedGarage));
      } else {
        // Add mode
       const res = await dispatch(addGarage(formDataToSend));
       if(res.status === 400){
        alert("added data not correct uploded")
       }
       else{

         setStep(step - 1);
         setFormData({
          name: "",
          address: "",
          city: "",
          state: "",
          zipCode: "",
          contactNumber: "",
          emailAddress: "",
          selectedPaymentMethod: [],
          location: null,
          brands:[]
        });
      }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error
    }
  };

  const handleImageClick = () => {
    document.getElementById("fileInput").click();
  };

  useEffect(()=>{
    fetchBrands()
  },[])

  const [brands, setBrands]= useState([])

  const fetchBrands = async () => {
    try {

      const token = localStorage.getItem("accessToken");

     
      const response = await http.get("api/brand",http.generateConfig(token));
      console.log(response,'THE :LAS')
      if(response){
        setBrands(response);
      }
    } catch (error) {
      console.error("Error fetching brands:", error);
    }
  };


  const formattedBrandsList = brands.map((brand) => ({
    value: brand.title,
    label: brand.title,
    id: brand.id,
  }));
  return (
    <Container>
      <div className="step-titles">
        <h6 className={step === 1 ? "active-step" : "" + `d-flex ${rowReverse}`}>{t("1 Garage Details")}</h6>
        <div className="line2" />
        <h6 className={step === 2 ? "active-step" : "" + `d-flex ${rowReverse}`}>{t("2 Confirmation")}</h6>
      </div>
      <Row className="justify-content-center mt-5">
        <Col>
          {step === 1 && (
            <div
              className="form-container"
              style={{ border: "1px solid #D3DAE7", borderRadius: "14px" }}
            >
              <h5 className={`mb-4 m-2 d-flex ${rowReverse}`} style={{ backgroundColor: "#F3F7FF" }}>
                {t("Garage Information")}
              </h5>
              <div className="p-2" style={{ backgroundColor: "#fff" }}>
                <Form>
                  <Row className={`mb-2 ${rowReverse}`}>
                    <Col
                      md={12}
                      className={`d-flex justify-content-between align-items-center ${rowReverse}`}
                    >
                      <div
                        className="circular-container"
                        onClick={handleImageClick}
                      >
                        {selectedImage ? (
                          <img
                            src={URL.createObjectURL(selectedImage)}
                            alt="Selected Image"
                            className="selected-image"
                          />
                        ) : (
                          <label
                            htmlFor="fileInput"
                            className={`upload-button btn btn-outline-primary d-flex ${rowReverse}`}
                          >
                            {t("Upload")}
                          </label>
                        )}
                        <input
                          id="fileInput"
                          type="file"
                          accept="image/*"
                          onChange={handleImageChange}
                          style={{ display: "none" }}
                        />
                      </div>
                      <Button
                        variant="secondary"
                        style={{ border: "1px solid red" }}
                        onClick={() => setShowLocationModal(true)}
                      >
                        {t("Mark Location")}
                      </Button>
                    </Col>
                  </Row>
                  <Row className={`mb-2 ${rowReverse}`}>
                    <Col md={4}>
                      <Form.Group controlId="name">
                        <Form.Label className={`d-flex ${rowReverse}`}>{t("Garage Name")}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Garage Name"
                          className="custom-input-style"
                          value={formData.name}
                          onChange={(e) =>
                            handleInputChange("name", e.target.value)
                          }
                        />
                          {errors.name && (
                            <span style={{marginLeft:"20px", color:"red"}} >
                              {t(errors.name)}
                            </span>
                          )}
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="address">
                        <Form.Label className={`d-flex ${rowReverse}`}>{t("Address")}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Address"
                          className="custom-input-style"
                          value={formData.address}
                          onChange={(e) =>
                            handleInputChange("address", e.target.value)
                          }
                        />
                          {errors.address && (
                            <span style={{marginLeft:"20px", color:"red"}} >
                              {t(errors.address)}
                            </span>
                          )}
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="city">
                        <Form.Label className={`d-flex ${rowReverse}`}>{t("City")}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="City"
                          className="custom-input-style"
                          value={formData.city}
                          onChange={(e) =>
                            handleInputChange("city", e.target.value)
                          }
                        />
                          {errors.city && (
                            <span style={{marginLeft:"20px", color:"red"}} >
                              {t(errors.city)}
                            </span>
                          )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className={`mb-2 ${rowReverse}`}>
                    <Col md={4}>
                      <Form.Group controlId="state">
                        <Form.Label className={`d-flex ${rowReverse}`}>{t("State")}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="State"
                          className="custom-input-style"
                          value={formData.state}
                          onChange={(e) =>
                            handleInputChange("state", e.target.value)
                          }
                        />
                          {errors.state && (
                            <span style={{marginLeft:"20px", color:"red"}} >
                              {t(errors.state)}
                            </span>
                          )}
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="zipCode">
                        <Form.Label className={`d-flex ${rowReverse}`}>{t("Zip Code")}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Zip Code"
                          className="custom-input-style"
                          value={formData.zipCode}
                          onChange={(e) =>
                            handleInputChange("zipCode", e.target.value)
                          }
                        />
                          {errors.zipCode && (
                            <span style={{marginLeft:"20px", color:"red"}} >
                              {errors.zipCode}
                            </span>
                          )}
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="contactNumber">
                        <Form.Label className={`d-flex ${rowReverse}`}>{t("Contact Number")}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Contact Number"
                          className="custom-input-style"
                          value={formData.contactNumber}
                          onChange={(e) =>
                            handleInputChange("contactNumber", e.target.value)
                          }
                        />
                                                  {errors.contactNumber && (
                            <span style={{marginLeft:"20px", color:"red"}} >
                            {errors.contactNumber}
                            </span>
                         
                            )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className={`mb-2 ${rowReverse}`}>
                    <Col md={4}>
                      <Form.Group controlId="emailAddress">
                        <Form.Label className={`d-flex ${rowReverse}`}>{t("Email Address")}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Email Address"
                          className="custom-input-style"
                          value={formData.emailAddress}
                          onChange={(e) =>
                            handleInputChange("emailAddress", e.target.value)
                          }
                        />
                                                  {errors.emailAddress && (
                            <span style={{marginLeft:"20px", color:"red"}} >
                            {errors.emailAddress}
                            </span>
                         
                            )}
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="selectedPaymentMethods">
                        <label>{t("Select Payment Methods")}</label>
                        <Select
                          isMulti
                          options={options}
                          value={options.filter((option) =>
                            formData.selectedPaymentMethod.includes(
                              option.value
                            )
                          )}
                          onChange={handlePaymentMethodChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="brands">
                        <label>{t("Select Brands")}</label>
                        <Select
                          isMulti
                          options={formattedBrandsList}
                          value={formattedBrandsList.filter((option) =>
                            formData.brands.includes(
                              option.value
                            )
                          )}
                          onChange={handleBrandsChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className={`mt-4 d-flex ${rowReverse}`}>
                    <Button
                      variant="primary"
                      onClick={handleNext}
                      className="ml-auto"
                    >
                      {t("Continue")}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          )}

          {step === 2 && (
            <div
              className="form-container"
              style={{ border: "1px solid #D3DAE7", borderRadius: "14px" }}
            >
              <h5 className={`mb-4 m-2 d-flex ${rowReverse}`} style={{ backgroundColor: "#F3F7FF" }}>
                {t("Confirmation")}
              </h5>

              <div style={{ backgroundColor: "#fff" }}>
                <div className="p-2">
                  <Row className={`${rowReverse}`}>
                    <Col md={4}>
                      <Form.Group controlId="name">
                        <Form.Label className={`d-flex ${rowReverse}`}>{t("Garage Name")}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={formData.name}
                          readOnly
                          className="custom-input-style"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="address">
                        <Form.Label className={`d-flex ${rowReverse}`}>{t("Address")}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={formData.address}
                          readOnly
                          className="custom-input-style"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="city">
                        <Form.Label className={`d-flex ${rowReverse}`}>{t("City")}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={formData.city}
                          readOnly
                          className="custom-input-style"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="state">
                        <Form.Label className={`d-flex ${rowReverse}`}>{t("State")}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={formData.state}
                          readOnly
                          className="custom-input-style"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="zipCode">
                        <Form.Label className={`d-flex ${rowReverse}`}>{t("Zip Code")}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={formData.zipCode}
                          readOnly
                          className="custom-input-style"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="contactNumber">
                        <Form.Label className={`d-flex ${rowReverse}`}>{t("Contact Number")}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={formData.contactNumber}
                          readOnly
                          className="custom-input-style"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="emailAddress">
                        <Form.Label className={`d-flex ${rowReverse}`}>{t("Email Address")}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={formData.emailAddress}
                          readOnly
                          className="custom-input-style"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="selectedPaymentMethods">
                        <label>{t("Select Payment Methods")}</label>
                        <Select
                          isMulti
                          options={options}
                          value={options.filter((option) =>
                            formData.selectedPaymentMethod.includes(
                              option.value
                            )
                          )}
                          isDisabled
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="selectedPaymentMethods">
                        <label>{t("Select Brands")}</label>
                        <Select
                          isMulti
                          options={formattedBrandsList}
                          value={formattedBrandsList.filter((option) =>
                            formData.brands.includes(
                              option.value
                            )
                          )}
                          isDisabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
                <div className={`mt-4 d-flex ${rowReverse} gap-3 p-3`}>
                  <Button variant="secondary" onClick={handleBack}>
                    {t("Back")}
                  </Button>
                  <Button
                    variant="primary"
                    className="ml-auto"
                    onClick={handleSubmit}
                  >
                    {t("Submit")}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Col>
      </Row>
      <MapModel
        show={showLocationModal}
        handleClose={() => setShowLocationModal(false)}
        handleLocationSelect={handleLocationSelect}
        googleMapsApiKey="AIzaSyACX9R5S-OC-97qHCWSNAzzHzO6lEK4QK8"
      />
    </Container>
  );
};

export default AddGarage;
