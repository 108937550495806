import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Container,
  Table,
  Pagination,
  Spinner,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import { Layout } from "../components";
import userimage from "../assets/userImage.png";
import dots from "../assets/dots.png";
import http from "../utils/Api";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ServiceSelectionModal from "../components/serviceSelection";
import QrReader from "react-qr-scanner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { UserContext } from "../Context/UserContext";
import ServiceConfirmModal from "../components/serviceConfirmModal/Index";

const Customer = () => {
  const pageSize = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const selectedGarage = useSelector((state) => state.selectedGarage);
  const [customerList, setCustomerList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalSearchTerm, setModalSearchTerm] = useState("");
  const qrReaderRef = useRef(null);
  const [showQrScanner, setShowQrScanner] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [scanned, setScanned] = useState(false);
  const garageList = useSelector((state) => state.garages);
  const { language } = useContext(UserContext);
  const rowReverse = language === 'ar' ? "flex-row-reverse" : "";
  const { t } = useTranslation();
  const [showServiceConfirmModal, setShowServiceConfirmModal] = useState(false);
  const [servToBeComp, setServToBeComp] = useState(null);

  // useEffect(() => {
  //   getGarageCustomers();
  // }, [selectedGarage, updating]);

  const getGarageCustomers = async () => {
    const token = localStorage.getItem("accessToken");
    const config = http.generateConfig(token);
    try {
      setLoading(true);
      const allCustomers = await http.get(
        `/api/customerbygarage/${selectedGarage.id}`,
        config
      );
      setCustomerList(allCustomers);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false regardless of success or failure
    }
  };

  const indexOfLastCustomer = currentPage * pageSize;
  const indexOfFirstCustomer = indexOfLastCustomer - pageSize;
  const currentCustomers = customerList.slice(
    indexOfFirstCustomer,
    indexOfLastCustomer
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSearch = async () => {
    // e.preventDefault();
    setLoading(true);
    try {
      const token = localStorage.getItem("accessToken");
      const config = http.generateConfig(token);
      const exBookings = await http.get(
        `api/search-service-booking/${searchTerm}`,
        config
      );
      if (exBookings && exBookings.success) {
        if (Array.isArray(exBookings.result)) {
          setCustomerList(exBookings.result);
        } else {
          setCustomerList([exBookings.result]);
        }
      }
    } catch (error) {
      console.error("Error searching service bookings:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchQR = async (term) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("accessToken");
      const config = http.generateConfig(token);
      const exBookings = await http.get(
        `api/search-service-booking/${term}`,
        config
      );
      if (exBookings && exBookings.success) {
        if (Array.isArray(exBookings.result)) {
          setCustomerList(exBookings.result);
        } else {
          setCustomerList([exBookings.result]);
        }
      }
    } catch (error) {
      console.error("Error searching service bookings:", error);
    } finally {
      setLoading(false);
    }
  };

  // Inside the QR scanner component
  const handleScan = (data) => {
    try {
      if (data && !scanned) {
        setSearchTerm(data.text);
        // setScanned(true);
        setShowQrScanner(false);
        handleSearchQR(data.text);

        if (qrReaderRef.current) {
          qrReaderRef.current.closeImageDialog(); // Close the camera after scanning
        } else {
          console.error("qrReaderRef is null");
        }
      }
    } catch (error) {
      console.error("Error scanning QR code:", error);
    }
  };

  const handleError = (error) => {
    console.error("QR Code scanning error:", error);
  };

  const triggerScan = () => {
    try {
      console.log("Triggering scan...");
      setShowQrScanner(true);
      if (qrReaderRef.current) {
        qrReaderRef.current.openImageDialog();
      } else {
        console.error("qrReaderRef is null");
      }
    } catch (error) {
      console.error("Error triggering scan:", error);
    }
  };

  const handleViewBookings = (customer) => {
    setSelectedCustomer(customer);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalSearchTerm(""); // Reset modal search term on modal close
  };

  const handleModalSearch = () => {
    // Filter bookings by booking UID
    const filteredBookings = selectedCustomer.services.filter((booking) =>
      booking.bookingUID.includes(modalSearchTerm)
    );
    setSelectedCustomer((prevState) => ({
      ...prevState,
      serviceBookings: filteredBookings,
    }));
  };
  const updateServiceBooking = async (id, status) => {
    const token = localStorage.getItem("accessToken");
    const config = http.generateConfig(token);
    try {
      const res = await http.put(`/api/service-booking/${id}`, { status: status }, config);
      const newCus = customerList?.map(cus => {
        if(cus.id === id) {
          return { ...cus, status: status };
        }
        return cus;
      });
      setCustomerList(newCus);
      setShowModal(false);
      setUpdating(true);
    } catch (error) {
      console.log(error);
    }
  };

  const [selectedServices, setSelectedServices] = useState([]);
  const [showServiceModal, setShowServiceModal] = useState(false);

  const handleAddService = () => {
    setShowServiceModal(true); // Update showModal to true to open the modal
  };

  const addServicesupdateFunction = async (Booking, newServices) => {
    const token = localStorage.getItem("accessToken");
    const config = http.generateConfig(token);
    const payload = {
      BookingId: Booking.id,
      services: newServices,
      mongoBookingId: Booking.bookingUID,
    };
    try {
      const allCustomers = await http.post(
        `/api/booking/addServices`,
        payload,
        config
      );
      console.log(allCustomers, "THE ALL CUSTOMER");
      if (allCustomers && allCustomers.success) {
        setCustomerList([allCustomers.serviceBooking]);
      }
      setShowServiceModal(false);
      setShowModal(false);
      setUpdating(true);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateService = async (service, index, status) => {
    const token = localStorage.getItem("accessToken");
    const config = http.generateConfig(token);
    const updt = await http.post(
      `/api/booking/updateServiceinBooking`,
      {
        bookingUID: selectedCustomer?.bookingUID,
        service: service,
        index: index,
        status: status,
      },
      config
    );

    if (updt && updt.success) {
      setSelectedCustomer(updt.result);
    }
  };

  const [image, setImage] = useState(null);
  const fileInputRef = useRef(null);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0]; // Assuming single file upload

    try {
      // Create FormData object
      const formData = new FormData();
      formData.append('image', file);
      const token = localStorage.getItem("accessToken");
      const config = http.generateConfig(token);
      config.headers['Content-Type'] = 'multipart/form-data';

      // Send POST request to your API endpoint
      const response = await http.post(`api/booking/uploadInvice/${selectedCustomer.bookingUID}`, formData,
      config
    );

      console.log(response,"THE RESPONSE213235wa@")
      if(!!response.success){
        alert("Invoice Added Successfully")
      }
      // If successful, set image URL to state
      setImage(response.data);
    } catch (error) {
      console.error('Error uploading image:', error);
      // Handle error, display message to user, etc.
    }
  };

  const handleUploadClick = () => {
    // Trigger click event on file input element to open file dialog
    fileInputRef.current.click();
  };

  const flex_rev_class = `d-flex ${rowReverse}`;

  return (
    <>
      <ServiceConfirmModal showModal={showServiceConfirmModal} handleClose={()=> { setServToBeComp(null); setShowServiceConfirmModal(false); }} handleComplete={()=> {updateServiceBooking(servToBeComp?.id, servToBeComp?.status); setServToBeComp(null); setShowServiceConfirmModal(false);}} />
      <Layout>
      {
        garageList.length <= 0? (<p className={flex_rev_class}>{t("No Garage Assigned")}</p>):
        (
          <>
          <div
          className={`d-flex justify-content-between align-items-center mb-3 ${rowReverse} gap-2`}
          // style={{ width: "80%" }}
        >
          <Form className={`flex-grow-1 me-2`}>
            <Form.Group className="mb-3" controlId="searchTerm">
              <Form.Label className={`me-2 ${flex_rev_class}`}>{t("Search by Booking UID")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("Enter Booking UID")}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Form.Group>
          </Form>
          <Button variant="primary" onClick={handleSearch} className={`mt-3`}>
            {t("Search")}
          </Button>
          <Button className={`mt-3`}
            variant="primary"
            onClick={triggerScan} // Call triggerScan function when the button is clicked
            disabled={showQrScanner} // Disable the button if the QR scanner is already open
          >
            {t("Scan QR Code")}
          </Button>
        </div>

        {loading ? (
          <Container className="d-flex justify-content-center align-items-center mt-5">
            {loading && <p className={flex_rev_class}>{t("Loading...")}</p>}

            {customerList && customerList.length === 0 && (
              <p className={flex_rev_class}>{t("No customer found with the provided UID.")}</p>
            )}
            {/* <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner> */}
          </Container>
        ) : customerList.length === 0 ? (
          <Container
            fluid
            className="d-flex flex-column align-items-center justify-content-center text-center p-5"
          >
            <>
              <h3 className={flex_rev_class}>{t("No Booking Found")}</h3>
              <span
                className={`mb-4 ${flex_rev_class}`}
                style={{
                  color: "#757575",
                  textAlign: "center",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                }}
              >
                {t("Ready to kick off your customer management? Add customers and")}{" "}
                <br />
                {t("provide them with a seamless experience.")}
              </span>
            </>
          </Container>
        ) : (
          <Container
            fluid
            className="p-2"
            style={{ backgroundColor: "#fff", borderRadius: "15px" }}
          >
            <div style={{ overflowY: "auto" }}>
              <Table
                hover
                responsive
                className="text-center"
                style={{ margin: "0 auto" }}
                dir={language === 'ar' ? "rtl" : "ltr"}
              >
                <thead>
                  <tr>
                    <th>{t("Booking UID")}</th>
                    <th>{t("Customer")}</th>
                    <th>{t("Vehicle")}</th>
                    {/* <th>Product</th>
                    <th>Price</th> */}
                    <th>{t("Joined Date")}</th>
                    <th>{t("Status")}</th>
                    <th>{t("Actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {customerList.map((customer, index) => {
                    return (
                      <tr key={index}>
                        <td>{customer?.bookingUID || ""}</td>
                        <td>
                          <div className="d-flex">
                            <Link to={`/bookingDetails/${customer.id}`}>
                              {/* <img
                              src={userimage}
                              alt="Order"
                              style={{
                                borderRadius: "32px",
                                marginRight: "10px",
                              }}
                              height={35}
                              width={35}
                            /> */}
                            </Link>
                            <div style={{ textAlign: "left" }}>
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontWeight: 600,
                                  margin: 0,
                                }}
                              >
                                {customer.customer?.name || ""}
                              </span>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 400,
                                  color: "#A3A3A3",
                                  margin: 0,
                                }}
                              >
                                {customer.customer?.email || ""}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex">
                            <div style={{ textAlign: "left" }}>
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontWeight: 600,
                                  margin: 0,
                                }}
                              >
                                {customer.vehicle?.brand?.title || ""}
                              </span>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 400,
                                  color: "#A3A3A3",
                                  margin: 0,
                                }}
                              >
                                {customer.vehicle?.vehicleModel?.title || ""}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>{customer?.createdAt || ""}</td>
                        <td>{customer?.status || ""}</td>
                        <td style={{ cursor: "pointer" }}>
                          <Button
                            // updateServiceBooking(customer.id, "completed")
                            variant="success"
                            style={{ width: "60px", margin: "1px" }}
                            onClick={() => {setServToBeComp({ id: customer.id, status: 'completed', }); setShowServiceConfirmModal(true)}}
                          >
                            {t("Ok")}
                          </Button>
                          <Button
                            variant="danger"
                            style={{ width: "60px", margin: "1px" }}
                            onClick={() =>
                              updateServiceBooking(customer.id, "cancelled")
                            }
                          >
                            x
                          </Button>
                          <Button
                            className="m-1"
                            onClick={() => handleViewBookings(customer)}
                          >
                            {t("View Services")}
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3">
              {/* <Pagination>
                <Pagination.Prev
                  onClick={() =>
                    setCurrentPage((prevPage) =>
                      prevPage > 1 ? prevPage - 1 : prevPage
                    )
                  }
                />
                {Array.from({
                  length: Math.ceil(customerList.length / pageSize),
                }).map((item, index) => (
                  <Pagination.Item
                    key={index}
                    active={index + 1 === currentPage}
                    onClick={() => paginate(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() =>
                    setCurrentPage((prevPage) =>
                      prevPage < Math.ceil(customerList.length / pageSize)
                        ? prevPage + 1
                        : prevPage
                    )
                  }
                />
              </Pagination> */}
            </div>
          </Container>
        )}
        {showQrScanner && ( // Render the QR scanner only if showQrScanner is true
          <QrReader
            ref={qrReaderRef}
            delay={300}
            onError={handleError}
            onScan={handleScan}
            style={{
              display: "block",
              width: "100%",
              maxWidth: "300px",
              margin: "auto",
            }}
          />
        )}
        <ServiceSelectionModal
          showModal={showServiceModal}
          handleCloseModal={() => setShowServiceModal(false)}
          selectedCustomer={selectedCustomer} // Pass the selected customer to the modal
          selectedServices={selectedServices}
          setSelectedServices={setSelectedServices}
          handleNewServiceAdd={addServicesupdateFunction}
        />

        {/* Modal for View Bookings */}
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton style={{ flexDirection: language === 'ar' ? 'row-reverse': '', justifyContent: 'space-between' }}>
            <Modal.Title className={`d-flex ${rowReverse}`}>{t("Booked Services")}</Modal.Title>
          </Modal.Header>
          <Modal.Body className={`d-flex ${rowReverse}`}>
            <div className="d-flex justify-content-between align-items-center mb-3">
            <Button variant="primary" onClick={() => handleAddService()}>
              {t("Add Service")}
            </Button>
            <input
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          style={{ display: 'none' }} // Hide the input element
          ref={fileInputRef} // Ref to the input element
        />
        {/* FontAwesomeIcon with onClick event to trigger file input click */}
        <FontAwesomeIcon 
          icon={faUpload} 
          style={{ color: 'primary' }} // Change color to primary
          onClick={handleUploadClick} 
        />
            </div>
        {image && (
          <div>
            <img src={`https://varposbuckets.s3.amazonaws.com/InvoiceImage/${image}`} alt="Uploaded" style={{ maxWidth: '15%', maxHeight: '5%', borderRadius:"50%" }} />
          </div>
        )} 
            <Table dir={language === 'ar' ? "rtl" : "ltr"}>
              <thead>
                <tr>
                  <th>{t("Service")}</th>
                  <th>{t("Category")}</th>
                  <th>{t("Product")}</th>
                  <th>{t("service")}</th>
                  <th>{t("status")}</th>
                  <th>{t("action")}</th>
                </tr>
              </thead>
              <tbody>
                {selectedCustomer &&
                  selectedCustomer?.services?.map((services, index) => {
                    return (
                      <tr key={index}>
                        {services.serviceCategory ? (
                          <td>{services.serviceCategory.title}</td>
                        ) : (
                          <td>{services.serviceItem.title}</td>
                        )}
                        {services.serviceSubCategory ? (
                          <td>{services.serviceSubCategory.title}</td>
                        ) : (
                          <td>{services.mileageInterval}</td>
                        )}
                        {services.specification ? (
                          <>
                            {Object.keys(services.specification).map(
                              (spc, index) => {
                                return <td key={index}>{spc}</td>;
                              }
                            )}
                          </>
                        ) : (
                          <td>{services.action}</td>
                        )}
                        {services.specification ? (
                          <td>( POS )</td>
                        ) : (
                          <td> ({t("Warranty")})</td>
                        )}

                        {services.status ? (
                          <td>{services.status}</td>
                        ) : (
                          <td>-</td>
                        )}
                        {
                          <td style={{ display: "flex" }}>
                            <Button
                              variant="success"
                              style={{ width: "40px", margin: "0.4px" }}
                              onClick={() =>
                                handleUpdateService(services, index, "complete")
                              }
                            >
                              {t("Ok")}
                            </Button>
                            <Button
                              variant="danger"
                              style={{ width: "40px", margin: "0.4px" }}
                              onClick={() =>
                                handleUpdateService(services, index, "cancelled")
                              }
                            >
                              x
                            </Button>
                          </td>
                        }
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>
          </>
        )
      }
      </Layout>
    </>
  );
};

export default Customer;
