// Login.js

import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Form, Button, Image } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import "./login.css"; // Add a CSS file for custom styling
import logo from "../../assets/Logo.png";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { signin } from "../../service/action/authAction";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../Context/UserContext";
import i18n from "../../i18n/i18n";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { language, setLanguage } = useContext(UserContext);
  const rowReverse = language === 'ar' ? "flex-row-reverse" : "";
  const { t } = useTranslation();

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    dispatch(signin(formData)).then((data)=>{
      if(data.status===400 || data.status===401 ){
        alert("invalid email/password")
      }
      if(!!data && data.status!==400  && data.status!==401  ){
        localStorage.setItem("user", JSON.stringify(data))
        
        if(data.roles[0].name === 'cashier'){
          navigate('/order')
        }
        else{
          navigate('/dashboard')
        }
      }
    })
    // formData.forEach((value, key) => {
    //   console.log(`${key}: ${value}`);
    // });

  }

  useEffect(() => {
    if (localStorage) {
      const lang = localStorage.getItem("language");
      if (lang) {
        setLanguage(lang);
        i18n.changeLanguage(lang);
      }
    }
  }, [])

  const handleLanguageChange = () => {
    const lang = language === 'en' ? 'ar' : 'en';
    setLanguage(lang);
    localStorage.setItem("language", lang);
    i18n.changeLanguage(lang);
  }

  return (
    <Container fluid style={{ height: "100vh" }}>
      <div className={`d-flex ${rowReverse}`} style={{ height: "100%" }}>
        <Col
          md={4}
          className="login-form d-flex"
          style={{
            flexDirection: "column",
            justifyContent: "center",
            padding:'10px 30px 0 30px '
          }}
        >
          <div className={`logo-container d-flex ${rowReverse}`}>
            <Image
              src={logo}
              alt="Logo"
              fluid
              className="logo"
              height={90}
              width={90}
            />
          </div>

          <div
          className={`d-flex`}
            style={{
              flexGrow: 1,
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div className="text-header" >

            <p className={`d-flex ${rowReverse}`}>{t("Login")}</p>
            <p className={`d-flex ${rowReverse}`}>{t("Login to your account")}</p>
            </div>

            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicEmail" className="mb-3">
                <Form.Label className={`text-left formLabel d-flex ${rowReverse}`}>{t("Email")}</Form.Label>
                <Form.Control
                  type="email"
                  placeholder={t("Email")}
                  className="formControl"
                  name="email"
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword" className="position-relative mb-3">
                <Form.Label className={`text-left formLabel d-flex ${rowReverse}`}>{t("Password")}</Form.Label>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder={t("Password")}
                  className="formControl"
                  name="password"
                />
                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  className="eye-icon"
                  onClick={handleTogglePassword}
                />
              </Form.Group>

              <Form.Group
                controlId="formBasicCheckbox"
                className={`checkbox-group text-left d-flex ${rowReverse}`}
              >
                <Form.Check className="rememberme" type="checkbox" label={t("Remember me")} name="rememberme" />

                <Link to="forgetPassword" style={{fontSize:'12px', color:'#F7941D'}} >{t("Forgot Password?")}</Link>
              </Form.Group>

              <Button variant="primary" type="submit" className={`signin-btn d-flex ${rowReverse}`}>
                {t("Sign in")}
              </Button>

              <p className={`signup-text`}>
                {t("Don't already have an account?")} <Link to="signup" style={{color:"#F7941D"}}>{t("Signup")}</Link>
              </p>
              
              <div onClick={handleLanguageChange} style={{ display: 'flex', justifyContent: 'center' }}>
                <p style={{ cursor: 'pointer', borderRadius: '15px', border: '2px solid orange', padding: '7px 12px' }}>Change to {language === 'ar' ? "English": "Arabic"}</p>
              </div>
            </Form>
          </div>
        </Col>

        <Col md={8} className="background-image" style={{ height: "100%" }}>
          {/* Add image to the background */}
          <div className="text">
            <h4 className={`d-flex ${rowReverse}`}>{t("Features of VAR Mobile Application")}</h4>
            <p className={`d-flex ${rowReverse}`}>
              {t(`Will enable users to get all required services with only one click
              away. This convenience will eliminate the hustle and emergency
              situations which any car owner faces when there is an urgent need
              and as well as the routine care of their vehicle.`)}
            </p>
          </div>
        </Col>
      </div>
    </Container>
  );
};

export default Login;
