// Login.js

import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Form, Button, Image } from "react-bootstrap";
import "./login.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/Logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signup } from "../../service/action/authAction";
import http from "../../utils/Api";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import verifyImage from '../../assets/Verify.png'
import { useTranslation } from "react-i18next";
import { UserContext } from "../../Context/UserContext";
import i18n from "../../i18n/i18n";


const Signup = () => {
  const [ownerInfo, setOwnerInfo] = useState({
    firstName: "",
    lastName: "",
    iqamaNumber: "",
    company: "",
    commercialNumber: "",
    emailAddress: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
  });

  const [requestSubmitted, setRequestSubmitted ] =useState(false)

  const [profilePicture, setProfilePicture] = useState(null);
  const [documents, setDocuments] = useState([]);
  const { language, setLanguage } = useContext(UserContext);
  const rowReverse = language === 'ar' ? "flex-row-reverse" : "";
  const { t } = useTranslation();

  const handleChangeNew = (event) => {
    const { name, value } = event.target;
    setOwnerInfo({ ...ownerInfo, [name]: value });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfilePicture(file);
    }
  };

  const handleDocumentChange = (event) => {
    const files = event.target.files;
    const newDocuments = [...documents];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (!file.type.startsWith("image")) {
        newDocuments.push(file);
      }
    }

    setDocuments(newDocuments);
  };
  const handleRemoveDocument = (indexToRemove) => {
    const newDocuments = documents.filter(
      (_, index) => index !== indexToRemove
    );
    setDocuments(newDocuments);
  };

  const handleSubmitOWNER = async (event) => {
    event.preventDefault();

    const formData = new FormData();

    // Append ownerInfo fields
    Object.entries(ownerInfo).forEach(([key, value]) => {
      formData.append(key, value);
    });

    // Append profilePicture
    if (profilePicture) {
      formData.append("profilePicture", profilePicture);
    }

    documents.forEach((documenta, index) => {
      formData.append(`documents`, documenta);
    });
    const id = localStorage.getItem("userId");

    formData.append("userId", id);
    try {
      const token = localStorage.getItem("accessToken");

      const config = http.generateConfig(token);
      config.headers["Content-Type"] = "multipart/form-data";

      const response = await http.post("/api/ownerRequestSignup", formData, config);
      console.log(response, "KAJSBKJAS");
      if (response && (response.status === 400  || response.status===500)) {
        alert("Validation Error check Email enter all fields and upload Documents");
      } else {
        alert("Request sent to admin");
        // Clear form fields and state after successful submission
        setOwnerInfo({
          firstName: "",
          lastName: "",
          iqamaNumber: "",
          company: "",
          commercialNumber: "",
          emailAddress: "",
          phoneNumber: "",
          password: "",
          confirmPassword: "",
        });
        setProfilePicture(null);
        setDocuments([]);

        setRequestSubmitted(true)
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    }
  };





  // SIGNUP PREVIOUS
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage) {
      const lang = localStorage.getItem("language");
      if (lang) {
        setLanguage(lang);
        i18n.changeLanguage(lang);
      }
    }
  }, [])

  const handleLanguageChange = () => {
    const lang = language === 'en' ? 'ar' : 'en';
    setLanguage(lang);
    localStorage.setItem("language", lang);
    i18n.changeLanguage(lang);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    console.log(formData);

    // Dispatch your signup action here
    dispatch(signup(formData)).then((data) => {
      if (!!data) {
        navigate("/");
      }
    });
  };

  return (
    <Container fluid style={{ height: "100vh" }}>
      <div className={`d-flex ${rowReverse}`} style={{ height: "100%", }}>
        {requestSubmitted ? (<>
          <Col md={4}>
            <Container
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh", // Ensure the Container covers the entire viewport height
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    <div>
                      <img  src={verifyImage}  />
                    </div>
                    <br />
                    {t("Your request has been successfully submitted for")} <br /> {t("approval by the administrator.")}
                  </div>
                  <div style={{ textAlign: "center" }}>
                  
                    <p className="signup-text">
                      {t("Already have an account?")}{" "}
                      <Link to="/" style={{ color: "#F7941D" }}>
                        {t("Sign in")}
                      </Link>
                    </p>
                  </div>
                  {/* <button
                    style={{
                      width: "271px",
                      height: "40px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                      flexShrink: 0,
                      borderRadius: "999px",
                      borderColor: "orange",
                      background: "linear-gradient(97deg, #F15822 0%, #F7941D 100%)",
                      marginTop: "5px",
                    }}
                    onClick={handleLogoutClick}
                  >
                    Log out
                  </button> */}
            </Container>
          </Col>
        </>):(<>
          <Col
          md={4}
          className={`login-form d-flex`}
          style={{
            flexDirection: "column",
            justifyContent: "center",
            padding: "10px 15px 0 30px ",
          }}
        >
          <div
            className={`d-flex`}
            style={{
              flexDirection: "column",
              justifyContent: "center",
              maxHeight: "100vh",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <div className={`logo-container d-flex ${rowReverse}`}>
              <Image
                src={logo}
                alt="Logo"
                fluid
                className="logo"
                height={90}
                width={90}
              />
            </div>

            <div
              className={`d-flex`}
              style={{
                flexGrow: 1,
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Form onSubmit={handleSubmitOWNER}>
                <div className={`d-flex ${rowReverse}`}>
                  <Col md={3} style={{ textAlign: "center" }}>
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "50%",
                        overflow: "hidden",
                        margin: "0 auto",
                        position: "relative",
                        backgroundColor: "#ccc",
                      }}
                    >
                      <img
                        src={
                          profilePicture
                            ? URL.createObjectURL(profilePicture)
                            : "/default-profile-pic.png"
                        }
                        alt="Profile"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                      <label
                        htmlFor="fileInputProfile"
                        style={{
                          position: "absolute",
                          bottom: "0",
                          right: "0",
                          background: "#F15822",
                          borderRadius: "50%",
                          padding: "6px",
                          cursor: "pointer",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPencilAlt}
                          style={{ color: "#FFF" }}
                        />
                      </label>
                      <input
                        id="fileInputProfile"
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                      />
                    </div>
                    <p style={{ marginTop: "10px" }}>{t("Profile Picture")}</p>
                  </Col>

                  <Col md={9}>
                    <Form.Group controlId="firstName" className="mb-3">
                      {/* <Form.Label>First Name:</Form.Label> */}
                      <Form.Control
                        placeholder={t("First Name")}
                        type="text"
                        name="firstName"
                        value={ownerInfo.firstName}
                        onChange={handleChangeNew}
                      />
                    </Form.Group>
                    <Form.Group controlId="lastName" className="mb-2">
                      {/* <Form.Label>Last Name:</Form.Label> */}
                      <Form.Control
                        placeholder={t("Last Name")}
                        type="text"
                        name="lastName"
                        value={ownerInfo.lastName}
                        onChange={handleChangeNew}
                      />
                    </Form.Group>
                  </Col>
                </div>
                <div className={`d-flex ${rowReverse}`}>
                  <Col md={12}>
                    <div className="mb-2">
                      <Form.Group controlId="iqamaNumber">
                        {/* <Form.Label>Iqama Number:</Form.Label> */}
                        <Form.Control
                          placeholder={t("National ID or IQama")}
                          type="text"
                          name="iqamaNumber"
                          value={ownerInfo.iqamaNumber}
                          onChange={handleChangeNew}
                        />
                      </Form.Group>
                    </div>
                    <div className="mb-2">
                      <Form.Group controlId="company">
                        {/* <Form.Label>Iqama Number:</Form.Label> */}
                        <Form.Control
                          placeholder={t("Company")}
                          type="text"
                          name="company"
                          value={ownerInfo.company}
                          onChange={handleChangeNew}
                        />
                      </Form.Group>
                    </div>
                    <div className="mb-2">
                      <Form.Group controlId="commercialNumber">
                        {/* <Form.Label>Iqama Number:</Form.Label> */}
                        <Form.Control
                          placeholder={t("Commercial Number")}
                          type="text"
                          name="commercialNumber"
                          value={ownerInfo.commercialNumber}
                          onChange={handleChangeNew}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                </div>
                <Row>
                  <Col className="mb-2">
                    <Form.Label className={`d-flex ${rowReverse}`}>{t("Upload Commercial Documents")}</Form.Label>
                    <Form.Group
                      controlId="uploadPhotos"
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        padding: "16px",
                        borderRadius: "15px",
                        border: "1px solid #D3DAE7",
                        background: "#F3F7FF",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <label
                          htmlFor="fileInputDocuments"
                          style={{ cursor: "pointer" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M9 17V11M9 11L7 13M9 11L11 13"
                              stroke="#F15822"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14"
                              stroke="#F15822"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M22 10H18C15 10 14 9 14 6V2L22 10Z"
                              stroke="#F15822"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </label>
                        <input
                          id="fileInputDocuments"
                          type="file"
                          multiple
                          accept="image/*"
                          onChange={handleDocumentChange}
                          style={{ display: "none" }}
                        />
                      </div>
                      {documents?.length > 0 ? (
                        <div
                          style={{
                            display: "flex",
                            // flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            marginTop: "10px",
                            width: "100%",
                          }}
                        >
                          {documents?.map((document, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                flexDirection: language === 'ar' ? "row-reverse": "row",
                                alignItems: "center",
                                margin: "2px",
                              }}
                            >
                              <span style={{ marginRight: "10px" }}>
                                {document.name}
                              </span>
                              <button
                                type="button"
                                onClick={() => handleRemoveDocument(index)}
                                style={{
                                  marginLeft: "10px",
                                  background: "none",
                                  border: "none",
                                  cursor: "pointer",
                                  color: "red",
                                  // border:"1px solid red",
                                  // borderRadius:"90px",
                                  cursor: "pointer",
                                }}
                              >
                                x
                              </button>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <p
                          style={{
                            color: "#87898D",
                            textAlign: "center",
                            fontFeatureSettings: "'clig' off, 'liga' off",
                            fontFamily: "Source Sans Pro",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                          }}
                        >
                          {t("Drag files here or click to select")}
                          <br />
                          {t("Up to 2 MB")}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="emailAddress" className="mb-2">
                      <Form.Control
                        placeholder={t("Email")}
                        type="tel"
                        name="emailAddress"
                        value={ownerInfo.emailAddress}
                        onChange={handleChangeNew}
                      />
                    </Form.Group>

                    <div className={`d-flex ${rowReverse}`}>
                      <Col md={6} className="mb-2">
                        <Form.Group
                          controlId="password"
                          className="position-relative mb-3"
                        >
                          <Form.Control
                            type={showPassword ? "text" : "password"}
                            placeholder={t("Password")}
                            className="formControl"
                            name="password"
                            value={ownerInfo.password}
                            onChange={handleChangeNew}
                          />
                          <FontAwesomeIcon
                            icon={showPassword ? faEyeSlash : faEye}
                            className="eye-icon"
                            onClick={handleTogglePassword}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6} className="mb-2">
                        <Form.Group
                          controlId="confirmPassword"
                          className="position-relative mb-3"
                        >
                          <Form.Control
                            type={showPassword ? "text" : "password"}
                            placeholder={t("Confirm Password")}
                            className="formControl"
                            name="confirmPassword"
                            value={ownerInfo.confirmPassword}
                            onChange={handleChangeNew}
                          />
                          <FontAwesomeIcon
                            icon={showPassword ? faEyeSlash : faEye}
                            className="eye-icon"
                            onClick={handleTogglePassword}
                          />
                        </Form.Group>
                      </Col>

                      <Col md={12}>
                        <Form.Group controlId="phoneNumber">
                          <Form.Control
                            placeholder={t("Phone Number")}
                            type="tel"
                            name="phoneNumber"
                            value={ownerInfo.phoneNumber}
                            onChange={handleChangeNew}
                          />
                        </Form.Group>
                      </Col>
                    </div>
                  </Col>
                </Row>

                <Form.Group
                  controlId="formBasicCheckbox"
                  className={`checkbox-group text-left mt-2 d-flex ${rowReverse} me-1`}
                >
                  <Form.Check
                    custom
                    className="rememberme"
                    type="checkbox"
                    id="custom-checkbox"
                    label={
                      <span style={{ fontSize: "16px", fontWeight: 600, width: '100%', display: 'flex', flexDirection: language === 'ar' ? 'row-reverse': 'row', gap: '5px', }}>
                        {t("I accept the")}{" "}
                        <Link to="#" style={{ color: "#F7941D" }}>
                          {t("terms")}{" "}
                        </Link>
                        {t("and")}{" "}
                        <Link to="#" style={{ color: "#F7941D" }}>
                          {t("conditions")}
                        </Link>
                      </span>
                    }
                  />
                </Form.Group>

                <div style={{ textAlign: "center" }}>
                  <Button
                    variant="primary"
                    type="submit"
                    className="signup-btn"
                  >
                    {t("Sign up")}
                  </Button>
                  <p className="signup-text">
                    {t("Already have an account?")}{" "}
                    <Link to="/" style={{ color: "#F7941D" }}>
                      {t("Sign in")}
                    </Link>
                  </p>
                </div>
                <div onClick={handleLanguageChange} style={{ display: 'flex', justifyContent: 'center' }}>
                  <p style={{ cursor: 'pointer', borderRadius: '15px', border: '2px solid orange', padding: '7px 12px' }}>Change to {language === 'ar' ? "English": "Arabic"}</p>
                </div>
              </Form>
              {/* <div className="text-header text-center">
              <p>Create Your Account</p>
              <p>Welcome to VAR! Please create your account</p>
            </div>

            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicName" className="mb-3">
                <Form.Label className="text-left formLabel">Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  className="formControl"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail" className="mb-3">
                <Form.Label className="text-left formLabel">Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  className="formControl"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group
                controlId="formBasicPassword"
                className="position-relative mb-3"
              >
                <Form.Label className="text-left formLabel">
                  Password
                </Form.Label>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  className="formControl"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  className="eye-icon"
                  onClick={handleTogglePassword}
                />
              </Form.Group>

              <Form.Group
                controlId="formBasicCheckbox"
                className="checkbox-group text-left"
              >
                <Form.Check
                  custom
                  className="rememberme"
                  type="checkbox"
                  id="custom-checkbox"
                  label={
                    <span>
                      I accept the{" "}
                      <Link to="#" style={{ color: "#F7941D" }}>
                        terms{" "}
                      </Link>
                      and{" "}
                      <Link to="#" style={{ color: "#F7941D" }}>
                        conditions
                      </Link>
                    </span>
                  }
                />
              </Form.Group>

              <Button variant="primary" type="submit" className="signin-btn">
                Sign up
              </Button>

              <p className="signup-text">
                Already have an account?{" "}
                <Link to="/" style={{ color: "#F7941D" }}>
                  Sign in
                </Link>
              </p>
            </Form> */}
            </div>
          </div>
        </Col>
        </>)}
       

        <Col md={8} className="background-image" style={{ height: "100%" }}>
          {/* Add image to the background */}
          <div className="text">
            <h4 className={`d-flex ${rowReverse}`}>{t("Features of VAR Mobile Application")}</h4>
            <p className={`d-flex ${rowReverse}`}>
              {t(`Will enable users to get all required services with only one click
              away. This convenience will eliminate the hustle and emergency
              situations which any car owner faces when there is an urgent need
              and as well as the routine care of their vehicle.`)}
            </p>
          </div>
        </Col>
      </div>
    </Container>
  );
};

export default Signup;
