import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/Logo.png";
import "../../App.css";
import http from "../../utils/Api";
import { useNavigate } from "react-router-dom";

const OwnerRequest = () => {
  const [ownerInfo, setOwnerInfo] = useState({
    iqamaNumber: "",
    emailAddress: "",
    phoneNumber: "",
    company: "",
  });

  const [profilePicture, setProfilePicture] = useState(null);
  const [documents, setDocuments] = useState([]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setOwnerInfo({ ...ownerInfo, [name]: value });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfilePicture(file);
    }
  };

  const handleDocumentChange = (event) => {
    const files = event.target.files;
    const newDocuments = [...documents];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (!file.type.startsWith("image")) {
        newDocuments.push(file);
      }
    }

    setDocuments(newDocuments);
  };
  const handleRemoveDocument = (indexToRemove) => {
    const newDocuments = documents.filter(
      (_, index) => index !== indexToRemove
    );
    setDocuments(newDocuments);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();

    // Append ownerInfo fields
    Object.entries(ownerInfo).forEach(([key, value]) => {
      formData.append(key, value);
    });

    // Append profilePicture
    if (profilePicture) {
      formData.append("profilePicture", profilePicture);
    }

    documents.forEach((documenta, index) => {
      formData.append(`documents`, documenta);
    });
    const id = localStorage.getItem("userId");

    formData.append("userId", id);
    try {
      const token = localStorage.getItem("accessToken");

      const config = http.generateConfig(token);
        config.headers['Content-Type'] = 'multipart/form-data';


      const response = await http.post("/api/ownerRequest", formData,config);
      console.log(response,"KAJSBKJAS")
      if(response && response.status  === 400){
        alert("no document provided")
      }
     else{
      alert("Request sent to admin")
       // Clear form fields and state after successful submission
       setOwnerInfo({
         iqamaNumber: "",
         emailAddress: "",
         phoneNumber: "",
         company: "",
        });
        setProfilePicture(null);
        setDocuments([]);
        window.location.reload();
      } 
    } catch (error) {


      console.error("Error:", error);
      // Handle error
    }
  };

  const navigate = useNavigate();

  const handleLogoutClick = () => {
    // dispatch(logo)
    localStorage.removeItem("accessToken");
    localStorage.removeItem("sessionToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("user");
    navigate("/");
  };

  return (
    <>
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
        className="mt-5 mb-5"
      >
        <div
          style={{
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={logo} alt="VAR" height={80} />
          <button
            style={{
              width: "271px",
              height: "40px",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              flexShrink: 0,
              borderRadius: "999px",
              borderColor: "orange",
              background: "linear-gradient(97deg, #F15822 0%, #F7941D 100%)",
              marginTop: "5px",
            }}
            onClick={handleLogoutClick}
          >
            Log out
          </button>
        </div>
      </Container>
      <Container style={{}}>
        <div style={{ border: "1px solid gray", textAlign: "left" }}>
          <h3>Owner Information</h3>
          <div style={{ border: "1px solid gray" }}>
            <Row>
              <Col md={3} style={{ textAlign: "center" }}>
                <div
                  style={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    margin: "0 auto",
                    position: "relative",
                    backgroundColor: "#ccc",
                  }}
                >
                  <img
                    src={
                      profilePicture
                        ? URL.createObjectURL(profilePicture)
                        : "/default-profile-pic.png"
                    }
                    alt="Profile"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <label
                    htmlFor="fileInputProfile"
                    style={{
                      position: "absolute",
                      bottom: "0",
                      right: "0",
                      background: "#F15822",
                      borderRadius: "50%",
                      padding: "6px",
                      cursor: "pointer",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPencilAlt}
                      style={{ color: "#FFF" }}
                    />
                  </label>
                  <input
                    id="fileInputProfile"
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                </div>
                <p style={{ marginTop: "10px" }}>Profile Picture</p>
              </Col>
              <Col
                md={9}
                style={{
                  textAlign: "left",
                }}
              >
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col>
                      <Form.Group controlId="iqamaNumber">
                        <Form.Label>Iqama Number:</Form.Label>
                        <Form.Control
                          type="text"
                          name="iqamaNumber"
                          value={ownerInfo.iqamaNumber}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="emailAddress">
                        <Form.Label>Email Address:</Form.Label>
                        <Form.Control
                          type="email"
                          name="emailAddress"
                          value={ownerInfo.emailAddress}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group controlId="phoneNumber">
                        <Form.Label>
                          Phone Number (including country code):
                        </Form.Label>
                        <Form.Control
                          type="tel"
                          name="phoneNumber"
                          value={ownerInfo.phoneNumber}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="company">
                        <Form.Label>Company Name:</Form.Label>
                        <Form.Control
                          type="text"
                          name="company"
                          value={ownerInfo.company}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Label>Upload Documents</Form.Label>
                      <Form.Group
                        controlId="uploadPhotos"
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          padding: "16px",
                          borderRadius: "15px",
                          border: "1px solid #D3DAE7",
                          background: "#F3F7FF",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <label
                            htmlFor="fileInputDocuments"
                            style={{ cursor: "pointer" }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M9 17V11M9 11L7 13M9 11L11 13"
                                stroke="#F15822"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14"
                                stroke="#F15822"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M22 10H18C15 10 14 9 14 6V2L22 10Z"
                                stroke="#F15822"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </label>
                          <input
                            id="fileInputDocuments"
                            type="file"
                            multiple
                            accept="image/*"
                            onChange={handleDocumentChange}
                            style={{ display: "none" }}
                          />
                        </div>
                        {documents.length > 0 ? (
                          <div
                            style={{
                              display: "flex",
                              // flexDirection: "column",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                              marginTop: "10px",
                              width:"100%"

                            }}
                          >
                            {documents.map((document, index) => (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  margin:"2px"
                                }}
                              >
                                
                                <span style={{ marginRight: "10px" }}>
                                  {document.name}
                                </span>
                                <button
                                  type="button"
                                  onClick={() => handleRemoveDocument(index)}
                                  style={{
                                    marginLeft: "10px",
                                    background: "none",
                                    border: "none",
                                    cursor: "pointer",
                                    color:"red",
                                    // border:"1px solid red",
                                    // borderRadius:"90px",
                                    cursor:"pointer"
                                  }}
                                >
                                  x
                                </button>
                              </div>
                            ))}
                          </div>
                        ) : (
                           <p
                            style={{
                              color: "#87898D",
                              textAlign: "center",
                              fontFeatureSettings: "'clig' off, 'liga' off",
                              fontFamily: "Source Sans Pro",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "normal",
                            }}
                          >
                            Drag files here or click to select
                            <br />
                            Up to 2 MB
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <button
                    style={{
                      display: "flex",
                      width: "271px",
                      height: "40px",
                      padding: "20px 43px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                      flexShrink: 0,
                      borderRadius: "999px",
                      borderColor: "orange",
                      background:
                        "linear-gradient(97deg, #F15822 0%, #F7941D 100%)",
                      marginTop: "5px",
                    }}
                    type="submit"
                  >
                    Submit
                  </button>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
};

export default OwnerRequest;
