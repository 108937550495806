import React, { useState, useEffect, useContext } from "react";
import { Modal, Form, Dropdown, Button } from "react-bootstrap";
import http from "../../utils/Api";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../Context/UserContext";

const CustomerSelectionModal = ({
  customerList,
  onCreateCustomer,
  onCreateVehicle,
  selectedCustomer,
  selectedVehicle,
  setSelectedCustomer,
  setSelectedVehicle,
  showModal,
  setShowModal,
  brands,
  selectedService,
  flow
}) => {
  const [newCustomerData, setNewCustomerData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    iqamaNumber:""
  });

  const [newVehicleData, setNewVehicleData] = useState({
    brandName: "",
    carModel: "",
    carNumber: "",
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [vehicles, setVehicles] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedVehicleModel, setSelectedVehicleModel]= useState("")
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [showNewCustomerFields, setShowNewCustomerFields] = useState(true);
  const [showNewVehicleFields, setShowNewVehicleFields] = useState(true);
  
  const [ModelEngineTypes , setModelEngineTypes]= useState([])
  const [selectedEngineType, setSelectedEngineType] = useState('')
  const [vehicleModels , setVehicleModels] = useState([])
  const { language } = useContext(UserContext);
  const rowReverse = language === 'ar' ? "flex-row-reverse" : "";
  const { t } = useTranslation();


  useEffect(() => {
    if (selectedCustomer) {
      fetchVehicles(selectedCustomer.id);
    }
  }, [selectedCustomer]);

  const fetchVehicles = async (customerId) => {
    try {
      const token = localStorage.getItem("accessToken");

      const response = await http.get(
        `/api/customerVehicle/${customerId}`,
        http.generateConfig(token)
      );
      console.log(response, "THE RESPONSEASDASD");
      if (response) {
        setVehicles(response);
      }
    } catch (error) {
      console.error("Error fetching vehicles:", error);
    }
  };

  const handleSelectCustomer = (customer) => {
    setSelectedCustomer(customer);
    setSelectedCustomerId(customer.id);
    setShowNewCustomerFields(false);
    setShowNewVehicleFields(true);
    // setShowModal(false);
  };



  const handleCreateCustomer = () => {
    // console.log(newCustomerData,"JALKSKJDA")
    onCreateCustomer(newCustomerData);
    // setSelectedCustomer(newCustomerData)
    // setShowModal(false);
  };


  const handleCreatingVehicle= async(newVehicleData)=>{
    const token = localStorage.getItem("accessToken");
    const res = await http.post(
      `/api/vehiclePos`,
      { ...newVehicleData, customerId: selectedCustomer.id },
      http.generateConfig(token)
    );
    if(res){
      console.log(res,"THE RESPOSNE")
      setVehicles([...vehicles, {...res.result, brand:res.brand}])
    }
  
  }
  const handleCreateVehicle = () => {
    // const vehicle = { ...newVehicleData, brandName: selectedBrand };
    const vehicleData= {
      brandName: selectedBrand,
       carModel:selectedVehicleModel,
      engineType: selectedEngineType,
      carNumber: newVehicleData.carNumber
      }
    // console.log(selectedBrand, selectedVehicleModel,selectedEngineType,newVehicleData.carNumber ,"TEH VEHICLE MODEL")
    handleCreatingVehicle(vehicleData)
    

    // onCreateVehicle(vehicle);
    // setSelectedVehicle(newVehicleData)
    // setShowModal(false);
  };

  const handleCloseModal = () => {
    setSelectedCustomer(null);
    setSelectedVehicle(null);
    setShowModal(false);
  };

  const filteredCustomerList = customerList.filter((customer) => {
    const { name, email, mobileNumber } = customer;
    const searchRegex = new RegExp(searchTerm, "i");
    return (
      searchRegex.test(name) ||
      searchRegex.test(email) ||
      searchRegex.test(mobileNumber)
    );
  });

  const selectedGarage = useSelector((state) => state.selectedGarage);

  const handleVehicleSelection= async (e)=>{
    setSelectedVehicle(e.target.value)
    if(flow==="Schedule"){
      const payload = { carNumber:e.target.value ,customer:selectedCustomer,service:selectedService, garageId:selectedGarage.id}
      const token = localStorage.getItem("accessToken");
      await http.post('/api/Booking-pos',payload,http.generateConfig(token))
      setShowModal(false)
    }
    else{

      
      const payload = { carNumber:e.target.value ,customer:selectedCustomer,service:selectedService, garageId:selectedGarage.id}
      // console.log(payload,'THE PAYLOAD HER')
      const token = localStorage.getItem("accessToken");
      await http.post('/api/Booking-pos',payload,http.generateConfig(token))
      setShowModal(false)
    }
  }


  const selectingBrand =async (e)=>{

    setSelectedBrand(e)
    const token = localStorage.getItem("accessToken");
    const res = await http.get(`/api/vehicleModel/${e}`, http.generateConfig(token))
    if(!!res){
      setVehicleModels(res.result)
    }
  }

  const onVehicleModelSelect = async(e)=>{
    setSelectedVehicleModel(e)
    const sModel = vehicleModels.filter((model)=> model.title === e)
    setModelEngineTypes(sModel[0].engineTypes)
  }


  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton style={{ flexDirection: language === 'ar' ? 'row-reverse': '', justifyContent: 'space-between' }}>
        <Modal.Title className={`d-flex ${rowReverse}`}>{t("Select Customer")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formSearch">
            <Form.Label className={`d-flex ${rowReverse}`}>{t("Search Customer")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("Search by name, email, or phone number")}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Form.Group>
          {showNewCustomerFields && (
            <Form.Group controlId="formNewCustomer">
              <Form.Label className={`d-flex ${rowReverse}`}>{t("New Customer")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("Enter name")}
                value={newCustomerData.name}
                onChange={(e) =>
                  setNewCustomerData({
                    ...newCustomerData,
                    name: e.target.value,
                  })
                }
              />
              <Form.Control
                type="email"
                placeholder={t("Enter email")}
                value={newCustomerData.email}
                onChange={(e) =>
                  setNewCustomerData({
                    ...newCustomerData,
                    email: e.target.value,
                  })
                }
              />
              <Form.Control
                type="text"
                placeholder={t("Enter mobile number")}
                value={newCustomerData.mobileNumber}
                onChange={(e) =>
                  setNewCustomerData({
                    ...newCustomerData,
                    mobileNumber: e.target.value,
                  })
                }
              />
              <Form.Control
                type="text"
                placeholder={t("Enter iqama number")}
                value={newCustomerData.iqamaNumber}
                onChange={(e) =>
                  setNewCustomerData({
                    ...newCustomerData,
                    iqamaNumber: e.target.value,
                  })
                }
              />
            </Form.Group>
            
          )}
          <Form.Group controlId="formCustomer">
            <Form.Label className={`d-flex ${rowReverse}`}>{t("Existing Customer")}</Form.Label>
            {filteredCustomerList?.length > 0 ? (
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  style={{
                    width: "100%",
                    textAlign: "left",
                    backgroundColor: "white",
                    color: "black",
                  }}
                >
                  {selectedCustomer ? selectedCustomer?.name : t("Select Customer")}
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
                  {filteredCustomerList?.map((customer) => (
                    <Dropdown.Item
                      key={customer.id}
                      onClick={() => handleSelectCustomer(customer)}
                    >
                      {customer.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <Form.Text className={`d-flex ${rowReverse}`}>{t("No customers found.")}</Form.Text>
            )}
              <Form.Group controlId="formBrand">
                <Form.Label className={`d-flex ${rowReverse}`}>{t("Select Brand")}</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedBrand}
                  onChange={(e) => selectingBrand(e.target.value)}
                >
                  <option value="">{t("Select Brand")}</option>
                  {brands.map((brand) => (
                    <option key={brand.id} value={brand.title}>
                      {brand.title}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
             
          </Form.Group>
          {showNewVehicleFields && (
            <Form.Group controlId="formNewVehicle">
              {/* <Form.Control
                type="text"
                placeholder="Enter car model"
                value={newVehicleData.carModel}
                onChange={(e) =>
                  setNewVehicleData({
                    ...newVehicleData,
                    carModel: e.target.value,
                  })
                }
              /> */}

               <Form.Group controlId="formVehicleModel"  >
                <Form.Label className={`d-flex ${rowReverse}`}>{t("Select Model")}</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedVehicleModel}
                  onChange={(e) => onVehicleModelSelect(e.target.value)}
                >
                  <option value="" className={`d-flex ${rowReverse}`}>{t("Select Model")}</option>
                  {vehicleModels.map((models) => (
                    <option key={models.id} value={models.title}>
                      {models.title}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="formVehicleEngineType"  >
                <Form.Label className={`d-flex ${rowReverse}`}>{t("Select Engine Type")}</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedEngineType}
                  onChange={(e) => setSelectedEngineType(e.target.value)}
                >
                  <option value="" className={`d-flex ${rowReverse}`}>{t("Select EngineType")}</option>
                  {ModelEngineTypes.map((engineTypes) => (
                    <option key={engineTypes} value={engineTypes}>
                      {engineTypes}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Control
              className="mt-2"
                type="text"
                placeholder={t("Enter car number")}
                value={newVehicleData.carNumber}
                onChange={(e) =>
                  setNewVehicleData({
                    ...newVehicleData,
                    carNumber: e.target.value,
                  })
                }
              />

              <Form.Group controlId="formVehicle">
                <Form.Label className={`d-flex ${rowReverse}`}>{t("Select Vehicle")}</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedVehicle} // Ensure that selectedVehicle is properly set
                  onChange={(e) => handleVehicleSelection(e)} // Update selectedVehicle state
                >
                  <option value="">{t("Select Vehicle")}</option>
                  {vehicles && vehicles.length > 0 ? (
                    vehicles.map((vehicle) => (
                      <option key={vehicle.id} value={vehicle.carNumber}>
                       {vehicle.brand.title} {vehicle.carModel} {vehicle.carNumber} 
                      </option>
                    ))
                  ) : (
                    <option disabled>{t("No vehicles available")}</option>
                  )}
                </Form.Control>
              </Form.Group>
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          {t("Close")}
        </Button>
        {showNewCustomerFields && (
          <Button variant="primary" onClick={handleCreateCustomer}>
            {t("Create Customer")}
          </Button>
        )}
        {showNewVehicleFields && (
          <Button
            variant="primary"
            onClick={handleCreateVehicle}
            disabled={!selectedCustomer}
          >
            {t("Create Vehicle")}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CustomerSelectionModal;
