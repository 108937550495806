import http from "../../utils/Api";
import { addCompanyUser, assignGarage, getCompanyUsers } from "../actionTypes";

const adduser = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");
    const id = localStorage.getItem("userId");
    const user = JSON.parse(localStorage.getItem("user"));
    const payload = {...data, companyId:user.companyId}
    
    const res = await http.post(`/api/user`, payload, http.generateConfig(token));

    if (res) {
      dispatch({
        type: addCompanyUser,
        payload: res.result,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

const assignGarageToUser = (data) => async (dispatch) => {
  try {
    console.log(data,"THE DATA")
    const token = localStorage.getItem("accessToken");
    const id = localStorage.getItem("userId");
    const user = JSON.parse(localStorage.getItem("user"));

    const res = await http.post(
      `/api/assignGarageToUser`,
      data,
      http.generateConfig(token)
    );

    if (res) {
      dispatch({
        type: assignGarage,
        payload: res,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getAllUsers = (data) =>  async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");
    const id = localStorage.getItem("userId");
    const user = JSON.parse(localStorage.getItem("user"));
    const res = await http.get(
      `/api/companyUsers/${user.companyId}`,
      http.generateConfig(token)
    );
    if (res) {
      dispatch({
        type: getCompanyUsers,
        payload: res.data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};


export {
    adduser,
    assignGarageToUser,
    getAllUsers
}