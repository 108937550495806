import React, { useContext, useEffect, useState } from "react";
import { Layout, OrderStats } from "../components";
import { Col, Container, Row, Table } from "react-bootstrap";
import userOrderImage from "../assets/userOrderImage.png";
import OrderChart from "../components/orderChart";
import dots from "../assets/dots.png";
import { useDispatch } from "react-redux";
import { getUserGarages } from "../service/action/garageAction";
import { getServiceCategories } from "../service/action/serviceCategory";
import { UserContext } from "../Context/UserContext";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const { language } = useContext(UserContext);
  const rowReverse = language === 'ar' ? "flex-row-reverse" : "";
  const { t } = useTranslation();

  console.log(language, "====selectedLangselectedLang");

  const Orders = [
    {
      name: "Azhar Naeem",
      email: "azharnaeem76@gmail.com",
      amount: "980.00",
      image: userOrderImage,
    },
    {
      name: "Azhar Naeem",
      email: "azharnaeem76@gmail.com",
      amount: "980.00",
      image: userOrderImage,
    },
    {
      name: "Azhar Naeem",
      email: "azharnaeem76@gmail.com",
      amount: "980.00",
      image: userOrderImage,
    },
    {
      name: "Azhar Naeem",
      email: "azharnaeem76@gmail.com",
      amount: "980.00",
      image: userOrderImage,
    },
    {
      name: "Azhar Naeem",
      email: "azharnaeem76@gmail.com",
      amount: "980.00",
      image: userOrderImage,
    },
    {
      name: "Azhar Naeem",
      email: "azharnaeem76@gmail.com",
      amount: "980.00",
      image: userOrderImage,
    },
    {
      name: "Azhar Naeem",
      email: "azharnaeem76@gmail.com",
      amount: "980.00",
      image: userOrderImage,
    },
    {
      name: "Azhar Naeem",
      email: "azharnaeem76@gmail.com",
      amount: "980.00",
      image: userOrderImage,
    },
    {
      name: "Azhar Naeem",
      email: "azharnaeem76@gmail.com",
      amount: "980.00",
      image: userOrderImage,
    },
    {
      name: "Azhar Naeem",
      email: "azharnaeem76@gmail.com",
      amount: "980.00",
      image: userOrderImage,
    },
    {
      name: "Azhar Naeem",
      email: "azharnaeem76@gmail.com",
      amount: "980.00",
      image: userOrderImage,
    },
    // ... (other orders)
  ];

  const OrderStatistics = [
    { title: "Total Orders", count: '1000', ratio: "10%" },
    { title: "Total Sales", count: '$1000', ratio: "10%" },
    { title: "Active Orders", count: '1000', ratio: "10%" },
    { title: "Average orders size", count: '$1000', ratio: "10%" },
  ];

  const [bestPerfoemingGarages, setbestPerfoemingGarages] = useState([
    {
      image: userOrderImage,
      name: "Product 1",
      location: "Riyadh",
      performance: "$299.00",
      sales: "$1974.00",
      status: "active",
    },
    {
      image: userOrderImage,
      name: "Product 1",
      location: "Riyadh",
      performance: "$299.00",
      sales: "$1974.00",
      status: "active",
    },
    {
      image: userOrderImage,
      name: "Product 1",
      location: "Riyadh",
      performance: "$299.00",
      sales: "$1974.00",
      status: "active",
    },
    {
      image: userOrderImage,
      name: "Product 1",
      location: "Riyadh",
      performance: "$299.00",
      sales: "$1974.00",
      status: "active",
    },
    {
      image: userOrderImage,
      name: "Product 1",
      location: "Riyadh",
      performance: "$299.00",
      sales: "$1974.00",
      status: "active",
    },
    {
      image: userOrderImage,
      name: "Product 1",
      location: "Riyadh",
      performance: "$299.00",
      sales: "$1974.00",
      status: "Inactive",
    },      
  ]);

  const user = JSON.parse(localStorage.getItem('user'));
  const userRole = user?.roles[0]?.name;
  
  const dispatch = useDispatch()

  useEffect(()=>{

    dispatch(getServiceCategories())
  },[])
  return (
    <Layout>
      <OrderStats title="Order Statistics"  statistics={OrderStatistics} />
      <Container className="mt-4">
        <div className="d-flex justify-content-between">
          <div
            style={{
              backgroundColor: "#fff",
              width: "738px",
              height: "390px",
              marginRight: "20px",
              borderRadius: "15px",
            }}
          >
              <OrderChart />
          </div>

          <div
            style={{
              backgroundColor: "#fff",
              overflow: "auto",
              width: "352px",
              height: "390px",
              borderRadius: "15px",
              padding: '10px'
            }}
          >
            <Row className={`m-1 mb-4 align-items-center ${rowReverse}`}>
              <Col md={9} className={`d-flex ${rowReverse}`}>
                <span style={{ fontSize: "20px", fontWeight:600 }}>{t("Recent Orders")}</span>
              </Col>
              <Col>
                <span
                  className={`d-flex ${rowReverse}`}
                  style={{
                    fontSize: "12px",
                    color: "#F7941D",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  {t("All orders")}
                </span>
              </Col>
            </Row>

            {Orders.map((order, index) => (
              <Row
                key={index}
                className={`mb-3 ${rowReverse}`}
                style={{ margin: 0, padding: 0 }}
              >
                <Col md={2} style={{ textAlign: "center", padding: 0 }}>
                  <img
                    src={order.image}
                    alt={t("Order")}
                    style={{ borderRadius: "32px" }}
                    height={40}
                    width={40}
                  />
                </Col>
                <Col md={7} style={{ padding: 0 }}>
                  <span
                    className={`d-flex ${rowReverse}`}
                    style={{ fontSize: "14px", fontWeight: 600, margin: 0 }}
                  >
                    {order.name}
                  </span>
                  <p
                    className={`d-flex ${rowReverse}`}
                    style={{
                      fontSize: "10px",
                      fontWeight: 400,
                      color: "#A3A3A3",
                      margin: 0,
                    }}
                  >
                    {order.email}
                  </p>
                </Col>
                <Col className="text-right" style={{ padding: 0 }}>
                  <div>
                    <span
                      style={{
                        color: "#1D2129",
                        fontSize: "16px",
                        lineHeight: "16px",
                        fontWeight: 600,
                        margin: 0,
                      }}
                    >
                      ${order.amount}
                    </span>
                  </div>
                </Col>
              </Row>
            ))}
          </div>
        </div>
      </Container>


      <Container className="mt-4">
        <div className="d-flex justify-content-between">

          <div
            style={{
              backgroundColor: "#fff",
              width: "738px",
              height: "274px",
              marginRight: "20px",
              borderRadius: "15px",
              overflowY: "auto",
              
            }}
            className="p-3"
          >
            <span style={{ fontSize: "20px", fontWeight:600 }} className={`d-flex ${rowReverse}`}>{t("Best Performing Garages")}</span>

            <Table
                    hover
                    responsive
                    className="text-center"
                    style={{ margin: "0 auto" }}
                    dir={language === 'ar' ? "rtl" : "ltr"}
                  >
                    <thead>
                      <tr>
                        <th>{t("Garage Name")}</th>
                        <th>{t("Location")}</th>
                        <th>{t("Performance")}</th>
                        <th>{t("Sales")}</th>
                        <th>{t("Status")}</th>
                        <th>{t("Actions")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bestPerfoemingGarages.map((garage, index) => (
                        <tr key={index}>
                          <td>
                            <img
                              src={garage.image}
                              alt="Order"
                              style={{ borderRadius: "32px" }}
                              height={35}
                              width={35}
                            />
                            <span className="garageName">{garage.name}</span>
                          </td>
                          <td>{garage.locaiton}</td>
                          <td>{garage.performance}</td>
                          <td>{garage.sales}</td>
                          <td>
                            <span
                              className={
                                garage.status === "active"
                                  ? "in-stock"
                                  : "not-available"
                              }
                            >
                              {garage.status}
                            </span>
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            <img src={dots} alt={"..."} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
          </div>

          <div
            style={{
              backgroundColor: "#fff",
              overflow: "auto",
              width: "352px",
              height: "274px",
              borderRadius: "15px",
              padding: '5px',
            }}
          >
            <Row className={`m-1 mb-4 ${rowReverse}`}>
              <Col md={9}>
                <span className={`d-flex ${rowReverse}`} style={{ fontSize: "20px", fontWeight:600 }}>{t("Visitors")}</span>
              </Col>
              <Col>
                <span
                  className={`d-flex ${rowReverse}`}
                  style={{
                    fontSize: "12px",
                    color: "#F7941D",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  {t("All orders")}
                </span>
              </Col>
            </Row>

             <p className={`d-flex ${rowReverse}`}>{t("No Visitor")}</p>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default Dashboard;
