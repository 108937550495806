import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Dropdown } from "react-bootstrap";
import { DateRange } from "react-date-range";
import { startOfMonth, endOfMonth, format } from "date-fns";
import CalendarIcon from "../../assets/calendarIcon.png";
import { UserContext } from "../../Context/UserContext";
import { useTranslation } from "react-i18next";

const OrderStats = ({title, statistics}) => {
 
  const [showCalendar, setShowCalendar] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: "selection",
    },
  ]);

  const { language } = useContext(UserContext);
  const rowReverse = language === 'ar' ? "flex-row-reverse" : "";
  const { t } = useTranslation();

  const dayCount = Math.ceil(
    (dateRange[0].endDate - dateRange[0].startDate) / (1000 * 60 * 60 * 24)
  );
  const handleDropdownToggle = () => {
    setShowCalendar(!showCalendar);
  };

  const handleDateSelect = (ranges) => {
    setDateRange([ranges.selection]);
    setShowCalendar(false);
  };

  useEffect(() => {
    // Set default selection as start of the current month and end of the current month
    setDateRange([
      {
        startDate: startOfMonth(new Date()),
        endDate: endOfMonth(new Date()),
        key: "selection",
      },
    ]);
  }, []);

  return (
    <Container>
      <Row className={`align-items-center ${rowReverse}`}>
        <Col md={6}>
          <p className={`d-flex ${rowReverse}`} style={{
            fontSize:"24px",
            fontWeight:600,

          }} >{t(title)}</p>
        </Col>

        <Col md={6} className={`d-flex justify-content-end ${rowReverse}`}>
            {title==='Order Statistics' ? (<Dropdown show={showCalendar} onToggle={handleDropdownToggle}>
            <Dropdown.Toggle
              variant="light"
              style={{

                backgroundColor: "#fff",
                borderRadius: "999px",
                width: "auto", 
                display: "flex",
                alignItems: "center",
                padding:"10px 25px"
              }}
            >
              <img
                src={CalendarIcon}
                alt="cal"
                style={{
                  marginRight: "8px", 
                }}
              />
              {dayCount} Days {format(dateRange[0].startDate, "MM/dd/yyyy")} -{" "}
              {format(dateRange[0].endDate, "MM/dd/yyyy")}
            </Dropdown.Toggle>

            <Dropdown.Menu className="p-0">
              <DateRange
                editableDateInputs={false}
                onChange={handleDateSelect}
                moveRangeOnFirstSelection={false}
                ranges={dateRange}
                staticRanges={[
                  {
                    label: t("Last 7 Days"),
                    range: () => ({
                      startDate: new Date(
                        new Date().setDate(new Date().getDate() - 6)
                      ),
                      endDate: new Date(),
                    }),
                  },
                  {
                    label: t("Last 30 Days"),
                    range: () => ({
                      startDate: new Date(
                        new Date().setDate(new Date().getDate() - 29)
                      ),
                      endDate: new Date(),
                    }),
                  },
                ]}
                style={{
                  borderRadius: "8px",
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                }}
              />
            </Dropdown.Menu>
          </Dropdown>):(<></>)}
          
        </Col>
      </Row>

       <Row className={`mt-3 ${rowReverse}`} >
        {statistics.map((stats, index) => (
          <React.Fragment key={index}>
            <Col style={{ 
                borderRadius:"15px",
                backgroundColor:"#fff",
                boxShadow: "0px 0px 0px 0px rgba(0, 17, 83, 0.05), 7.989px 0px 18.64px 0px rgba(0, 17, 83, 0.05), 209.034px 0px 58.583px 0px rgba(0, 17, 83, 0.00)"
                
                }} 
                className="m-2 mt-0 mb-0">

              <Row className="align-items-center p-2 pb-0">
                <p style={{fontWeight:"600", fontSize:"15px"}} className={`d-flex ${rowReverse}`} >{t(stats.title)}</p>
                <div className={`d-flex align-items-center justify-content-between ${rowReverse}`}>
                  <p style={{fontSize:"32px" ,color:"#F15822", fontWeight:600, paddingLeft:"5px"}} >{stats.count}</p>
                  <p style={{fontSize:"12px", paddingLeft:"5px"}}>{stats.ratio}</p>
                </div>
                {/* <Col md ={8}  >
                </Col> */}
                <p className={`d-flex ${rowReverse}`}>{t("Image")}</p>
              </Row>
            </Col>
          </React.Fragment>
        ))}
      </Row>
    </Container>
  );
};

export default OrderStats;
