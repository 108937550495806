import { combineReducers } from "redux";
import authReducer from "./authReducer";
import garageReducer from "./garageReducer";
import serviceCategoryReducer from "./serviceCategoryReducer";
import ServiceReducer from "./serviceReducer";
import userReducer from "./userReducer";
import selectedGarageReducer from "./selectedGarage";
import productCategoryReducer from './productCategoryReducer'
import ProductReducer from "./productReducer";
import ServiceScheduleReducer from "./serviceScheduleReducer";
const rootReducer = combineReducers({
  auth: authReducer,
  garages: garageReducer,
  serviceCategories: serviceCategoryReducer,
  services: ServiceReducer,
  serviceSchedule:ServiceScheduleReducer,
  users: userReducer,
  selectedGarage:selectedGarageReducer,
  productCategory: productCategoryReducer,
  products: ProductReducer
});

export default rootReducer;
