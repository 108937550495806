import { Button, Container, Row, Col, Modal } from "react-bootstrap";
import { AddGarage, Layout } from "../components";
import { useContext, useEffect, useState } from "react";
import noGarage from "../assets/noGarage.png";
import userImage from "../assets/userImage.png";
import dots from "../assets/dots.png";
import location from "../assets/location.svg";
import "../App.css";
import GarageRating from "../components/rattingStars";
import { useDispatch, useSelector } from "react-redux";
import { getUserAssignedGarages, getUserGarages, removeGarage } from "../service/action/garageAction";
import { getServiceCategories } from "../service/action/serviceCategory";
import GarageOptionsBox from "../components/garageOptionBox";
import AssignUserModal from "../components/assignUser";
import { assignGarageToUser, getAllUsers } from "../service/action/userAction";
import http from "../utils/Api";
import { useTranslation } from "react-i18next";
import { UserContext } from "../Context/UserContext";

const Garage = () => {
  const garageList = useSelector((state) => state.garages);
  const dispatch = useDispatch();
  const { language } = useContext(UserContext);
  const rowReverse = language === 'ar' ? "flex-row-reverse" : "";
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem('user'));
  const userRole = user?.roles[0]?.name;
  
  useEffect(() => {
    if (userRole === 'manager') {
      dispatch(getUserAssignedGarages());
    } else if (userRole === 'owner') {
      dispatch(getUserGarages());
    }
    dispatch(getServiceCategories());
    dispatch(getAllUsers());
  }, []);

  const [isAddingGarage, setAddingGarage] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [selectedGarage, setSelectedGarage] = useState(null);
  const [showAssignUserModal, setShowAssignUserModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleAddGarageClick = () => {
    setAddingGarage(true);
  };

  const handleBackToListClick = () => {
    setAddingGarage(false);
    setEditing(false);
  };

  const handleOptionsClick = (garage) => {
    setSelectedGarage(garage);
  };

  const handleAssignUserSubmit = (selectedUsers) => {
    const payload = {
      selectedUsers: selectedUsers,
      selectedGarage: selectedGarage,
    };
    dispatch(assignGarageToUser(payload));
    handleCloseAssignUserModal();
  };

  const handleCloseOptionsBox = () => {
    setSelectedGarage(null);
  };

  const handleAssignUserClick = (garage) => {
    setShowAssignUserModal(true);
  };

  const handleEditClick = (garage) => {
    setSelectedGarage(garage);
    setEditing(true);
  };

  const handleCloseAssignUserModal = () => {
    setShowAssignUserModal(false);
  };

  const handleDeleteClick = (garage) => {
    setSelectedGarage(garage);
    setShowConfirmationModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(removeGarage(selectedGarage));
    setShowConfirmationModal(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const garageElement = document.querySelector(".garage-options-box");
      if (selectedGarage && garageElement && !garageElement.contains(event.target)) {
        handleCloseOptionsBox();
      }
    };

    if (selectedGarage) {
      document.addEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [selectedGarage]);

  const users = useSelector((state) => state.users);

  return (
    <Layout>
      {garageList.length <= 0 && !isAddingGarage ? (
        <Container
          fluid
          className="d-flex flex-column align-items-center justify-content-center text-center p-5"
        >
          <>
            <img src={noGarage} alt="no Garage" height={250} width={250} />
            <h3 className={`d-flex ${rowReverse}`} style={{ fontWeight: 600 }}>{t("NO GARAGE FOUND")}</h3>
            <span
              className={`mb-4 d-flex ${rowReverse}`}
              style={{
                color: "#757575",
                textAlign: "center",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
              }}
            >
              {t("Ready to get started? Create your first garage")} <br />
              {t("and unlock a world of automotive")} <br />
              {t("management at your fingertips.")}
            </span>
            {userRole === 'manager' ? (
              <></>
            ) : (
              <Button
                style={{
                  display: "flex",
                  width: "271px",
                  height: "40px",
                  padding: "20px 43px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  flexShrink: 0,
                  borderRadius: "999px",
                  borderColor: "orange",
                  background: "linear-gradient(97deg, #F15822 0%, #F7941D 100%)",
                }}
                onClick={handleAddGarageClick}
              >
                {t("Add New Garage")}
              </Button>
            )}
          </>
        </Container>
      ) : (
        <>
          {isAddingGarage || isEditing ? (
            <div>
              <Button
                onClick={handleBackToListClick}
                style={{
                  display: "flex",
                  width: "210px",
                  height: "40px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  flexShrink: 0,
                  borderRadius: "999px",
                  borderColor: "orange",
                  background:
                    "linear-gradient(97deg, #F15822 0%, #F7941D 100%)",
                }}
              >
                {t("Back to List")}
              </Button>
              <AddGarage
                isEditing={isEditing}
                selectedGarage={selectedGarage}
              />
            </div>
          ) : (
            <>
              <div className={`d-flex align-items-center justify-content-between mt-2 ${rowReverse}`}>
                  <p
                    className={`d-flex ${rowReverse}`}
                    style={{
                      fontSize: "24px",
                      fontWeight: 600,
                    }}
                  >
                    {t("Garage")}
                  </p>
                {/* <Col md={6}>
                </Col> */}
                {/* <Col md={6} className="d-flex justify-content-end"> */}
                  {userRole === 'manager' ? (
                    <></>
                  ) : (
                    <Button
                      onClick={handleAddGarageClick}
                      style={{
                        display: "flex",
                        width: "210px",
                        height: "40px",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        flexShrink: 0,
                        borderRadius: "999px",
                        borderColor: "orange",
                        background:
                          "linear-gradient(97deg, #F15822 0%, #F7941D 100%)",
                      }}
                    >
                      {t("Add New Garage")}
                    </Button>
                  )}
                {/* </Col> */}
              </div>{" "}
              <Container
                fluid
                className={`d-flex flex-wrap  mt-3 ${rowReverse}`}
                style={{ borderRadius: "15px" }}
              >
                {garageList?.map((garage, index) => (
                  <div
                    key={garage.id}
                    style={{
                      width: "348.768px",
                      padding: "15px",
                      backgroundColor: "#fff",
                      borderRadius: "13px",
                      marginBottom:
                        index < garageList.length - 1 ? "15px" : 0,
                      margin: "5px",
                    }}
                  >
                    <div className={`d-flex flex-wrap justify-content-between align-items-center md-4 ${rowReverse}`}>
                      <div className={`d-flex align-items-center gap-2 ${rowReverse}`}>
                        <img
                          src={`https://varposbuckets.s3.amazonaws.com/GarageImage/${garage.image}`}
                          alt="User"
                          height={40}
                          width={40}
                          style={{ borderRadius: "44px", }}
                        />
                        <p className={`mb-0 d-flex ${rowReverse}`}>{garage.name}</p>
                      </div>
                      <div>
                        <img
                          src={dots}
                          alt="Options"
                          onClick={() => handleOptionsClick(garage)}
                          style={{ cursor: "pointer" }}
                        />
                        {selectedGarage && userRole === 'owner' &&
                          selectedGarage.id === garage.id && (
                            <GarageOptionsBox
                              handleAssignUserClick={() =>
                                handleAssignUserClick(garage)
                              }
                              handleEditClick={() => handleEditClick(garage)}
                              handleDeleteClick={() =>
                                handleDeleteClick(garage)
                              }
                              handleCloseOptionsBox={handleCloseOptionsBox}
                            />
                          )}
                      </div>
                    </div>
                    <div>
                      <span
                        className={`d-flex mt-1 mb-1 ${rowReverse}`}
                        style={{
                          color: "#585858",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineheight: "normal",
                        }}
                      >
                        {t(garage.description) ||
                          t("Vehicles as canvases, where artistry meets engineering brilliance")}
                      </span>
                    </div>
                    <div className={`d-flex flex-wrap justify-content-between align-items-center md-4  mt-4 ${rowReverse}`}>
                      <div className={`d-flex gap-2 ${rowReverse}`}>
                        <img
                          src={location}
                          alt="loc"
                        />
                        <span>{garage.city}</span>
                      </div>
                      <div>
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "#F08B1D",
                            color: "#fff",
                            padding: "5.078px 23.89px",
                            borderRadius: "4.424px",
                          }}
                        >
                          {garage.city}
                        </button>
                      </div>
                    </div>
                    <div className="d-flex flex-wrap justify-content-between align-items-center mt-4">
                      <div>
                        <GarageRating rating={garage.rating} />
                      </div>
                      <div>
                        <span
                          style={{
                            color: "#141414",
                            fontSize: "14px",
                            fontWeight: 400,
                          }}
                        >
                          {garage.team ? <>{t("Team")}</> : <> </>}
                        </span>
                        <span style={{ marginLeft: "8px" }}>
                          {garage.team ? (
                            <>
                              {garage?.team.map((tem, index) => {
                                return (
                                  <img
                                    key={index}
                                    src={userImage}
                                    height={19}
                                    width={19}
                                    style={{
                                      borderRadius: "19px",
                                      marginLeft: index > 0 ? "-4px" : 0,
                                    }}
                                  />
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </Container>
            </>
          )}
        </>
      )}
      <AssignUserModal
        show={showAssignUserModal}
        handleClose={handleCloseAssignUserModal}
        users={users}
        handleAssignUserSubmit={handleAssignUserSubmit}
      />
      {/* Confirmation Modal */}
      <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)}>
        <Modal.Header closeButton style={{ flexDirection: language === 'ar' ? 'row-reverse': '', justifyContent: 'space-between' }}>
          <Modal.Title className={`d-flex ${rowReverse}`}>{t("Confirm Deletion")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={`d-flex ${rowReverse}`}>{t("Are you sure you want to delete this garage?")}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmationModal(false)}>
            {t("Cancel")}
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            {t("Delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default Garage;
