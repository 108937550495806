import { Layout } from "../components"

const Settings =()=>{
    return(
            <Layout>

            <h1>SettingsS</h1>
            </Layout>
    )
}


export default Settings