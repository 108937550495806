



import axios from "axios";
import http from "../../utils/Api";
import { useNavigate } from "react-router";

import { addProduct, getGarageProducts, getProductsByUIDs } from '../actionTypes'



const createProduct=(data)=>async(dispatch)=>{
    try{
        const token = localStorage.getItem("accessToken");
        const config = http.generateConfig(token);
        config.headers['Content-Type'] = 'multipart/form-data';

        const res = await http.post(`/api/products`, data, config);

        return res
            // dispatch({
            //     type:addProduct,
            //     payload :res.result
            // })

    }
    catch(err){
        console.log(err)
    }
}

const getProductByCategory =(data)=>async(dispatch)=>{
    try{

        const token = localStorage.getItem("accessToken");
        const {id,garageId }  = data 
        
        const res = await http.get(`/api/products/category/${id}/garage/${garageId}`, http.generateConfig(token))
        dispatch({
            type:getGarageProducts,
            payload:res
        })
    }
    catch(err){
        console.log(err)
    }
}

const getProductsByUID = (productUID) => async (dispatch) => {
    try {
        const token = localStorage.getItem("accessToken");
        const res = await http.get(`/api/product/search/${productUID}`, http.generateConfig(token));
        console.log(res,'TEHJABFS ')
        dispatch({
            type: getGarageProducts,
            payload: res.result // Assuming the response contains the actual data
        });
    } catch (err) {
        console.error("Error fetching products by UID:", err);
        // Optionally dispatch an error action or handle the error in another way
    }
};

export {
    getProductByCategory,
    getProductsByUID,
    createProduct
}