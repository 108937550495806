import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h1>Privacy Policy</h1>
      <p>
        Welcome to Var! We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy policy, or our practices with regards to your personal information, please contact us at vartest@technologybirds.com.
      </p>
      <h2>Information We Collect</h2>
      <p>
        When you use our mobile application, we may collect and store the following information:
      </p>
      <ul>
        <li><strong>Personal Information:</strong> When you sign up, login, or use our app, we collect personal information such as your name, email address, phone number, and vehicle information.</li>
        <li><strong>Location Data:</strong> We may collect information about your location to help you find the nearest garage.</li>
        <li><strong>Usage Data:</strong> We may collect information about how you interact with our app, such as pages visited, features used, and booking history.</li>
      </ul>
      <h2>How We Use Your Information</h2>
      <p>
        We use the information we collect in the following ways:
      </p>
      <ul>
        <li>To provide, operate, and maintain our app</li>
        <li>To improve, personalize, and expand our app</li>
        <li>To understand and analyze how you use our app</li>
        <li>To develop new products, services, features, and functionality</li>
        <li>To communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the app, and for marketing and promotional purposes</li>
        <li>To process your transactions and manage your bookings</li>
        <li>To send you emails and notifications</li>
      </ul>
      <h2>Sharing Your Information</h2>
      <p>
        We do not share your personal information with third parties except in the following circumstances:
      </p>
      <ul>
        <li>With service providers and partners who help us operate our app, such as payment processors and hosting providers</li>
        <li>To comply with legal obligations, protect and defend our rights, or prevent fraud and illegal activities</li>
        <li>With your consent, if we believe it is necessary to provide a service you requested</li>
      </ul>
      <h2>Security of Your Information</h2>
      <p>
        We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.
      </p>
      <h2>Your Privacy Rights</h2>
      <p>
        Depending on your location, you may have the following rights regarding your personal information:
      </p>
      <ul>
        <li>The right to access – You have the right to request copies of your personal information.</li>
        <li>The right to rectification – You have the right to request that we correct any information you believe is inaccurate or complete information you believe is incomplete.</li>
        <li>The right to erasure – You have the right to request that we erase your personal information, under certain conditions.</li>
        <li>The right to restrict processing – You have the right to request that we restrict the processing of your personal information, under certain conditions.</li>
        <li>The right to object to processing – You have the right to object to our processing of your personal information, under certain conditions.</li>
        <li>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
      </ul>
      <h2>Changes to This Privacy Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us:
      </p>
      <ul>
        <li>By email: vartest@technologybirds.com</li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
