import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import ar from '../i18n/ar.json'
// import 'intl-pluralrules';

// Set up i18next
i18n
  .use(initReactI18next)
  .init({
    // Set up your configuration here
    resources: {
      ar: {
        translation: ar
      },
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
});


export default i18n;