import { filterServices, getGarageServices, createGarageService } from '../actionTypes';

const ServiceReducer = (state = [], action) => {
  switch (action.type) {
    case getGarageServices:
      return action.payload;
    case createGarageService:
      return action.payload
    default:
      return state;
  }
};

export default ServiceReducer;
