import axios from "axios";
import http from "../../utils/Api";
import { useNavigate } from "react-router";
import { getAllUserGarages, editUserGarage,getSIngleGarage,deletGarage, addNewGarrage, selectGarage } from "../actionTypes";
const getUserGarages =(data)=>async (dispatch)=>{

    try{

        const token = localStorage.getItem("accessToken");
        const id = localStorage.getItem("userId");
        const user = JSON.parse(localStorage.getItem("user"));

        // console.log(token,"THE ACCEAS")
        // let { id } = jwt_decode(token);
        // console.log(id,"THE ASDM")
        const res = await http.get(`/api/user/${user.companyId}/garages`, http.generateConfig(token));

        if(res){
            dispatch({
                type:getAllUserGarages,
                payload:res.result
            })

            dispatch({
                type:selectGarage,
                payload:res.result[0]
            })

        }

    }
    catch(err){
        console.log(err)
    }
}

const getUserAssignedGarages =(data)=> async (dispatch)=>{
    try{
        const token = localStorage.getItem("accessToken");
        const id = localStorage.getItem("userId");
        const user = JSON.parse(localStorage.getItem("user"));
        console.log(id,"<:THE ASDJ")
        const res = await http.get(`/api/getUserGarages/${id}`,
         http.generateConfig())
            console.log(res,"THE OBJECT")
        
          if(res){
            dispatch({
                type:getAllUserGarages,
                payload:res.data.assignedGarages
            })
            dispatch({
                type:selectGarage,
                payload:res.data.assignedGarages[0]
            })

        }

    }
    catch(error){
        console.log(error)
    }
}

const addGarage = (formData) => async (dispatch) => {
    try {
        const token = localStorage.getItem("accessToken");
        const user = JSON.parse(localStorage.getItem("user"));
        formData.append("companyId", user.companyId);

        const config = http.generateConfig(token);
        config.headers['Content-Type'] = 'multipart/form-data';

        const res = await http.post('/api/garages', formData, config);
        console.log(res,'THE RES')
        if (res.result) {
            dispatch({
                type: addNewGarrage,
                payload: res.result,
            });
        }
        return res
    } catch (err) {
        console.log(err);
    }
};

const editGarages =(data)=>async (dispatch)=>{
    try{

        const res = await http.put(`/api/garages/${data.id}`,data, http.generateConfig());
        if(res){
            dispatch({
                type: editUserGarage,
                payload:res.result
            })
        }

    }
    catch(err){
        console.log(err)
    }
}


const getGarage =(data)=>async (dispatch)=>{

    try{
        const res = await http.get(`/api/garages/${data.id}`,http.generateConfig());
        if(res){
            dispatch({
                type:getSIngleGarage,
                payload:res.result
            })
        }


    }
    catch(err){
        console.log(err)
    }
}



const removeGarage =(data)=>async (dispatch)=>{

    try{
        console.log(http.generateConfig(),"THE DELETE DATA")
        const token = localStorage.getItem("accessToken");
        console.log(token)
        console.log(http.generateConfig(token))
        const res = await http.delete(`/api/garages/${data.id}`,http.generateConfig(token));

        if(res){
            dispatch({
                type:deletGarage,
                payload:res.result
            })
        }



    }
    catch(err){
        console.log(err)
    }
}




export {
    getUserGarages,
    editGarages,
    getGarage,
    removeGarage,
    addGarage,
    getUserAssignedGarages
    
}