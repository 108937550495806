import React, { useEffect, useState } from "react";
import Layout from "../layout";
import {
  Button,
  Container,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Dropdown,
  Col,
  Row,
  Card,
} from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import { useDispatch, useSelector } from "react-redux";
import addImage from "../../assets/addImage.png";
import Select from "react-select";
import http from "../../utils/Api";
import { getProductCategories } from "../../service/action/productCategory";
import { createProduct } from "../../service/action/productAction";

const SingleProduct = () => {
  const [formData, setFormData] = useState({
    title: "",
    price: "",
    description: "",
    unit: "",
    unitPrice: "",
    discount: "",
    searchTags: [],
    images: [],
    productCategoryId: "",
    garageId: "", 
  });

  const productCategories = useSelector((state) => state.productCategory);
  const garageList = useSelector((state) => state.garages);
  const selectedGarage = useSelector((state) => state.selectedGarage);
  const [formattedProductCategories, setFormattedProductCategories] = useState(
    []
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProductCategories());
    setFormData({
      ...formData,
      garageId: selectedGarage.id,
    });
  }, []);

  useEffect(() => {
    const formattedProductCategories = productCategories.map(
      (productCategories) => ({
        value: productCategories.title,
        label: productCategories.title,
        id: productCategories.id,
      })
    );
    setFormattedProductCategories(formattedProductCategories);
  }, [productCategories]);

  const [newCategoryName, setNewCategoryName] = useState("");

  const handleNewCategoryChange = (newValue) => {
    setNewCategoryName(newValue);
    setFormData({
      ...formData,
      productCategoryId: newValue.id,
    });
  };

  const handleAddNewProductCategory = async (inputValue) => {
    if (!inputValue.trim()) {
      alert("Please enter a valid subcategory name");
      return;
    }

    const token = localStorage.getItem("accessToken");
    try {
      const res = await http.post(
        "/api/product-categories",
        {
          title: inputValue,
        },
        http.generateConfig(token)
      );
      console.log(res, "TEH RESPONSE");
      if (res) {
        setFormattedProductCategories((prevProductCategories) => [
          ...prevProductCategories,
          { value: res.title, label: res.title, id: res.id },
        ]);
        console.log(formattedProductCategories, "THE FORMATTED SIB");
        alert("New subcategory added successfully");
      }
    } catch (error) {
      console.error("Error creating subcategory:", error);
      alert("Error creating subcategory. Please try again later.");
    }
  };

  const handleSearchTagChange = (selectedOptions) => {
    const searchTags = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFormData({ ...formData, searchTags });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageUpload = (e) => {
    const files = e.target.files;
    const updatedImages = [...formData.images];
    for (let i = 0; i < files.length; i++) {
      updatedImages.push(files[i]);
    }
    setFormData({ ...formData, images: updatedImages });
  };

  const handleRemoveImage = (indexToRemove) => {
    const updatedImages = formData.images.filter(
      (_, index) => index !== indexToRemove
    );
    setFormData({ ...formData, images: updatedImages });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = new FormData();

    Object.entries(formData).forEach(([key, value]) => {
      if (key === "images") {
        for (let i = 0; i < value.length; i++) {
          form.append(`images`, value[i]);
        }
      } else {
        form.append(key, value);
      }
    });
    console.log(form)
    dispatch(createProduct(form))

    // Example of sending FormData object to server using fetch API
    /*
        fetch("your_api_endpoint", {
          method: "POST",
          body: form,
        })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error("Error:", error));
        */
  };

  return (
    <div>
      <Layout>
        <Container
          style={{
            borderRadius: "15px",
            backgroundColor: "#fff",
          }}
        >
          <h5>Add New Product</h5>
          <Container>
            <Row style={{ height: "80vh" }}>
              <Col md={2}  >
                {[...Array(3)].map((_, index) => (
                  <Card
                    key={index}
                    className="mt-2 mb-2"
                    style={{ height: "20%", position: "relative" }}
                  >
                    {formData.images[index] && (
                      <div>
                        <img
                          src={URL.createObjectURL(formData.images[index])}
                          width="100%"
                          height="100%"
                          alt={`Image ${index + 1}`}
                        />
                        <button
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                            borderRadius:"15px",
                            color:"red"
                          }}
                          onClick={() => handleRemoveImage(index)}
                        >
                          x
                        </button>
                      </div>
                    )}
                  </Card>
                ))}
                <Card
                  className="mt-2 mb-2"
                  style={{
                    height: "20%",
                    backgroundColor: "rgba(211, 218, 231, 1)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handleImageUpload}
                    style={{ display: "none" }}
                    id="file-upload"
                  />
                  <label htmlFor="file-upload">
                    <img
                      src={addImage}
                      width={100}
                      height={100}
                      alt="Add"
                      style={{ cursor: "pointer" }}
                    />
                  </label>
                </Card>
              </Col>

              <Col md={4} style={{ height: "83%" }}>
                <Card
                  className="mt-2 mb-2"
                  style={{ height: "100%", position: "relative" }}
                >
                  {formData.images.length > 0 && (
                    <img
                      src={URL.createObjectURL(
                        formData.images[formData.images.length - 1]
                      )}
                      width="100%"
                      height="100%"
                      alt={`img`}
                    />
                  )}
                  {formData.images.length > 0 && (
                    <button
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        color:"red"

                      }}
                      onClick={() =>
                        handleRemoveImage(formData.images.length - 1)
                      }
                    >
                      x
                    </button>
                  )}
                </Card>
              </Col>

              <Col md={6} style={{ height: "83%" }}>
                <Card className="mt-2 mb-2 p-1" style={{ height: "100%" }}>
                  <Form onSubmit={handleFormSubmit}>
                    <FormGroup>
                      <FormLabel className="label-txt">Product Name</FormLabel>
                      <FormControl
                        className="serviceFormControl"
                        type="text"
                        name="title"
                        value={formData.title}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormLabel className="label-txt">Description</FormLabel>
                      <FormControl
                        style={{ height: "90px" }}
                        className="serviceFormControl"
                        as="textarea"
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                    
                    <select
                    style={{width:"100%"}}
                      className="serviceFormControl mt-2"
                      id="garageSelect"
                      value={formData.garageId} 
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          garageId: e.target.value,
                        })

                      }
                    >
                      {garageList.map((garage) => (
                        <option key={garage.id} value={garage.id}>
                          {garage.name}
                        </option>
                      ))}
                    </select>
                    <CreatableSelect
                      className="mt-2 mb-2"
                      options={formattedProductCategories}
                      onChange={(newValue) =>
                        handleNewCategoryChange(newValue)
                      }
                      value={newCategoryName}
                      placeholder="Select or Add New Product Category"
                      onCreateOption={handleAddNewProductCategory}
                    />
                    <Row>
                      <Col>
                        <FormGroup>
                          <FormLabel className="label-txt">Sell Unit Price</FormLabel>
                          <FormControl
                            type="text"
                            name="price"
                            value={formData.price}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <FormLabel className="label-txt">
                            Purchase Unit Price
                          </FormLabel>
                          <FormControl
                            type="text"
                            name="unitPrice"
                            value={formData.unitPrice}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <FormLabel className="label-txt">Unit</FormLabel>
                          <FormControl
                            type="number"
                            name="unit"
                            value={formData.unit}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <FormLabel className="label-txt">Discount</FormLabel>
                      <FormControl
                        className="serviceFormControl"
                        type="text"
                        name="discount"
                        value={formData.discount}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>Search Tags</FormLabel>
                      <CreatableSelect
                        isClearable
                        isMulti
                        onChange={handleSearchTagChange}
                        options={[]}
                      />
                    </FormGroup>
                    <Button
                      style={{
                        display: "flex",
                        width: "271px",
                        height: "40px",
                        padding: "20px 43px",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        flexShrink: 0,
                        borderRadius: "999px",
                        borderColor: "orange",
                        background:
                          "linear-gradient(97deg, #F15822 0%, #F7941D 100%)",
                      }}
                      type="submit"
                    >
                      Add Product
                    </Button>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Container>
        </Container>
      </Layout>
    </div>
  );
};

export default SingleProduct;
