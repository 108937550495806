import { useEffect, useState } from "react";
import { Container, Table, Pagination, Spinner } from "react-bootstrap"; // Import Spinner component from react-bootstrap
import { Layout } from "../components";
import dots from "../assets/dots.png";
import userimage from "../assets/userImage.png";
import "../App.css";
import http from "../utils/Api";
import { useSelector } from "react-redux";

const Customer = () => {
  const pageSize = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const selectedGarage = useSelector((state) => state.selectedGarage);
  const [customerList, setCustomerList] = useState([]);
  const [loading, setLoading] = useState(true); // State variable to track loading state

  useEffect(() => {
    getGarageCustomers();
  }, [selectedGarage]);

  const getGarageCustomers = async () => {
    const token = localStorage.getItem("accessToken");
    const config = http.generateConfig(token);
    try {
      setLoading(true)
      const allCustomers = await http.get(`/api/customerGarage/${selectedGarage.id}`, config);
      setCustomerList(allCustomers);
      setLoading(false)
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false)
    } finally {
      setLoading(false); // Set loading to false regardless of success or failure
    }
  };

  const indexOfLastCustomer = currentPage * pageSize;
  const indexOfFirstCustomer = indexOfLastCustomer - pageSize;
  const currentCustomers = customerList.slice(indexOfFirstCustomer, indexOfLastCustomer);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  function convertUTCtoLocalDateTime(utcDateString) {
    // Create a new Date object from the UTC date string
    const utcDate = new Date(utcDateString);
  
    // Get the local date and time components
    const localYear = utcDate.getFullYear();
    const localMonth = utcDate.getMonth() + 1; // Months are zero-based, so add 1
    const localDay = utcDate.getDate();
    const localHours = utcDate.getHours();
    const localMinutes = utcDate.getMinutes();
    const localSeconds = utcDate.getSeconds();
  
    // Format the local date and time string
    const localDateTimeString = `${localYear}-${String(localMonth).padStart(2, '0')}-${String(localDay).padStart(2, '0')}T${String(localHours).padStart(2, '0')}:${String(localMinutes).padStart(2, '0')}:${String(localSeconds).padStart(2, '0')}`;
  
    return localDateTimeString;
  }
  return (
    <Layout>
      {loading ? ( // Render loading spinner if loading is true
        <Container className="d-flex justify-content-center align-items-center mt-5">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Container>
      ) : customerList.length === 0 ? (
        <Container
          fluid
          className="d-flex flex-column align-items-center justify-content-center text-center p-5"
        >
          <>
            <h3>No Service Found</h3>
            <span
              className="mb-4"
              style={{
                color: "#757575",
                textAlign: "center",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
              }}
            >
              Ready to kick off your customer management? Add customers and{" "}
              <br />
              provide them with a seamless experience.
            </span>
          </>
        </Container>
      ) : (
        <Container
        fluid
        className="p-2"
        style={{ backgroundColor: "#fff", borderRadius: "15px" }}
      >
        <div style={{ overflowY: "auto" }}>
          <Table
            hover
            responsive
            className="text-center"
            style={{ margin: "0 auto" }}
          >
            <thead>
              <tr>
                <th>Customer</th>
                <th>Product</th>
                <th>Last Payment</th>
                <th>Joined Date</th>
                <th>Last Connected</th>
                {/* <th>Tags</th> */}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
            {customerList.map((customer, index) => (
  <tr key={index}>
    <td>
      <div className="d-flex"
      
      >
        <img
          src={userimage}
          alt="Order"
          style={{ borderRadius: "32px", marginRight:"10px" }}
          height={35}
          width={35}
        />
        <div style={{textAlign:"left"}}>
          <span
            style={{
              fontSize: "11px",
              fontWeight: 600,
              margin: 0,

            }}
          >
            {customer?.name|| ''}
          </span>
          <p
            style={{
              fontSize: "12px",
              fontWeight: 400,
              color: "#A3A3A3",
              margin: 0,
            }}
          >
            {customer?.email || ''}
          </p>
        </div>
      </div>
    </td>

    <td>{customer.serviceBookings && customer.serviceBookings.length > 0 ? customer.serviceBookings[0].GarageService.title : ''}</td>
    <td>
      <span
        className={
          customer.lastPayment === "Successful"
            ? "in-stock"
            : "not-available"
        }
      >
        {customer?.lastPayment|| ' '}
      </span>
    </td>
    <td>{customer?.createdAt|| ''}</td>
    <td>{customer?.lastConnected || ''}</td>
    {/* <td>{customer?.tags.join(", ") || ''}</td> */}
    <td style={{ cursor: "pointer" }}>
      <img src={dots} alt={"..."} />
    </td>
  </tr>
))}
            </tbody>
          </Table>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-3">
          <Pagination>
            <Pagination.Prev
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage > 1 ? prevPage - 1 : prevPage
                )
              }
            />
            {Array.from({ length: Math.ceil(customerList.length / pageSize) }).map(
              (item, index) => (
                <Pagination.Item
                  key={index}
                  active={index + 1 === currentPage}
                  onClick={() => paginate(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              )
            )}
            <Pagination.Next
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage < Math.ceil(customerList.length / pageSize)
                    ? prevPage + 1
                    : prevPage
                )
              }
            />
          </Pagination>
        </div>
      </Container>
      )}
    </Layout>
  );
};

export default Customer;
