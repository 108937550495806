import { useParams } from "react-router-dom"
import { Layout } from "../components"


const BookingDetails =()=>{
    const {id} = useParams()
    return(
        <div>
            <Layout>
                abc
            </Layout>
        </div>
    )
}

export default BookingDetails