// SetupPassword.js

import React, { useState } from "react";
import { Col, Container, Row, Form, Button, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import zxcvbn from "zxcvbn"; // Password strength library
import logo from "../../assets/Logo.png";
import "./login.css";

const SetupPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [passwordStrengthError, setPasswordStrengthError] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    // Calculate password strength using the zxcvbn library
    const result = zxcvbn(newPassword);
    setPasswordStrength(result.score + 1);
    setPasswordMatchError(false); // Reset the password match error when the password changes
    setPasswordStrengthError(false); // Reset the password strength error when the password changes
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    setPasswordMatchError(false); // Reset the password match error when the confirm password changes
  };

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    // Add your password validation logic here
    if (password !== confirmPassword) {
      // Display an error for password mismatch
      console.log("Passwords do not match");
      setPasswordMatchError(true);
      setPasswordStrengthError(false);
      return;
    }

    // Check for password strength using zxcvbn
    const result = zxcvbn(password);
    if (result.score < 2) {
      // Display an error for weak password
      console.log("Password is too weak");
      setPasswordStrengthError(true);
      setPasswordMatchError(false);
      return;
    }

    // Continue with your submission logic
    setPasswordMatchError(false);
    setPasswordStrengthError(false);
    navigate("/otp");
  };

  return (
    <Container fluid style={{ height: "100vh" }}>
      <Row style={{ height: "100%" }}>
        <Col
          md={4}
          className="login-form d-flex"
          style={{
            flexDirection: "column",
            justifyContent: "center",
            padding: "10px 30px 0 30px",
          }}
        >
          <div className="logo-container">
            <Image src={logo} alt="Logo" fluid className="logo" height={90} width={90} />
          </div>

          <div
            className="d-flex"
            style={{
              flexGrow: 1,
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div className="text-header">
              <p>Setup New Password</p>
              <p>
                Have you already reset the password?{" "}
                <Link to="/" style={{ color: "#F7941D" }}>
                  {" "}
                  Sign in{" "}
                </Link>
              </p>
            </div>

            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicPassword" className="position-relative mb-3">
              <Form.Label className="text-left formLabel"></Form.Label>

                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  className="formControl"
                  onChange={handlePasswordChange}
                />
                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  className="eye-icon"
                  onClick={handleTogglePassword}
                />
              </Form.Group>

              <div className="password-strength">
                <div className={passwordStrength >= 1 ? "strength-line active" : "strength-line"}></div>
                <div className={passwordStrength >= 2 ? "strength-line active" : "strength-line"}></div>
                <div className={passwordStrength >= 3 ? "strength-line active" : "strength-line"}></div>
                <div className={passwordStrength >= 4 ? "strength-line active" : "strength-line"}></div>
              </div>

              <div className="text-header">
                <p></p>
                <p>Use 8 or more characters with a mix of letters, numbers & symbols</p>
              </div>

              <Form.Group controlId="formBasicConfirmPassword" className="mb-3">
                <Form.Control
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  className={`formControl ${passwordMatchError ? "error" : ""}`}
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                />
                <FontAwesomeIcon
                  icon={showConfirmPassword ? faEyeSlash : faEye}
                  className="eye-icon"
                  onClick={handleToggleConfirmPassword}
                />
              </Form.Group>
                {passwordMatchError && (
                  <p className="error-message">Passwords do not match</p>
                )}

              {passwordStrengthError && (
                <p className="error-message">Password is too weak</p>
              )}

              <Button variant="primary" type="submit" className="signin-btn">
                Submit
              </Button>

              <p className="signup-text">
                <Link to="/" style={{ color: "#F7941D" }}>
                  Back
                </Link>
              </p>
            </Form>
          </div>
        </Col>

        <Col md={8} className="background-image" style={{ height: "100%" }}>
          {/* Add image to the background */}
          <div className="text">
            <h4>Features of VAR Mobile Application</h4>
            <p>
              Will enable users to get all required services with only one click
              away. This convenience will eliminate the hustle and emergency
              situations which any car owner faces when there is an urgent need
              and as well as the routine care of their vehicle.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SetupPassword;
