import React, { useState } from "react";
import {
  Container,
  Table,
  Pagination,
  Spinner,
  Form,
  Button,
  Modal,
} from "react-bootstrap";

const ServiceConfirmModal = ({ showModal=false, handleComplete=()=>{}, handleClose=()=>{}, }) => {
//   const [showModal, setShowModal] = useState(false);

//   const handleShow = () => setShowModal(true);
//   const handleClose = () => setShowModal(false);

  return (
    <Container>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Service Complete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure to complete this service ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleComplete}>
            Complete
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ServiceConfirmModal;
