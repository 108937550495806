import React, { useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Sidebar from './sidebar';
import Header from './header';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../Context/UserContext';

const Layout = ({ children }) => {
  const { language, } = useContext(UserContext);
  const rowReverse = language === 'ar' ? "flex-row-reverse": "";
  const { t } = useTranslation();
  // const isArabic = selectedLang === 'ar';

  return (
    <Container fluid style={{ border: '1px solid black', height: '100vh', padding: 0 }}>
      <div className={`d-flex ${rowReverse}`} style={{ margin: 0, padding: 0, height: '100%' }}>
        <Col md={2} style={{ padding: 0 }}>
          <Sidebar />
        </Col>
        <Col md={10} style={{ padding: 0, height: '100%' }}>
          <Col md={12} style={
            { height: '10%' , backgroundColor:'#F3F7FF'}
            }
            className="d-flex align-items-center "
            >
            <Header />
          </Col>
          <Col md={12} style={{ height: '90%', backgroundColor: '#F3F7FF', overflowY: 'auto' }}>
           <main style={{ padding: '1rem', minHeight: '100%' }}>{children}</main>
          </Col>

        </Col>
      </div>
    </Container>
  );
};

export default Layout;
