
import star from '../assets/star.svg'

const GarageRating = ({ rating }) => {
    const filledStars = Array.from({ length: Math.floor(rating) }, (_, index) => (
      <img key={index} src={star} alt="*" />
    ));
  
    return (
      <div>
        {filledStars}
      </div>
    );
  };


  export default GarageRating;