import React, { useContext, useEffect, useState } from "react";
import { Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import bellIcon from "../../assets/bellIcon.png";
import userIcon from "../../assets/userImage.png";
import dropdownImage from "../../assets/dropdownIcon.png";
import { useDispatch, useSelector } from "react-redux";
import { getUserAssignedGarages, getUserGarages } from "../../service/action/garageAction";
import { selectedGaragestate } from '../../service/action/selectedGarage';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../Context/UserContext";
import i18n from "../../i18n/i18n";

const Header = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const garageList = useSelector((state) => state.garages);
  const selectedGarage = useSelector((state) => state.selectedGarage);
  const dispatch = useDispatch();
  const { language, setLanguage, } = useContext(UserContext);
  const rowReverse = language === 'ar' ? "flex-row-reverse" : "";
  const { t } = useTranslation();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const user = JSON.parse(localStorage.getItem('user'));
  const userRole = user?.roles[0]?.name;

  useEffect(() => {
    console.log(userRole, "THE USER ROLES HER")
    if (userRole === 'manager' || userRole === 'cashier') {
      console.log("RUNNING MANAGER")
      dispatch(getUserAssignedGarages());
    } else if (userRole === 'owner') {
      console.log("RUNNING OWNER")
      dispatch(getUserGarages());
    }
  }, [userRole]);

  // Set the first garage as default selected
  useEffect(() => {
    if (garageList.length > 0 && !selectedGarage) {
      dispatch(selectedGaragestate(garageList[0].id));
    }
  }, [garageList]);

  useEffect(() => {
    if (localStorage) {
      const lang = localStorage.getItem("language");
      if (lang) {
        setLanguage(lang);
        i18n.changeLanguage(lang);
      }
    }
  }, [])

  const selectGarageChange = (garageId) => {
    const selectedId = parseInt(garageId, 10);
    const garageData = garageList.find((garage) => garage.id === selectedId);

    dispatch(selectedGaragestate(garageData));
  };

  const handleLanguageChange = () => {
    const lang = language === 'en' ? 'ar' : 'en';
    setLanguage(lang);
    localStorage.setItem("language", lang);
    i18n.changeLanguage(lang);
  }

  console.log(user, "TEH USER")
  return (
    <Container fluid className="pb-1 pt-1 border-bottom">
      <Row className={`${rowReverse}`}>
        <Col md={6} className={`d-flex align-items-center ${rowReverse}`}>
          {/* Garage selection dropdown */}
          <div className={`input-group align-items-center ${rowReverse}`}>
            <div className="input-group-prepend">
              <span className="input-group-text" id="garage-dropdown">
                <img src={dropdownImage} alt="Dropdown Icon" />
              </span>
            </div>
            <select
              className="form-control rounded-pill"
              id="garageSelect"
              value={selectedGarage ? selectedGarage.id : ""}
              onChange={(e) => selectGarageChange(e.target.value)}
            >
              {/* Populate dropdown options with garageList */}
              {garageList.map((garage) => (
                <option key={garage.id} value={garage.id}>
                  {garage.name}
                </option>
              ))}
            </select>
          </div>
        </Col>

        <Col md={6} className={`d-flex align-items-center ${language === 'ar' ? "": "justify-content-end"}`}>
          <div className={`d-flex ${rowReverse}`}>
            <div className={`cursor-pointer me-3 d-flex align-items-center ${language === 'ar' ? "ms-2": ""}`} onClick={handleLanguageChange}>
              {/* <NotificationIcon isNew={true} /> */}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">{language === 'en' ? "Change to Arabic" : "Change to English"}</Tooltip>}
              >
                <FontAwesomeIcon icon={faLanguage} />
              </OverlayTrigger>
            </div>
            <div className={`d-flex align-items-center ${rowReverse}`}>
              {/* Your user image */}
              <img
                src={bellIcon}
                alt="Bell Icon"
                className={`rounded-circle me-3 ${language === 'ar' ? "ms-3": ""}`}
                width={30}
                height={30}
              />
              <div className={`d-flex`}>
                {
                  user && user.profilepicture ? (<img
                    src={`https://varposbuckets.s3.amazonaws.com/profilePicture/${user.profilepicture}`}
                    // src={userIcon}
                    alt="User Icon"
                    className="rounded-circle mr-3"
                    width={40}
                    height={40}
                  />
                  ) : (
                    <img

                      src={userIcon}
                      alt="User Icon"
                      className="rounded-circle mr-3"
                      width={40}
                      height={40}
                    />

                  )
                }
                <div className="ml-2">
                  <div className="font-weight-bold" style={{ fontSize: "16px" }}>
                    {user.name.toUpperCase() || ' '}
                  </div>
                  <div style={{ fontSize: "12px", color: "#787486", fontWeight: 400 }}>
                    {userRole.toUpperCase() || '-'}
                  </div>
                </div>
              </div>
              {/* User information */}

              {/* Your dropdown button with an image */}
              {/* <div
                style={{ cursor: "pointer", paddingRight: '10px' }}
                onClick={toggleDropdown}
              >
                <img
                  src={dropdownImage}
                  alt="Dropdown Icon"
                  className="ml-2"
                  style={{ width: '20px', height: '20px' }}
                />
                {isDropdownOpen && (
                  <div
                    className="position-absolute mt-1"
                    style={{
                      width: "200px",
                      backgroundColor: '#FFF',
                      border: '1px solid #ccc',
                      padding: '10px',
                      zIndex: 1,
                    }}
                  >
                    <ul className="list-unstyled">
                      <li>Option 1</li>
                      <li>Option 2</li>
                    </ul>
                  </div>
                )}
              </div> */}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
